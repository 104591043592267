<template>
  <b-row no-gutters class="mx-2">
    <b-col cols="8" class="pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >Interactions
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content:
                'Total number of reactions, comments and shares per publication.',
            }"
          ></i>
        </span>
      </div>
      <div 
        v-if="!view"
        class="image-container-off"
      >
        <img
          class="off_op"
          src="@/assets/images/image-off-interactionsmultiple.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
      <highcharts v-else :options="getOptions()" />
    </b-col>
    <b-col cols="4" class="pl-5 pr-2">
      <div class="bg-instagram rounded p-4 text-white">
        <p>Total Interactions</p>
        <p class="text-2xl font-weight-bold">{{ numFormatter(total) }}</p>
      </div>
      <div class="rounded p-4">
        <p>Average Interactions By Post</p>
        <p class="text-4xl font-weight-bold">{{ numFormatter(average) }}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";
export default {
  props: {
    series: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    average: {
      type: Number,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      view: false,
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {
    getOptions() {
      try {
        let options = {
          chart: { type: "column" },
          title: { text: "" },
          legend: {
            align: "right",
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          series: [],
          xAxis: {
            title: { text: "Days" },
            categories: [],
            crosshair: true,
            labels: {
              autoRotation: false,
              rotation: 0,
              style: { color: "#ababab" },
            },
          },
          yAxis: [
            {
              allowDecimals: false,
              label: { align: "left" },
              min: 0,
              title: { text: "Interactions" },
            },
            {
              allowDecimals: false,
              label: { align: "right" },
              min: 0,
              title: { text: "Post" },
              opposite: true,
            },
          ],
          credits: { enabled: false },
        };
        options.series = this.series;
        options.xAxis.categories = this.categories;

        return options;
      } catch (error) {
        return {};
      }
    },
    validate() {
      for (const item of this.series) {
        item.data.forEach((element) => {
          if (element > 0) {
            this.view = true;
            return;
          }
        });
      }
    },
  },

  created() {
    this.validate();
  },
};
</script>

<style></style>
