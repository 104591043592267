<template>
  <div>
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <div class="w-100 detail-content-box" v-if="detail">
      <profile-bar
        :detail="detail"
        :accountName="detail.name"
        :rrss="'youtube'"
        :logo="detail.logo"
      />

      <div class="w-100 d-flex text-left mb-4 box-card-channel" :class="flexClass">
        <variation-item
          class="box-card-channel__item"
          v-for="(variation, index) in detail.variations"
          :key="index"
          :backgrounColor="variation.background"
          :title="variation.title"
          :level="variation.level"
          :cant="variation.cant"
          :up="variation.up"
          :metrics="detail.metrics"
          :rrss="'youtube'"
        />
      </div>

      <!-- tabs -->
      <tabs :cant="3" :titles="tabs" @change="changeTab" />

      <div class="w-100 bg-white p-xl-5 p-lg-3">
          <audience
            v-if="activeTab === 0"
            :totals="detail.audience.audienceTotals"
            :totalSeries="detail.audience.totalSubscribersGraph"
            :viewsSeries="detail.audience.viewsGraph"
            :categories="detail.chartCategories"
            :boolViewDataSuscribers="detail.audience.boolViewDataSuscribers"
            :boolViewDataViewersYT="detail.audience.boolViewDataViewersYT"
          />
          <posts
            v-else-if="activeTab === 1"
            :detail="detail.posts" :categories="detail.chartCategories"
          />
          <interactions
            v-else
            :detailInteractions="detail.interactions"
            :categories="detail.chartCategories"
            :boolViewData="detail.interactions.boolViewData"
          />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBar from "../../ProfileBar.vue";
import VariationItem from "../../VariationItem.vue";
import Audience from "./tabs/Audience/AudienceYt";
import Interactions from "./tabs/Interactions/InteractionsYt";
import Posts from "./tabs/Posts/PostsYt";
import { Tabs } from "@isc/styleguide";

import { youtubeDetailByChannel } from "@/services/channel.service";
import { loaderCustom } from "@isc/styleguide"; //"../transversal/loader.vue";

export default {
  components: {
    ProfileBar,
    VariationItem,
    Audience,
    Interactions,
    Posts,
    Tabs,
    loaderCustom,
  },
  props: {
    ChannelId: {
      type:  [String, Number],
      required: true,
    },
    RangeDate: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      detail: null,
      tabs: [
        {
          name: "Audience",
        },
        {
          name: "Posts",
        },
        {
          name: "Interactions",
        },
      ],
      flexClass:'boxItemLess',
      visibilityLoader: true,
    };
  },
  async created() {
    const detail = await youtubeDetailByChannel(this.ChannelId, {
      init: this.RangeDate[0],
      end: this.RangeDate[1],
    });
    this.detail = detail;
    (this.detail.variations.length) > 3 ? this.flexClass = 'boxItemMore': this.flexClass = 'boxItemLess';
    this.visibilityLoader = false
  },
  methods: {
    changeTab(position) {
      this.activeTab = position;
    },
  },
};
</script>
<style>
.detail-content-box{
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
}
</style>
