<template>
  <div class="w-8/12 pl-3 pr-5">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >Posts per day in a month
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content:
              'Total number of publications per week day in a selected date range',
          }"
        ></i>
      </div>
    </div>
    <div v-if="!view" class="image-container-off">
      <img
        src="@/assets/images/off-post-per-day.png"
        class="off_op"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    <highcharts v-else :options="setDataPerDayChart()" />
  </div>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../util/config";
export default {
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      view: false,
    };
  },
  props: {
    series: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {
    setDataPerDayChart() {
      try {
        let secondOptions = {
          chart: {
            type: "column",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            marginTop: 10,
            height: 360,
            marginBottom: 80,
          },
          title: {
            text: null,
          },
          xAxis: {
            type: "datetime",
            lineColor: "#D6DBDF",
            tickLength: 0,
            categories: [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ],
          },
          credits: {
            text: "",
          },
          yAxis: {
            allowDecimals: false,
            title: { text: "Posts", align: "middle" },
            labels: {
              style: {
                color: "#B3B4B8",
              },
            },
            min: 0,
            stackLabels: {
              enabled: false,
            },
          },
          legend: {
            align: "right",
            x: 10,
            verticalAlign: "bottom",
            y: 10,
            floating: true,
            borderWidth: 0,
            shadow: false,
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          tooltip: {
            formatter: function () {
              let text =
                "<table class='table_tooltip' border='0' cellspacing='0' cellpadding='0'>";
              text +=
                "<tr><td colspan='2' class='tp-table'>" + this.x + "</td></tr>";
              for (let i = 0; i < this.points.length; i++) {
                text += "<tr><td style='padding:4px;padding-left:8px'>";
                text +=
                  "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                  this.points[i].series.color +
                  " !important'></i> " +
                  this.points[i].series.name;
                text +=
                  "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
                text += this.points[i].y;
                text += "</td></tr>";
              }
              text += "</table>";
              return text;
            },
            shared: true,
            padding: 0,
            backgroundColor: "#ffffff",
            useHTML: true,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            crosshairs: true,
          },
          exporting: {
            enabled: false,
          },
          plotOptions: {
            series: {
              pointWidth: 5,
              allowPointSelect: true,
            },
            bar: {
              stacking: "normal",
              pointWidth: 7,
            },
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: false,
              },
              pointWidth: 7,
            },
          },
          series: [],
        };

        secondOptions.series = this.series || [];
        return secondOptions;
      } catch (error) {
        return {};
      }
    },
    validar() {
      for (let item of this.series) {
        for (let it of item.data) {
          if (it > 0) {
            this.view = true;
            return;
          }
        }
      }
    },
  },
  created() {
    this.validar();
  },
};
</script>

<style>
</style>
