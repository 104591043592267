var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("total-followers-chart", {
        attrs: {
          series: _vm.detail.totalFollowers,
          total: _vm.detail.audienceTotals.total,
          boolViewData: _vm.detail.boolViewDataSuscribers,
          categories: _vm.categories,
        },
      }),
      _c("div", { staticClass: "w-100 h-20" }),
      _c("new-followers-chart", {
        attrs: {
          series: _vm.detail.newFollowers,
          total: _vm.detail.audienceTotals.new,
          average: _vm.detail.audienceTotals.newAverage || 0,
          categories: _vm.categories,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }