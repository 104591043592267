<template>
  <div class="boxMain overview-boxmain">
    <div
      class="first-block eye-layer"
      :class="`bg-${accountData.rrss}`"
      @click="navigateTo"
    >
      <div
        :class="['eye', showeye == true ? 'eyesee' : '']"
        @mouseover="changeenter(1)"
        @mouseleave="changeenter(0)"
      >
        <i class="fa fa-eye fa-4x"></i>
      </div>
      <div class="imgBox">
        <img :src="accountData.image" @error="errorUrlImage" />
      </div>
      <div class="data">
        <div class="general">
          <div class="general__social-icon iconCustomByCardRSS">
            <i
              :class="['fab', `fa-${accountData.rrss}`]"
              :title="accountData.rrss"
            ></i>
          </div>
          <span class="general__title">{{ accountData.name }}</span>
          <span class="general__level">{{
            numFormatter(accountData.audience)
          }}</span>
          <small class="general__audience">Audience</small>
          <div class="general__rrss">
            <span>Created {{ accountData.created }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="second-block">
      <div class="info">
        <span>
          Posts
          <div class="hide">
            <i
              class="ml-1 fa fa-question-circle"
              v-b-popover.top="'16/12/2020 - 15/01/2021'"
              title="Engaged Users"
            ></i>
          </div>
        </span>
        <span class="text-xl">{{ numFormatter(accountData.posts) }}</span>
      </div>
      <div class="info">
        <span>
          {{ getMetricByRS().name }}
          <div class="hide">
            <i
              class="ml-1 fa fa-question-circle"
              v-b-popover.top="'16/12/2020 - 15/01/2021'"
              :title="getMetricByRS().name"
            ></i>
          </div>
        </span>
        <span class="text-xl">{{ numFormatter(getMetricByRS().value) }}</span>
      </div>
      <div class="info">
        <span>
          Interactions
          <div class="hide">
            <i
              class="ml-1 fa fa-question-circle"
              v-b-popover.top="'16/12/2020 - 15/01/2021'"
              title="Interactions"
            ></i>
          </div>
        </span>
        <span class="text-xl">{{
          numFormatter(accountData.interactions)
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Helpers } from "@isc/styleguide";

export default {
  name: "ChannelBox",
  props: {
    accountData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showeye: false,
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  methods: {
    getMetricByRS() {
      switch (this.accountData.rrss) {
        case "facebook":
          return {
            name: "Engaged Users",
            value: this.accountData.engaged_users,
          };
        case "instagram":
          return {
            name: "Likes",
            value: this.accountData.likes,
          };
        case "youtube":
          return {
            name: "Views",
            value: this.accountData.views,
          };
        case "linkedin":
          return {
            name: "",
            value: "",
          };
        default:
          return {
            name: "Mentions",
            value: this.accountData.mentions,
          };
      }
    },
    navigateTo() {
      this.$router
        .push({
          name: "overview-channels-detail",
          query: {
            channel: this.accountData.id,
            rrss: this.accountData.rrss,
          },
        })
        .catch((_) => {});
    },
    changeenter(value) {
      if (value == 1) {
        this.showeye = true;
      } else {
        this.showeye = false;
      }
    },
    errorUrlImage(event) {
      // changing for default image.
      event.target.src = require("../assets/images/off-account_150x150.png");
    },
  },
};
</script>

<style></style>
