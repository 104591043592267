<template>
<div class="w-100 bg-mainbg">
    <bread-crumb>
        <v-datepicker
         range v-model="rangeDate" class="mr-3" @change="changeDate" ref="datePicker" type="date" :clearable="false" range-separator=" - " format="DD/MM/YYYY" :disabled-date="disabledAfter"/>
    </bread-crumb>
    <div class="mt-4 ">
        <b-container fluid v-if="!isLoading && rangeDate.length > 1" :key="updateChanges">
            <facebook-detail :range-date="rangeDate" :channel-id="channelId" v-if="socialType === 'facebook'" />
            <instagram-detail :range-date="rangeDate" :channel-id="channelId" v-else-if="socialType === 'instagram'" />
            <youtube-detail :range-date="rangeDate" :channel-id="channelId" v-else-if="socialType === 'youtube'" />
            <twitter-detail :range-date="rangeDate" :channel-id="channelId" v-else-if="socialType === 'twitter'" />
            <linkedin-detail :range-date="rangeDate" :channel-id="channelId" v-else-if="socialType === 'linkedin'" />
        </b-container>
    </div>
</div>
</template>

<script>
import {BreadCrumb} from "@isc/styleguide";
import FacebookDetail from "../components/channel-detail/socials/facebook/FacebookDetail.vue";
import InstagramDetail from "../components/channel-detail/socials/instagram/InstagramDetail.vue";
import YoutubeDetail from "../components/channel-detail/socials/youtube/youtubeDetail.vue";
import TwitterDetail from "../components/channel-detail/socials/twitter/TwitterDetail.vue";
import LinkedinDetail from "../components/channel-detail/socials/linkedin/LinkedinDetail.vue";
import moment from "moment";
export default {
    name: "ChannelDetail",
    components: {
        BreadCrumb,
        FacebookDetail,
        InstagramDetail,
        TwitterDetail,
        YoutubeDetail,
        LinkedinDetail
    },
    data() {
        return {
            rangeDate: [],
            isLoading: false,
            updateChanges: 1
        };
    },
    methods: {
        changeDate(date) {
            let fec1= new Date(date[0]).getTime()
            let fec2= new Date(date[1]).getTime()
            let resta
            if(fec1 >= fec2){
            resta=fec1-fec2
            }
            else{
            resta=fec2-fec1
            }

            if(Math.round(resta/ (1000*60*60*24)) <=31){
                this.rangeDate[0] = date[0]
                this.rangeDate[1] = date[1]
                this.updateChanges++
                localStorage.setItem('RangeDateInit',date[0])
                localStorage.setItem('RangeDateFin',date[1])

            }
            else{
                this.rangeDate= [
                moment()
                .subtract(16, "days")
                .toDate(),
                moment().subtract(1, "days").toDate(),
                ]
                this.$bvToast.toast(
                "Date range exceeds limits (31 days)",
                {
                    title: "Warning",
                    autoHideDelay: 5000,
                    appendToast: true,
                    variant: "danger",
                    solid: true,
                }
                );
            } 
        },
        disabledAfter(date) {
        return date > new Date();
        },
    
    },
    mounted(){
        try {
            const dateInit = localStorage.getItem('RangeDateInit')
        const dateFin = localStorage.getItem('RangeDateFin')
        if(dateInit){
            this.rangeDate = [new Date(dateInit),new Date(dateFin)]
        }else{
            this.rangeDate = [
                moment().subtract(16, "days").toDate(),
                moment().subtract(1, "days").toDate(),
            ]
        }
        } catch (error) {
            console.error('error en mounted channel detail: ',error)
        }
    },
    created() {
        
        if (!this.$route.query.rrss || !this.$route.query.channel) {
            this.$router.replace({
                name: "overview-channels"
            });
            return;
        }
    },
    computed: {
        socialType() {
            if (this.$route.query.rrss) return this.$route.query.rrss;
            else return null;
        },
        channelId() {
            if (this.$route.query.channel) return this.$route.query.channel;
            else return null;
        },
    },
};
</script>
<style scoped>

.slide-leave-active,
.slide-enter-active {
    transition: 0.5s;
}
.slide-enter {
    transform: translate(-100%, 0);
}
.slide-leave-to {
    transform: translate(100%, 0);
}
</style>
<style>
.title-style{
    font-size: 15px;
    font-weight: bold;
    color: #2c3852;
}
.icoPop{
    font-size: 12px;
    color: #a2acc4;
    margin-left: 5px;
}
.white-color-text{
    font-size: 0.9375em;
    color: #fff;
}
.bx_Content_color{
    display: flex;
    align-items: center;
    margin-top: -8px;
}
.bx_Content_color span{
    font-size: 2em;
    line-height: normal;
    position: relative;
    margin-left: 5px;
}
.bx_Content_color i{
    font-size: 1.125em;
    position: relative;
    top: 0;
    margin-left: 5px;
}
.box-column-trend{
    max-height: 300px;
}
.text-trend{
    font-size: 12px;
    font-weight: bold;
    color: #5d6b88;
}
.text-donut{
    font-size: 15px;
    font-weight: bold;
    color: #5d6b88;
}
  .boxItemLess{
    justify-content: start;
  }
  .boxItemMore{
    justify-content: space-between;
  }
  .boxItemLess .box-card-channel__item{
    margin-right: 15px;
  }
  .boxItemMore .box-card-channel__item{
    margin-right: 0;
  }
</style>
