<template>
  <div class="w-100 px-5">
    <loader-custom v-if="visibilityLoader"></loader-custom>
    <p class="text-xs mb-4">Name</p>
    <b-form-input
      v-model="name"
      placeholder="Example Name"
      class="mb-4"
    ></b-form-input>
    <p class="text-xs mb-4">Selected channels</p>
    <div
      class="box-list-sn mb-4 w-100 border rounded overflow-y-auto p-3"
      style=""
      v-if="accounts.length > 0"
    >
      <div
        class="text-sm mb-3"
        v-for="(cuenta, index) in accounts"
        :key="index"
      >
        <input type="checkbox" class="box-list-sn_checkbox mr-2" v-model="cuenta.checked" />
        <i
          :class="`fab fa-${cuenta.redSocial.toLowerCase()} text-${cuenta.redSocial.toLowerCase()} mr-2`"
        ></i>
        {{ cuenta.nomUsuarioRedsocial }}
      </div>
    </div>
    <p class="text-xs mb-4">Add new channel</p>
    <div class="text-white d-flex justify-content-around text-sm mb-4">
      <b-button
        variant="facebook"
        class="text-sm"
        @click="openDialogLogin('facebook')"
      >
        <i class="fab fa-facebook fa-lg"></i> Add
      </b-button>
      <b-button
        variant="instagram"
        class="text-sm"
        @click="openDialogLogin('instagram')"
      >
        <i class="fab fa-instagram fa-lg"></i> Add
      </b-button>
      <b-button
        variant="twitter"
        class="text-sm"
        @click="openDialogLogin('twitter')"
      >
        <i class="fab fa-twitter fa-lg"></i> Add
      </b-button>
      <b-button
        variant="youtube"
        class="text-sm"
        @click="openDialogLogin('youtube')"
      >
        <i class="fab fa-youtube fa-lg"></i> Add
      </b-button>
      <b-button
        variant="linkedin"
        class="text-sm"
        @click="openDialogLogin('linkedin')"
      >
        <i class="fab fa-linkedin fa-lg"></i> Add
      </b-button>
    </div>
    <b-button
      block
      variant="primary"
      :disabled="loading"
      @click="confirmAccounts"
    >
      Save
    </b-button>
    <!-- ALERT CODE MODAL -->
    <IscModalAlerts
      v-model="showAlertCode"
      :icon="iconCode"
      :iconColor="iconColorCode"
      :title="titleCode"
      :paragraph="paragraphCode"
      :buttonMessage="buttonMessageCode"
      @sendResult="resultModalAlertsCode"
      :noCloseBackdrop="true"
      :refresh="true"
    />
    <!-- MODAL ALERT GLOBAL -->
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
    >
    </IscModalAlerts>
  </div>
</template>

<script>
import * as OverviewService from "@/services/overview.service";
import * as RestrictService from "@/services/restrict.service";
import {
  IscModalAlerts,
  loaderCustom,
  UserObjMixin,
  AlertSetting,
  CodeToken,
  mapActions,
} from "@isc/styleguide";
export default {
  name: "editAccount",
  components: {
    IscModalAlerts,
    loaderCustom,
  },
  mixins: [UserObjMixin, AlertSetting, CodeToken],
  props: {
    accountData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      accounts: [],
      name: "",
      respRestrictAccount: null,
      visibilityLoader: false,
      clientId: 0,
    };
  },
  mounted() {
    window.addEventListener("message", this.getPage);
    this.clientId = this.userInfo.id_cliente;
  },
  beforeDestroy() {
    window.removeEventListener("message", this.getPage);
  },
  created() {
    this.getAccounts();

    this.name = this.accountData.title;
  },
  methods: {
    ...mapActions({
      updateAccounts: "overview/account/UPDATE_ACCOUNTS",
    }),
    async confirmAccounts() {
      try {
        this.visibilityLoader = true;
        let dataSend = { code: "", message: "" };
        const data = {
          client_id: this.clientId,
          data: this.accounts,
          brand_name: this.name,
          marca_id: this.accountData.id,
        };

        const respUpdateAccounts = await this.updateAccounts(data);
        const resp = this.validationCodeToken(respUpdateAccounts.data);
        if (!resp) return;

        //console.log("RESULT CONFIRMATION UPDATED ACCOUNTS  ===> ", resp);
        dataSend.code = "success";
        dataSend.message = resp.message;
        // Accounts array: account.redSocial === "twitter" if you insert for first time.
        // otherwise account.redSocial ==== "TWITTER"
        // Multicanales
        if (
          !this.respRestrictAccount &&
          dataSend.message === "Updated Account"
        ) {
          // create a new restrict account if it does not exist on table
          await this.createRestrictAccountBySN(this.accounts);
        }
        if (
          this.respRestrictAccount &&
          dataSend.message === "Updated Account"
        ) {
          await this.updateRestrictAccount(
            this.accounts,
            this.respRestrictAccount
          );
        }

        this.$emit("onClose", resp.message);
      } catch (error) {
        console.log("[Error] in function confirmAccounts editMultiple ", error);
        let msg =
          error?.response?.data?.message ?? "Server fails to update accounts.";
        this.errorModal(msg);
      } finally {
        this.loading = false;
        this.visibilityLoader = false;
      }
    },
    addAccountToList(redSocial, idMarca) {
      if (
        !this.accounts.find(
          (item) => item.nomCuentaRedSocial === redSocial.nomCuentaRedSocial
        )
      ) {
        this.accounts.push({
          idCuentaRedsocial: redSocial.idCuentaRedsocial,
          nomUsuarioRedsocial: redSocial.nomUsuarioRedsocial,
          nomCuentaRedSocial: redSocial.nomCuentaRedSocial,
          redSocial: redSocial.RedSocial.nomRedSocial,
          checked: this.accountData.id === idMarca,
        });
      }
    },
    async getAccounts() {
      const response = await OverviewService.ListAccounts();
      if (!response) return;
      const _accounts = response.marcas;
      const totalacc = response.totalaccounts;
      if (_accounts === -1) {
        this.$bvToast.toast("Server fails to load list of accounts", {
          title: "Error",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "danger",
          solid: true,
        });
      }
      // tenemos que separar las otras cuentas para no sobreescribir el checked
      const _otherAccounts = _accounts.filter(
        (account) => account.idMarca !== this.accountData.id
      );
      const _thisAccount = _accounts.filter(
        (account) => account.idMarca === this.accountData.id
      );
      //_thisAccount es un array con un solo elemento
      for (const account of _thisAccount) {
        for (const redSocial of account.CuentasRedSocial) {
          this.addAccountToList(redSocial, account.idMarca);
        }
      }
      for (const account of _otherAccounts) {
        for (const redSocial of account.CuentasRedSocial) {
          this.addAccountToList(redSocial, account.idMarca);
        }
      }
      for (const acc of totalacc) {
        const temp = this.accounts.filter(
          (item) => acc.idCuentaRedsocial == item.idCuentaRedsocial
        );
        if (temp.length == 0) {
          this.addAccountToList(acc, 0);
        }
      }
    },
    getPage(event) {
      if (event.data.accountName) {
        this.addAccount(event.data);
      }
    },
    async openDialogLogin(social_network) {
      if (social_network === "twitter") {
        const numSocialNetwork = Number(process.env.VUE_APP_TWITTER);
        const numLimitModuloBySN =
          process.env.VUE_APP_TWITTER_MODULO_REGISTER_ACCOUNT;
        const resp = await OverviewService.getValidationLimitAccountBySN(
          numSocialNetwork,
          numLimitModuloBySN
        );
        this.respRestrictAccount = resp;
        //console.log("RESP ASSOCIATED ACCOUNTS ==> ", resp);
        if (resp && resp.status) {
          let msg =
            resp?.data?.message ??
            "Server fails to get validation limit account by social network.";
          this.errorModal(msg);
          return;
        }
        this.openModalSocialNetwork(resp, numSocialNetwork, social_network);
      } else {
        this.getSocialNetwork(social_network);
      }
    },
    getSocialNetwork(social_network) {
      let w = 680;
      let h = 830;
      let left = screen.width / 2 - w / 2;
      let top = screen.height / 2 - h / 2;
      return window.open(
        social_network === "instagram"
          ? `${process.env.VUE_APP_BACK_MONITOR}/facebook/auth/${social_network}`
          : social_network === "twitter"
          ? `${process.env.VUE_APP_BACK_MONITOR}/${social_network}/auth`
          : `${process.env.VUE_APP_BACK_MONITOR}/${social_network}/auth`,
        "_blank",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          w +
          ", height=" +
          h +
          ", top=" +
          top +
          ", left=" +
          left
      );
    },
    addAccount(account) {
      const exist = this.accounts.find(
        (item) => item.nomCuentaRedSocial === account.socialNetworkId
      );
      if (exist) {
        this.$bvToast.toast("This account has been created before.", {
          title: "Error",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "danger",
          solid: true,
        });
      } else {
        this.accounts.push({
          ...account,
          nomUsuarioRedsocial: account.accountName,
          nomCuentaRedSocial: account.socialNetworkId,
          redSocial: account.socialNetwork,
          checked: true,
          _newrecord: true,
        });
      }
    },
    getError() {
      this.$bvToast.toast("Sorry, We could not add this account.", {
        title: "Error",
        autoHideDelay: 5000,
        appendToast: true,
        variant: "danger",
        solid: true,
      });
    },
    openModalSocialNetwork(resp, idSocialNetwork, social_network) {
      if (!resp) this.getSocialNetwork(social_network);
      else if (
        resp.LimiteModuloRedSocial.idRedSocial === idSocialNetwork &&
        resp.LimiteModuloRedSocial.numLimite > resp.numConsumo
      ) {
        this.getSocialNetwork(social_network);
      } else {
        let msg =
          "you already have a twitter's account registered, if you want to add other account. Go to setting's module and remove it.";
        this.errorModal(msg);
      }
    },
    /* Methods restrict  limit by account */
    async createRestrictAccountBySN(accounts) {
      for (const account of accounts) {
        // First register the name of social network is lowercase.
        if (account.redSocial === "twitter") {
          await this.saveSocialNetworkRestrictAccount(
            account.redSocial,
            Number(process.env.VUE_APP_TWITTER_MODULO_REGISTER_ACCOUNT)
          );
        }
      }
    },
    async updateRestrictAccount(accounts, periodAccount) {
      for (const account of accounts) {
        if (account.redSocial === "twitter") {
          await this.updateSocialNetworkRestrictAccount(
            Number(process.env.VUE_APP_TWITTER_MODULO_REGISTER_ACCOUNT),
            periodAccount
          );
        }
      }
    },
    async saveSocialNetworkRestrictAccount(socialNetwork, idModulo) {
      const data = {
        idLimiteModuloRedSocial: idModulo,
        dscPeriodoConsumo: "Amount of Account",
        redsocial: socialNetwork,
        numConsumo: 1,
        numSumaAcciones: 1,
      };
      const resp = await RestrictService.insertRestrictAccount(data);
      if (resp && resp.status) {
        let msg =
          resp?.data?.message ??
          "Server fails to save social network restrict account.";
        this.errorModal(msg);
      }
    },
    async updateSocialNetworkRestrictAccount(idModulo, periodAccount) {
      const data = {
        numConsumo: this.validationConsumeUpdate(periodAccount),
        numSumaAcciones: periodAccount.numSumaAcciones + 1,
      };
      //console.log("DATA ===> ", data);
      const resp = await RestrictService.updateRestrictAccount(data, idModulo);
      if (resp && resp.status) {
        let msg =
          resp?.data?.message ??
          "Server fails to update social network restrict account.";
        this.errorModal(msg);
      }
    },
    validationConsumeUpdate(periodConsume) {
      let numConsume = periodConsume.numConsumo;
      let limitConsume = periodConsume.LimiteModuloRedSocial.numLimite;
      if (numConsume < limitConsume) return numConsume + 1;
      else return numConsume;
    },
  },
};
</script>

<style lang="css" scoped>
.overflow-y-auto {
  overflow-y: auto;
}
/* sn = social networks */
.box-list-sn {
  display: flex;
  flex-direction: column;
  max-height: 30vh; min-height: 5vh; background: #eee
}
.box-list-sn_checkbox{
  width: auto;
}
</style>
