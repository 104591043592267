<template>
  <b-row no-gutters>
    <b-col cols="8" class="mt-2 pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >Reactions, comments, shares
          <i class="fa fa-info-circle text-skygray" id="reactCommShares"></i>
          <CpTooltipWhiteCustom
            target="reactCommShares"
            title="reactCommShares"
            message="Reactions, comments and shares updated every 24 hours."
          ></CpTooltipWhiteCustom>
        </span>
      </div>
      <highcharts v-if="view" :options="options" />
      <div
        v-else
        class="image-container-off"
        >
        <img
          src="@/assets/images/image-off-reactions.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
    </b-col>
    <b-col cols="4" class="pl-5 pr-2">
      <interactions-types-bar-graph :content="numFormatter(dataList.bars)" />
    </b-col>
  </b-row>
</template>

<script>
import InteractionsTypesBarGraph from "./InteractionsTypesBarGraph.vue";
import { CpTooltipWhiteCustom, mapGetters, Helpers } from "@isc/styleguide";
export default {
  components: { InteractionsTypesBarGraph, CpTooltipWhiteCustom },
  props: {
    series: {
      type: [Object, Array],
      required: true,
    },
    dataList: {
      type: Object,
      required: true,
    },
    chartCategories: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      options: {
        chart: { type: "spline" },
        title: { text: "" },
        legend: {
          align: "right",
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        series: [],
        xAxis: {
          categories: [],
          labels: {
            autoRotation: false,
            rotation: 0,
            style: { color: "#ababab" },
          },
        },
        yAxis: [
          {
            allowDecimals: false,
            label: { align: "left" },
            min: 0,
            title: { text: "" },
          },
        ],
        credits: { enabled: false },
        tooltip: { crosshairs: true, shared: true },
      },
      view: false,
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  created() {
    this.options.series = this.series;
    this.options.xAxis.categories = this.chartCategories;
    this.validate();
  },
  methods: {
    validate() {
      for (const item of this.series) {
        item.data.forEach((element) => {
          if (element > 0) {
            this.view = true;
            return;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.panels > div {
  width: 45%;
}
</style>
