<template>
  <div class="w-4/12 pl-lg-3 pl-xl-4 pr-2">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >Engaged users by post type
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Total users engaged by type of publication',
          }"
        ></i>
      </div>
    </div>
    <div class="w-100 text-semidark">
      <div class="d-flex align-items-center pr-4 mb-4">
        <div>
          <i class="fas fa-image text-xs text-color-text"></i> &nbsp;
          <span class="flex-none leading-none text-xs text-color-text">
            Image
          </span>
        </div>
        <div
          class="flex-grow-1 position-relative d-flex justify-content-start ml-2 pt-1"
        >
          <div class="position-relative z-0 bg-mainbg w-100 h-2"></div>
          <div
            class="position-absolute z-10 h-2 bg-facebook top-4 pt-1"
            :style="`width: ${count.image}%; max-width : 100%`"
          ></div>
        </div>
        <span class="flex-none text-xs ml-3 font-weight-bold text-color-text">{{
          numFormatter(count.image)
        }}</span>
      </div>
      <div v-if="viewtext == true" class="d-flex align-items-center pr-4 mb-4">
        <div>
          <i class="far fa-file-alt text-xs text-color-text"></i> &nbsp;
          <span class="flex-none leading-none text-xs text-color-text">
            Text
          </span>
        </div>
        <div
          class="flex-grow-1 position-relative d-flex justify-content-start ml-2 pt-1"
        >
          <div class="position-relative z-0 bg-mainbg w-100 h-2"></div>
          <div
            class="position-absolute z-10 h-2 bg-facebook top-4 pt-1"
            :style="`width: ${count.text}%; max-width : 100%`"
          ></div>
        </div>
        <span class="flex-none text-xs ml-3 font-weight-bold text-color-text">{{
          numFormatter(count.text)
        }}</span>
      </div>
      <div v-if="viewvideo == true" class="d-flex align-items-center pr-4 mb-4">
        <div>
          <i class="fas fa-video text-xs text-color-text"></i> &nbsp;
          <span class="flex-none leading-none text-xs text-color-text">
            Video
          </span>
        </div>
        <div
          class="flex-grow-1 position-relative d-flex justify-content-start ml-2 pt-1"
        >
          <div class="position-relative z-0 bg-mainbg w-100 h-2"></div>
          <div
            class="position-absolute z-10 h-2 bg-facebook top-4 pt-1"
            :style="`width: ${count.video}%; max-width : 100%`"
          ></div>
        </div>
        <span class="flex-none text-xs ml-3 font-weight-bold text-color-text">{{
          numFormatter(count.video)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { rutaNotFound } from "../../../../util/config";
export default {
  props: {
    count: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      viewtext: false,
      viewvideo: false,
    };
  },

  methods: {
    async validar() {
      if (this.count.text >= 0) {
        this.viewtext = true;
      }
      if (this.count.video >= 0) {
        this.viewvideo = true;
      }
    },
    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000) {
        num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num < 900) {
        num; // if value < 1000, nothing to do
      }
      return num;
    },
  },
  created() {
    this.validar();
  },
};
</script>

<style>
.et {
  width: 350px;
}
.off_op {
  opacity: 0.5;
}
</style>
