
const swal = require('sweetalert2')


async function validationCodeToken(data){
    try {
        if(data.code === 1002){
            swal.fire({
                title: 'Token expired',
                text: 'The token has expired, do you want to refresh it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Refresh',//Continuar
                cancelButtonText: 'Login',
            }).then(result => {
                if(result.isConfirmed){
                }
                if(result.isDismissed){
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = '/login'
                }
            })
        }else if(data.code === 1001){
            swal.fire({
                title: 'Token invalid',
                text: 'The token is invalid, we will redirect to the login',
                icon: 'error',
                confirmButtonText: 'Ok',
            }).then(() => {
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = '/login'
            })
        }else if(data.code === 1003){//1003 NECESITA HACER REFRESH
            const tokenRefresh = data.newToken//await refreshToken(currentToken)
            sessionStorage.setItem('token',tokenRefresh)
            return data.data
        }else if(data.code === 1005){//1005 EXISTE OTRA SESION ACTIVA
            swal.fire({
                title: 'Active session',
                text: 'There is an active session, please re-authenticate',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Login',//Continuar
            }).then(result => {
                if(result.isConfirmed){
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = '/login'
                }
                if(result.isDismissed){
                    
                }
            })
        }else{
            return data.data
        }
    } catch (error) {
        console.error('sucedio un error al validar el codigo del token: ',error)
    }
}
module.exports = {
    validationCodeToken,
}

