var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "b-col",
        { staticClass: "mt-2 pl-3 pr-5", attrs: { cols: "8" } },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c("span", { staticClass: "title-style" }, [_vm._v("Likes")]),
            _c("i", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover",
                  value: {
                    variant: "primary",
                    content:
                      "New Likes in date range divided into Organic and Paid",
                  },
                  expression:
                    "{\n          variant: 'primary',\n          content:\n            'New Likes in date range divided into Organic and Paid',\n        }",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "fa fa-info-circle icoPop",
            }),
          ]),
          !_vm.boolViewData
            ? _c("div", { staticClass: "image-container-off" }, [
                _c("img", {
                  staticClass: "off_op",
                  staticStyle: { opacity: "0.5", width: "100%" },
                  attrs: {
                    src: require("@/assets/images/image-off-netlikes.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "image-container-off__message" }, [
                  _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                  _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                ]),
              ])
            : _c("highcharts", { attrs: { options: _vm.getOptions() } }),
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass:
            "pl-5 pr-0 pt-2 mt-5 d-flex justify-content-between panels",
          attrs: { cols: "4" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "bg-facebook h-24 rounded d-flex flex-column justify-content-center align-items-center",
            },
            [
              _c(
                "p",
                {
                  staticClass: "white-color-text font-weight-bold text-center",
                },
                [_vm._v("Organic")]
              ),
              _c(
                "p",
                { staticClass: "bx_Content_color text-white text-center" },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " + _vm._s(_vm.numFormatter(_vm.dataList.organic)) + " "
                    ),
                  ]),
                  _c("i", { staticClass: "fa fa-sort-up text-white" }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "bg-warning h-24 rounded d-flex flex-column justify-content-center align-items-center",
            },
            [
              _c(
                "p",
                {
                  staticClass: "white-color-text font-weight-bold text-center",
                },
                [_vm._v("Paid")]
              ),
              _c(
                "p",
                { staticClass: "bx_Content_color text-white text-center" },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " + _vm._s(_vm.numFormatter(_vm.dataList.paid)) + " "
                    ),
                  ]),
                  _c("i", { staticClass: "fa fa-sort-up text-white" }),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }