var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "b-col",
        { staticClass: "mt-2 pl-3 pr-5", attrs: { cols: "8" } },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Reactions "),
              _c("i", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover",
                    value: {
                      variant: "primary",
                      content: "People who reacted on your posts",
                    },
                    expression:
                      "{\n            variant: 'primary',\n            content: 'People who reacted on your posts',\n          }",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "fa fa-info-circle text-skygray",
              }),
            ]),
          ]),
          _vm.view
            ? _c("highcharts", { attrs: { options: _vm.options } })
            : _c("div", { staticClass: "image-container-off" }, [
                _c("img", {
                  staticStyle: { opacity: "0.5", width: "100%" },
                  attrs: {
                    src: require("@/assets/images/image-off-emotions.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "image-container-off__message" }, [
                  _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                  _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                ]),
              ]),
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "pl-5 pr-2", attrs: { cols: "4" } },
        _vm._l(_vm.sentiments, function (sentiment, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "d-flex justify-content-between w-100 h-12",
            },
            [
              _c("span", [
                _c("img", {
                  staticClass: "h-4",
                  attrs: { src: sentiment.iconPath },
                }),
                _vm._v(" " + _vm._s(sentiment.name) + " "),
              ]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.numFormatter(_vm.getSentimentCant(sentiment.name))
                    ) +
                    " "
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }