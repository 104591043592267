import { render, staticRenderFns } from "./NewFollowersChart.vue?vue&type=template&id=43cd1ac5&"
import script from "./NewFollowersChart.vue?vue&type=script&lang=js&"
export * from "./NewFollowersChart.vue?vue&type=script&lang=js&"
import style0 from "./NewFollowersChart.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-overview/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43cd1ac5')) {
      api.createRecord('43cd1ac5', component.options)
    } else {
      api.reload('43cd1ac5', component.options)
    }
    module.hot.accept("./NewFollowersChart.vue?vue&type=template&id=43cd1ac5&", function () {
      api.rerender('43cd1ac5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/channel-detail/socials/instagram/tabs/Audience/NewFollowersChart.vue"
export default component.exports