var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 bg-mainbg" },
    [
      _c(
        "bread-crumb",
        [
          _c("v-datepicker", {
            ref: "datePicker",
            staticClass: "mr-3",
            attrs: {
              range: "",
              type: "date",
              clearable: false,
              "range-separator": " - ",
              format: "DD/MM/YYYY",
              "disabled-date": _vm.disabledAfter,
            },
            on: { change: _vm.changeDate },
            model: {
              value: _vm.rangeDate,
              callback: function ($$v) {
                _vm.rangeDate = $$v
              },
              expression: "rangeDate",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 " },
        [
          !_vm.isLoading && _vm.rangeDate.length > 1
            ? _c(
                "b-container",
                { key: _vm.updateChanges, attrs: { fluid: "" } },
                [
                  _vm.socialType === "facebook"
                    ? _c("facebook-detail", {
                        attrs: {
                          "range-date": _vm.rangeDate,
                          "channel-id": _vm.channelId,
                        },
                      })
                    : _vm.socialType === "instagram"
                    ? _c("instagram-detail", {
                        attrs: {
                          "range-date": _vm.rangeDate,
                          "channel-id": _vm.channelId,
                        },
                      })
                    : _vm.socialType === "youtube"
                    ? _c("youtube-detail", {
                        attrs: {
                          "range-date": _vm.rangeDate,
                          "channel-id": _vm.channelId,
                        },
                      })
                    : _vm.socialType === "twitter"
                    ? _c("twitter-detail", {
                        attrs: {
                          "range-date": _vm.rangeDate,
                          "channel-id": _vm.channelId,
                        },
                      })
                    : _vm.socialType === "linkedin"
                    ? _c("linkedin-detail", {
                        attrs: {
                          "range-date": _vm.rangeDate,
                          "channel-id": _vm.channelId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }