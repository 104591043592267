var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("total-posts", {
        attrs: {
          series: _vm.detail.totals.graph,
          total: _vm.detail.totals.total,
          categories: _vm.categories,
          boolViewData: _vm.detail.totals.boolViewData,
        },
      }),
      _c("div", { staticClass: "w-100 h-20" }),
      _c("posts-by-day", {
        attrs: {
          series: _vm.detail.byDay.graph,
          day: _vm.detail.byDay.mostUsedDay,
          hour: _vm.detail.byDay.mostUsedHour,
          postIndicators: _vm.detail.postIndicators,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }