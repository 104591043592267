<template>
  <p class="py-1 icon-reaction text-color-text">
    <i 
      class=" mr-2 w-4 d-inline-block"
      :class="[ icon ]"
    >
    </i> {{ name }}
    <span class="float-right">{{ amount }}</span>
  </p>
</template>

<script>
export default {
  name: "CpIcon",
  props: {
    name: {
      type: String,
      default: () => {
        return "Like";
      },
    },
    amount: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "far fa-thumbs-up",
    },
  },
};
</script>

<style>
.icon-reaction {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
}
</style>
