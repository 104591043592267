var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("interactions-total-chart", {
        attrs: {
          series: _vm.detail.total.graph,
          dataList: _vm.detail.total.dataList,
          categories: _vm.chartCategories,
          chartCategories: _vm.chartCategories,
        },
      }),
      _c("div", { staticClass: "w-100 h-20" }),
      _c("interactions-types-chart", {
        attrs: {
          series: _vm.detail.types.graph,
          dataList: _vm.detail.types.dataList,
          chartCategories: _vm.chartCategories,
        },
      }),
      _c("div", { staticClass: "w-100 h-20" }),
      _c("reactions-chart", {
        attrs: {
          series: _vm.detail.reactions.graph,
          dataList: _vm.detail.reactions.dataList,
          chartCategories: _vm.chartCategories,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }