var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-4/12 pl-lg-3 pl-xl-4 pr-2" }, [
    _c("div", { staticClass: "mb-4" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c(
          "span",
          {
            staticClass:
              "font-weight-bold text-capitalize text-lsm text-secondary mr-2",
          },
          [_vm._v("Engaged users by post type ")]
        ),
        _c("i", {
          directives: [
            {
              name: "b-popover",
              rawName: "v-b-popover.hover",
              value: {
                variant: "primary",
                content: "Total users engaged by type of publication",
              },
              expression:
                "{\n          variant: 'primary',\n          content: 'Total users engaged by type of publication',\n        }",
              modifiers: { hover: true },
            },
          ],
          staticClass: "fa fa-info-circle text-skygray",
        }),
      ]),
    ]),
    _c("div", { staticClass: "w-100 text-semidark" }, [
      _c("div", { staticClass: "d-flex align-items-center pr-4 mb-4" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass:
              "flex-grow-1 position-relative d-flex justify-content-start ml-2 pt-1",
          },
          [
            _c("div", {
              staticClass: "position-relative z-0 bg-mainbg w-100 h-2",
            }),
            _c("div", {
              staticClass: "position-absolute z-10 h-2 bg-facebook top-4 pt-1",
              style: "width: " + _vm.count.image + "%; max-width : 100%",
            }),
          ]
        ),
        _c(
          "span",
          {
            staticClass:
              "flex-none text-xs ml-3 font-weight-bold text-color-text",
          },
          [_vm._v(_vm._s(_vm.numFormatter(_vm.count.image)))]
        ),
      ]),
      _vm.viewtext == true
        ? _c("div", { staticClass: "d-flex align-items-center pr-4 mb-4" }, [
            _vm._m(1),
            _c(
              "div",
              {
                staticClass:
                  "flex-grow-1 position-relative d-flex justify-content-start ml-2 pt-1",
              },
              [
                _c("div", {
                  staticClass: "position-relative z-0 bg-mainbg w-100 h-2",
                }),
                _c("div", {
                  staticClass:
                    "position-absolute z-10 h-2 bg-facebook top-4 pt-1",
                  style: "width: " + _vm.count.text + "%; max-width : 100%",
                }),
              ]
            ),
            _c(
              "span",
              {
                staticClass:
                  "flex-none text-xs ml-3 font-weight-bold text-color-text",
              },
              [_vm._v(_vm._s(_vm.numFormatter(_vm.count.text)))]
            ),
          ])
        : _vm._e(),
      _vm.viewvideo == true
        ? _c("div", { staticClass: "d-flex align-items-center pr-4 mb-4" }, [
            _vm._m(2),
            _c(
              "div",
              {
                staticClass:
                  "flex-grow-1 position-relative d-flex justify-content-start ml-2 pt-1",
              },
              [
                _c("div", {
                  staticClass: "position-relative z-0 bg-mainbg w-100 h-2",
                }),
                _c("div", {
                  staticClass:
                    "position-absolute z-10 h-2 bg-facebook top-4 pt-1",
                  style: "width: " + _vm.count.video + "%; max-width : 100%",
                }),
              ]
            ),
            _c(
              "span",
              {
                staticClass:
                  "flex-none text-xs ml-3 font-weight-bold text-color-text",
              },
              [_vm._v(_vm._s(_vm.numFormatter(_vm.count.video)))]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fas fa-image text-xs text-color-text" }),
      _vm._v("   "),
      _c(
        "span",
        { staticClass: "flex-none leading-none text-xs text-color-text" },
        [_vm._v(" Image ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "far fa-file-alt text-xs text-color-text" }),
      _vm._v("   "),
      _c(
        "span",
        { staticClass: "flex-none leading-none text-xs text-color-text" },
        [_vm._v(" Text ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fas fa-video text-xs text-color-text" }),
      _vm._v("   "),
      _c(
        "span",
        { staticClass: "flex-none leading-none text-xs text-color-text" },
        [_vm._v(" Video ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }