<template>
  <div class="w-100">
    <net-likes-chart :v-if="detail.netLikes" :series="detail.netLikes.graph" 
    :dataList="detail.netLikes.dataList" 
    :boolViewData="detail.boolViewDataNetLikes" 
    :chartCategories="chartCategories"/>

    <div class="w-100 "></div>
    <fans-trend-chart :v-if="detail.fansTrend" 
    :series="detail.fansTrend.graph" 
    :dataList="detail.fansTrend.dataList" 
    :chartCategories="chartCategories" 
    :boolViewData="detail.boolViewDataFansTrend" 
    />
    <div class="w-100 h-20"></div>
    <!-- <reach-chart :v-if="detail.reach" 
    :series="detail.reach.graph" 
    :dataList="detail.reach.dataList" 
    :chartCategories="chartCategories"
    :boolViewData="detail.boolViewDataReach"
    /> -->
  </div>
</template>

<script>
import FansTrendChart from "./FansTrendChart.vue";
import NetLikesChart from "./NetLikesChart.vue";
import ReachChart from "./ReachChart.vue";
export default {
  components: { NetLikesChart, ReachChart, FansTrendChart },
  props: {
    detail: {
      type: Object,
      required: true,
    },
    chartCategories : {
      type : Array,
      required : true
    },
  },
  async created() {
  }
};
</script>
