<template>
  <b-row no-gutters class="mt-2">
    <b-col cols="8" class="pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >Posts
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content: 'Posts published by day and hour',
            }"
          ></i>
        </span>
      </div>
      <div
        v-if="!series.boolViewData"
        class="image-container-off"
      >
        <img
          class="off_op"
          src="@/assets/images/image-off-graphpost-channel.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
      <highcharts v-else :options="getOptions()" />
    </b-col>
    <b-col cols="4" class="pl-5 pr-2">
      <div class="bg-instagram rounded p-4 text-white">
        <p>Most used time to post</p>
        <p class="text-2xl font-weight-light">
          {{ postIndicators.horarioTiempo }}
        </p>
      </div>
      <div class="rounded p-4">
        <p>Total Posts</p>
        <p class="text-4xl font-weight-bold">{{ series.total }}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../../../util/config";
var dataGraph = [];
export default {
  props: {
    postIndicators: {
      type: Object,
      required: true,
    },
    series: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {
    getOptions() {
      try {
        let options = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "scatter",
            zoomType: "xy",
            marginTop: 10,
            marginBottom: 80,
          },
          credits: { text: "", href: process.env.VUE_APP_ADOSTELECOM },
          exporting: { enabled: false },
          title: { text: null },
          xAxis: {
            title: { text: "Days", align: "middle" },
            min: 0,
            startOnTick: true,
            endOnTick: true,
            tickInterval: 1,
            labels: {
              autoRotation: false,
              rotation: 0,
              style: { color: "#ababab" },
            },
          },
          yAxis: {
            title: { text: "Hours" },
            labels: {
              formatter: function () {
                return this.value + ":00";
              },
            },
            max: 24,
            tickInterval: 1,
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
            plotBands: [
              {
                from: 5,
                to: 12,
                color: "rgba(68, 170, 213, 0.1)",
                label: {
                  text: "Morning",
                  style: { color: "#606060" },
                },
              },
              {
                from: 12,
                to: 18,
                color: "rgba(0,0,0,0)",
                label: {
                  text: "Afternoon",
                  style: { color: "#606060" },
                },
              },
              {
                from: 18,
                to: 24,
                color: "rgba(68, 170, 213, 0.1)",
                label: {
                  text: "Night",
                  style: { color: "#606060" },
                },
              },
            ],
          },
          tooltip: {
            backgroundColor: "#ff69a1",
            borderColor: "#ff69a1",
            textColor: "#FFF",
            formatter: function () {
              if (this.point.clusteredData) {
                return "Clustered points: ";
              }
              return '<b style="color:#fff">View Post</b>';
            },
          },
          legend: {
            enabled: false,
            align: "right",
            x: 10,
            verticalAlign: "bottom",
            y: 10,
            floating: true,
            borderWidth: 0,
            shadow: false,
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: { enabled: true, lineColor: "rgb(100,100,100)" },
                },
              },
              states: {
                hover: { marker: { enabled: false } },
              },
            },
            series: {
              point: {
                events: {
                  click: function (event) {
                    const dataOption = event.point.options;

                    const indexGraphEquals = dataOption.x + "" + dataOption.y;
                    let respUrl = dataGraph.filter(
                      (it) => it.indexGraph == indexGraphEquals
                    );
                    window.open(respUrl[0].url);
                  },
                },
              },
              cursor: "pointer",
            },
          },
          series: [],
        };
        options.series = this.series.graph || [];
        options.xAxis.max = this.categories.length - 1;
        options.xAxis.categories = this.categories;
        dataGraph = this.series.graph[0].arrUrl;
        return options;
      } catch (error) {
        return {};
      }
    },
  },
};
</script>

<style>
.off_op {
  opacity: 0.5;
}
</style>
