<template>
  <div>
    <highcharts :options="getOptions()" />
  </div>
</template>
<script>
export default {
  props: {
    titleyAxis: {
      type: String,
      required: true,
    },
    titlexAxis: {
      type: String,
      required: true,
    },
    series: {
      type: Array,
      default: [],
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getOptions() {
      var _titleyAxis = this.titleyAxis;
      var _titlexAxis = this.titlexAxis;
      var _categories = this.categories;
      try {
        let options = {
          chart: {
            type: "column",
          },
          title: { text: "" },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
              },
            },
          },
          legend: {
            align: "right",itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          navigation: {
            buttonOptions: {
              enabled: false,
            },
          },
          credits: {
            enabled: false,
          },
        };
        options.xAxis = {
          categories: _categories,
          title: {
            text: _titlexAxis,
          },
        };
        options.yAxis = {
          allowDecimals: false,
          min: 0,
          title: {
            text: _titleyAxis,
          },
          labels: {
            overflow: "justify",
          },
        };
        options.series = this.series
        return options;
      } catch (error) {
        console.error("sucedio un error en getOptions: ", error);
        return {};
      }
    },
  },
};
</script>
