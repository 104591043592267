var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 pt-4 d-flex flex-wrap" },
    [
      _c("audience-total-chart", {
        attrs: {
          series: _vm.audienceDetails.acumulatedAudienceGraph,
          categories: _vm.audienceDetails.chartCategories,
        },
      }),
      _vm.audienceDetails.audienceCount.length > 0
        ? _c("bar-graph", {
            staticClass: "font-weight-bold pl-lg-3 pl-xl-4",
            attrs: {
              socialData: _vm.audienceDetails.audienceCount,
              title: "Total Audience",
              tooltipMsg: "Total amount of current audience",
            },
          })
        : _c("img", {
            staticClass: "w-4/12",
            staticStyle: { opacity: "0.5", height: "30%" },
            attrs: {
              src: require("@/assets/images/off-total-audience.png"),
              alt: "",
            },
          }),
      _c("div", { staticClass: "w-100 h-16" }),
      _c("audience-new-chart", {
        attrs: {
          series: _vm.audienceDetails.newAudienceGraph,
          categories: _vm.numFormatter(_vm.audienceDetails.chartCategories),
        },
      }),
      _c("div", { staticClass: "w-4/12 pl-lg-3 pl-xl-4 totalAudience" }, [
        _c("div", { staticClass: "mb-4" }, [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c(
              "span",
              {
                staticClass:
                  "font-weight-bold text-capitalize text-lsm text-secondary mr-2",
              },
              [_vm._v("Total new Audience ")]
            ),
            _c("i", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover",
                  value: {
                    variant: "primary",
                    content:
                      "Total current amount of new audience per social network",
                  },
                  expression:
                    "{\n            variant: 'primary',\n            content:\n              'Total current amount of new audience per social network',\n          }",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "fa fa-info-circle text-skygray",
            }),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "w-100 d-flex flex-wrap justify-content-between text-white text-center mb-5",
          },
          [
            _vm._l(
              _vm.audienceDetails.audienceCount,
              function (account, index) {
                return _c("div", { key: index, staticClass: "w-1/2 mb-3" }, [
                  _c(
                    "div",
                    {
                      class:
                        "w-10/12 bg-" +
                        account.rrss +
                        " p-xl-4 p-lg-3 base-box",
                    },
                    [
                      _c("p", { staticClass: "ellipsisBase" }, [
                        _c("i", { class: "fab fa-" + account.rrss }),
                        _c("small", { staticClass: "ml-2 text-xs" }, [
                          _vm._v(_vm._s(account.name)),
                        ]),
                      ]),
                      _c("p", [
                        _c("span", { staticClass: "text-lg" }, [
                          _vm._v(_vm._s(_vm.numFormatter(account.newAudience))),
                        ]),
                        _c("i", { class: "fa fa-sort-up" }),
                      ]),
                    ]
                  ),
                ])
              }
            ),
            _vm.audienceDetails.audienceCount.length === 0
              ? _c("div", { staticClass: "image-container-off" }, [
                  _c("img", {
                    staticClass: "opacity-50",
                    attrs: {
                      src: require("@/assets/images/off-total-new-audience.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "image-container-off__message" }, [
                    _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                    _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }