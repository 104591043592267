var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 px-5" },
    [
      _vm.visibilityLoader ? _c("loader-custom") : _vm._e(),
      _c("p", { staticClass: "text-xs mb-4" }, [_vm._v("Name")]),
      _c("b-form-input", {
        staticClass: "mb-4",
        attrs: { placeholder: "Example Name" },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _c("p", { staticClass: "text-xs mb-4" }, [_vm._v("Selected channels")]),
      _vm.accounts.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "box-list-sn mb-4 w-100 border rounded overflow-y-auto p-3",
            },
            _vm._l(_vm.accounts, function (cuenta, index) {
              return _c("div", { key: index, staticClass: "text-sm mb-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: cuenta.checked,
                      expression: "cuenta.checked",
                    },
                  ],
                  staticClass: "box-list-sn_checkbox mr-2",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(cuenta.checked)
                      ? _vm._i(cuenta.checked, null) > -1
                      : cuenta.checked,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = cuenta.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(cuenta, "checked", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              cuenta,
                              "checked",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(cuenta, "checked", $$c)
                      }
                    },
                  },
                }),
                _c("i", {
                  class:
                    "fab fa-" +
                    cuenta.redSocial.toLowerCase() +
                    " text-" +
                    cuenta.redSocial.toLowerCase() +
                    " mr-2",
                }),
                _vm._v(" " + _vm._s(cuenta.nomUsuarioRedsocial) + " "),
              ])
            }),
            0
          )
        : _vm._e(),
      _c("p", { staticClass: "text-xs mb-4" }, [_vm._v("Add new channel")]),
      _c(
        "div",
        {
          staticClass: "text-white d-flex justify-content-around text-sm mb-4",
        },
        [
          _c(
            "b-button",
            {
              staticClass: "text-sm",
              attrs: { variant: "facebook" },
              on: {
                click: function ($event) {
                  return _vm.openDialogLogin("facebook")
                },
              },
            },
            [_c("i", { staticClass: "fab fa-facebook fa-lg" }), _vm._v(" Add ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "text-sm",
              attrs: { variant: "instagram" },
              on: {
                click: function ($event) {
                  return _vm.openDialogLogin("instagram")
                },
              },
            },
            [
              _c("i", { staticClass: "fab fa-instagram fa-lg" }),
              _vm._v(" Add "),
            ]
          ),
          _c(
            "b-button",
            {
              staticClass: "text-sm",
              attrs: { variant: "twitter" },
              on: {
                click: function ($event) {
                  return _vm.openDialogLogin("twitter")
                },
              },
            },
            [_c("i", { staticClass: "fab fa-twitter fa-lg" }), _vm._v(" Add ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "text-sm",
              attrs: { variant: "youtube" },
              on: {
                click: function ($event) {
                  return _vm.openDialogLogin("youtube")
                },
              },
            },
            [_c("i", { staticClass: "fab fa-youtube fa-lg" }), _vm._v(" Add ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "text-sm",
              attrs: { variant: "linkedin" },
              on: {
                click: function ($event) {
                  return _vm.openDialogLogin("linkedin")
                },
              },
            },
            [_c("i", { staticClass: "fab fa-linkedin fa-lg" }), _vm._v(" Add ")]
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: { block: "", variant: "primary", disabled: _vm.loading },
          on: { click: _vm.confirmAccounts },
        },
        [_vm._v(" Save ")]
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.iconCode,
          iconColor: _vm.iconColorCode,
          title: _vm.titleCode,
          paragraph: _vm.paragraphCode,
          buttonMessage: _vm.buttonMessageCode,
          noCloseBackdrop: true,
          refresh: true,
        },
        on: { sendResult: _vm.resultModalAlertsCode },
        model: {
          value: _vm.showAlertCode,
          callback: function ($$v) {
            _vm.showAlertCode = $$v
          },
          expression: "showAlertCode",
        },
      }),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
        },
        on: { sendResult: _vm.resultModalAlerts },
        model: {
          value: _vm.showAlert,
          callback: function ($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }