<template>
  <div>
    <div class="w-100 px-5">
      <div
        class="d-flex align-items-center flex-column py-4 w-100"
        v-if="step === 1"
      >
        <span class="text-primary font-weight-bold text-center mb-4"
          >Set the name of the account</span
        >
        <b-form-input
          class="w-1/3"
          v-model="form.name"
          placeholder="Example Name"
        ></b-form-input>
        <div v-if="isExistedAccount" class="message-alert">
          <i class="fa fa-info-circle"></i>
          That account name already exists
        </div>
      </div>
      <div
        class="d-flex align-items-center flex-column py-4 w-100"
        v-if="step === 2"
      >
        <span class="text-primary font-weight-bold text-center mb-4"
          >Add channels to your account</span
        >
        <div class="d-flex justify-content-center">
          <a
            href="javascript:"
            class="mr-4 text-facebook"
            @click="openDialogLogin('facebook')"
            ><i class="fab fa-facebook fa-lg"></i
          ></a>
          <a
            href="javascript:"
            class="mr-4 text-instagram"
            @click="openDialogLogin('instagram')"
            ><i class="fab fa-instagram fa-lg"></i
          ></a>
          <a
            href="javascript:"
            class="mr-4 text-twitter"
            @click="openDialogLogin('twitter')"
            ><i class="fab fa-twitter fa-lg"></i
          ></a>
          <a
            href="javascript:"
            class="mr-4 text-youtube"
            @click="openDialogLogin('youtube')"
            ><i class="fab fa-youtube fa-lg"></i
          ></a>
          <a
            href="javascript:"
            class="mr-4 text-linkedin"
            @click="openDialogLogin('linkedin')"
            ><i class="fab fa-linkedin fa-lg"></i
          ></a>
        </div>
        <div class="my-4 flex flex-column w-100 mb-0">
          <div
            class="py-1 mb-2 border-bottom w-50 mx-auto d-flex justify-content-between align-items-center"
            v-for="(account, index) in accounts"
            :key="index"
          >
            <span>
              <i
                :class="`fab fa-${account.socialNetwork} fa-lg text-${account.socialNetwork} mr-2`"
              ></i>
              {{ account.accountName }}
            </span>
            <span
              class="h2"
              style="cursor: pointer"
              @click="removeAccount(account.socialNetworkId)"
              >×</span
            >
          </div>
          <div
            v-if="
              accounts.length > 0 && accounts[0].socialNetwork === 'facebook'
            "
            class="pt-05"
          >
            <CpWarningMessage
              justifyContent="center"
              message="You should have at least 10 followers for you to choose the user name in the url."
            ></CpWarningMessage>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center flex-column py-4 w-100"
        v-if="step === 3"
      >
        <span class="text-primary font-weight-bold text-center mb-4"
          >Confirm your settings</span
        >
        <div class="d-flex flex-column align-items-center mb-4">
          <div class="text-center py-2 text-xs">
            <p class="mb-2">Account name</p>
            <span
              class="border-bottom border-skygray px-4 d-inline-block text-center"
              >{{ form.name }}</span
            >
          </div>
        </div>
        <div class="d-flex flex-column align-items-center w-100">
          <div class="text-center py-2 w-100">
            <p class="mb-2">Channels Added</p>
            <div class="my-4 flex flex-column w-100">
              <div
                class="py-2 mb-2 border-bottom w-50 mx-auto"
                v-for="(account, index) in accounts"
                :key="index"
              >
                <i
                  :class="`fab fa-${account.socialNetwork} fa-lg text-${account.socialNetwork} mr-2`"
                ></i>
                {{ account.accountName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stepper w-100">
        <stepper ref="stepper" />
      </div>
      <div class="d-flex justify-content-between py-4 w-100">
        <b-button
          variant="skygray"
          class="text-white px-4"
          @click="prevStep"
          :disabled="step <= 1 || loading"
          >Prev</b-button
        >
        <b-button
          variant="primary"
          class="px-4"
          @click="nextStep"
          :disabled="checkNext || loading || checkValidationName"
          >{{ step === 3 ? "Confirm" : "Next" }}</b-button
        >
      </div>
    </div>
    <div class="d-flex containerModal">
      <isc-modal
        v-model="showModal"
        @sendResult="openModal"
        message="<p>Don’t forget to <strong>“Edit settings”</strong> to select all of your fanpages and give permissions to improve your experience with ISC.</p>"
      />
    </div>
    <!-- ALERT CODE MODAL -->
    <IscModalAlerts
      v-model="showAlertCode"
      :icon="iconCode"
      :iconColor="iconColorCode"
      :title="titleCode"
      :paragraph="paragraphCode"
      :buttonMessage="buttonMessageCode"
      @sendResult="resultModalAlertsCode"
      :noCloseBackdrop="true"
      :refresh="true"
    />
    <!-- MODAL ALERT GLOBAL -->
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
    >
    </IscModalAlerts>
  </div>
</template>

<script>
import Stepper from "./Stepper";
import * as OverviewService from "@/services/overview.service";
import * as RestrictService from "@/services/restrict.service";
import {
  loaderCustom,
  IscModal,
  CpWarningMessage,
  Helpers,
  IscModalAlerts,
  AlertSetting,
  mapMutations,
  mapActions,
  CodeToken,
} from "@isc/styleguide";
const rutaService = require("../../../util/config").rutaService;

export default {
  components: {
    Stepper,
    loaderCustom,
    IscModal,
    CpWarningMessage,
    IscModalAlerts,
  },
  mixins: [AlertSetting, CodeToken],
  props: {
    idClient: {
      type: Number, 
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      step: 1,
      form: {
        name: "",
      },
      accounts: [],
      isExistedAccount: false,
      showModal: false,
      socialNetwork: "",
      titleError: "Error",
      respRestrictAccount: null,
      checkValidationName: false,
    };
  },
  async mounted() {
    this.step = this.$refs.stepper.getStep();
    window.addEventListener("message", this.getPage);
    let self = this; // closure
    window.addEventListener("keyup", function (event) {
      if (event && self.form.name !== "") {
        self.isExistedAccount = false;
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("message", this.getPage);
  },
  methods: {
    ...mapMutations({
      setLoader: "global/helper/setLoader",
    }),
    ...mapActions({
      insertAccounts: "overview/account/INSERT_ACCOUNTS",
      validateDuplicatedName: "overview/account/VALIDATE_DUPLICATED_NAME",
    }),
    async nextStep() {
      if (this.step === 1) {
        this.checkValidationName = true;
        const resp = await this.isDuplicatedAccount(this.form.name);
        if(resp === undefined) return
        this.checkValidationName = false;
        if (resp && resp.flgEstado === 1) {
          let lowerCaseNameResp = resp.dscMarca.toLowerCase();
          let lowerCaseFormName = this.form.name.trim();
          if (lowerCaseFormName.toLowerCase() === lowerCaseNameResp) {
            this.isExistedAccount = true;
            return;
          }
        }
      }

      if (this.step === 3) {
        this.loading = true;
        this.confirmAccounts();
      }
      this.step = this.$refs.stepper.next();
    },
    async confirmAccounts() {
      try {
        this.setLoader(true);
        const data = {
          client_id: this.idClient,
          data: this.accounts,
          brand_name: this.form.name,
        };
        const respInsertAccount = await this.insertAccounts(data);
        const resp = this.validationCodeToken(respInsertAccount.data);
        if (!resp) {
          this.setLoader(false)
          return
        };
        //console.log("RESULT CONFIRMATION ACCOUNTS  ===> ", resp);
        let dataSend = { code: resp.code, message: resp.message || "" };
        if (resp.code && resp.code == 406) {
          dataSend.code = "error";
          dataSend.message = resp.message;
        }

        // Multicanales
        if (!this.respRestrictAccount && dataSend.code === "success") {
          // create a new restrict account if it does not exist on table
          await this.createRestrictAccountBySN(this.accounts);
        }

        if (this.respRestrictAccount && dataSend.code === "success") {
          await this.updateRestrictAccount(
            this.accounts,
            this.respRestrictAccount
          );
        }

        this.$emit("onClose", dataSend);
      } catch (error) {
        this.setLoader(false);
        console.log("[Error] in function confirmAccounts addMultiple ", error);
        let msg =
          error?.response?.data?.message ?? "Server fails to insert accounts.";
        this.errorModal(msg);
      } finally {
        this.loading = false;
      }
    },
    prevStep() {
      this.step = this.$refs.stepper.prev();
      this.checkValidationName = false;
    },
    getPage(event) {
      if (event.data.accountName) {
        this.addAccount(event.data);
      }
    },
    /*
     * Twitter: 2
     * SN: Social Network
     * Restrict modulo : 1
     */
    async openDialogLogin(social_network) {
      this.socialNetwork = social_network;
      if (social_network === "facebook" || social_network === "instagram") {
        this.showModal = true;
      } else if (social_network === "twitter") {
        const numSocialNetwork = Number(process.env.VUE_APP_TWITTER);
        const numLimitModuloBySN =
          process.env.VUE_APP_TWITTER_MODULO_REGISTER_ACCOUNT;
        const resp = await OverviewService.getValidationLimitAccountBySN(
          numSocialNetwork,
          numLimitModuloBySN
        );
        this.respRestrictAccount = resp;
        //console.log("RESP ASSOCIATED ACCOUNTS ==> ", resp);
        if (resp && resp.status) {
          let msg =
            resp?.data?.message ??
            "Server fails to get validation limit account by social network.";
          this.errorModal(msg);
          return;
        }
        this.openModalSocialNetwork(resp, numSocialNetwork);
      } else {
        this.getSocialNetwork();
      }
    },
    addAccount(account) {
      const exist = this.accounts.find(
        (item) => item.socialNetworkId === account.socialNetworkId
      );
      if (exist) {
        let message = "This account is already added.";
        let title = "Error";
        let variant = "danger";
        Helpers.getToastAlert(this.$bvToast, message, title, variant);
      } else {
        this.accounts.push(account);
      }
    },
    removeAccount(socialNetworkId) {
      this.accounts = this.accounts.filter(
        (item) => item.socialNetworkId != socialNetworkId
      );
    },
    getError() {
      let message = "The account was not added.";
      let title = "Error";
      let variant = "danger";
      Helpers.getToastAlert(this.$bvToast, message, title, variant);
    },
    async isDuplicatedAccount(brandName) {
      const data = {
        client_id: this.idClient,
        brand_name: brandName,
      }
      const respDuplicatedName = await this.validateDuplicatedName(data);
      const isExistedName = this.validationCodeToken(respDuplicatedName.data);
      
      if (isExistedName === undefined ) {
        this.checkValidationName = false;
        return;
      }
      return !isExistedName ? false : isExistedName;
    },
    openModal(value) {
      if (!value) return;
      this.getSocialNetwork();
    },
    //* calling SN Portal.
    getSocialNetwork() {
      let w = 680;
      let h = 830;
      let left = screen.width / 2 - w / 2;
      let top = screen.height / 2 - h / 2;
      window.open(
        this.socialNetwork === "instagram"
          ? rutaService + "/facebook/auth/" + this.socialNetwork
          : rutaService + "/" + this.socialNetwork + "/auth",
        "_blank",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          w +
          ", height=" +
          h +
          ", top=" +
          top +
          ", left=" +
          left
      );
      this.socialNetwork = "";
      this.showModal = false;
      window.addEventListener("message", this.getPage);
    },
    /*
     * Validate if the limit of account by client and socialNetwork
     */
    openModalSocialNetwork(resp, idSocialNetwork) {
      if (!resp) this.getSocialNetwork();
      else if (
        resp.LimiteModuloRedSocial.idRedSocial === idSocialNetwork &&
        resp.LimiteModuloRedSocial.numLimite > resp.numConsumo
      ) {
        this.getSocialNetwork();
      } else {
        let msg =
          "you already have a twitter's account registered, if you want to add other account. Go to setting's module and remove it.";
        this.errorModal(msg);
      }
    },
    async createRestrictAccountBySN(accounts) {
      for (const account of accounts) {
        if (account.socialNetwork === "twitter") {
          await this.saveSocialNetworkRestrictAccount(
            account.socialNetwork,
            Number(process.env.VUE_APP_TWITTER_MODULO_REGISTER_ACCOUNT)
          );
        }
      }
    },
    async updateRestrictAccount(accounts, periodAccount) {
      for (const account of accounts) {
        if (account.socialNetwork === "twitter") {
          await this.updateSocialNetworkRestrictAccount(
            Number(process.env.VUE_APP_TWITTER_MODULO_REGISTER_ACCOUNT),
            periodAccount
          );
        }
      }
    },
    async saveSocialNetworkRestrictAccount(socialNetwork, idModulo) {
      const data = {
        idLimiteModuloRedSocial: idModulo,
        dscPeriodoConsumo: "Amount of Account",
        redsocial: socialNetwork,
        numConsumo: 1,
        numSumaAcciones: 1,
      };
      const resp = await RestrictService.insertRestrictAccount(data);
      if (resp && resp.status) {
        let msg =
          resp?.data?.message ?? "Server fails to insert restrict account.";
        this.errorModal(msg);
      }
    },
    async updateSocialNetworkRestrictAccount(idModulo, periodAccount) {
      const data = {
        numConsumo: this.validationConsumeUpdate(periodAccount),
        numSumaAcciones: periodAccount.numSumaAcciones + 1,
      };
      const resp = await RestrictService.updateRestrictAccount(data, idModulo);
      if (resp && resp.status) {
        let msg =
          resp?.data?.message ?? "Server fails to update restrict account.";
        this.errorModal(msg);
      }
    },
    validationConsumeUpdate(periodConsume) {
      let numConsume = periodConsume.numConsumo;
      let limitConsume = periodConsume.LimiteModuloRedSocial.numLimite;
      if (numConsume < limitConsume) return numConsume + 1;
      else return numConsume;
    },
  },
  computed: {
    checkNext: function () {
      if (this.step === 1) {
        return this.form.name === "" || this.form.name === null;
      } else if (this.step === 2) {
        return this.accounts.length <= 0;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.containerModal {
  justify-content: center;
  align-items: center;
}
.message-alert {
  color: #ff9a9a;
  font-weight: 900;
  font-size: 12px;
  line-height: 24px;
  margin-top: 9px;
}
</style>
