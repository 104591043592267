<template>
  <b-row no-gutters class="mt-2">
    <b-col cols="8" class="pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >New followers
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content: 'New followers instagram',
            }"
          ></i>
        </span>
      </div>
      <div
      v-if="!view"
      class="image-container-off"
      >
      <img
      class="opacity-50"
      src="@/assets/images/images-off-twitteraudience-new.png"
      alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    <highcharts v-else :options="getOptions()" />
    </b-col>
    <b-col cols="4" class="pl-5 pr-2">
      <div class="bg-instagram rounded p-4 text-white">
        <p>New followers</p>
        <p class="text-4xl font-weight-bold">{{ numFormatter(total) }}</p>
      </div>
      <div class="rounded p-4">
        <p>Average New followers</p>
        <p class="text-4xl font-weight-bold">{{ numFormatter(average) }}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../../../util/config";
export default {
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      view: false,
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  methods: {
    getOptions() {
      try {
        let options = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "spline",
            marginTop: 10,
            height: 360,
            marginBottom: 70,
          },
          title: { text: "", enabled: false },
          credits: { text: "" },
          xAxis: {
            title: { text: "Days", align: "middle" },
            lineColor: "#D6DBDF",
            tickLength: 0,
            type: "datetime",
            categories: [],
            labels: {
              formatter: function () {
                return this.value;
              },
              rotation: 0,
              autoRotation: false,
              align: "center",
              style: { color: "#999999" },
            },
            dateTimeLabelFormats: {
              day: "%e of %b",
            },
          },
          yAxis: [
            {
              allowDecimals: false,
              tickInterval: 1,
              title: { text: "New Followers", align: "middle" },
              min: null,
              labels: { style: { color: "#B3B4B8" } },
            },
            {
              allowDecimals: false,
              tickInterval: 1,
              gridLineColor: "#D5DAE0",
              title: { enabled: false, text: "", align: "middle" },
              min: null,
              labels: { style: { color: "#B3B4B8" } },
              opposite: true,
            },
          ],
          plotOptions: {
            line: {
              marker: {
                enabled: true, // muestra el punto
              },
              connectNulls: true,
            },
            series: {
              pointWidth: 4, //width of the column bars irrespective of the chart size
              allowPointSelect: true,
            },
            spline: { marker: { lineWidth: 1, radius: 3, symbol: "circle" } },
            areaspline: {
              marker: { lineWidth: 1, radius: 3, symbol: "circle" },
            },
          },
          tooltip: {
            formatter: function () {
              let text =
                "<table class='table_tooltip' border=0 cellspacing=0 cellpadding='5px' style=''>";
              text +=
                "<tr><td class='tp-table' colspan='2' style=''>" +
                this.x +
                "</td></tr>";
              for (let i = 0; i < this.points.length; i++) {
                text += "<tr><td style='padding:4px;padding-left:8px;'>";
                text +=
                  "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                  this.points[i].series.color +
                  " !important'></i> " +
                  this.points[i].series.name;
                text +=
                  "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
                text += Helpers.numberWithCommas(this.points[i].y);
                text += "</td></tr>";
              }
              text += "</table>";
              return text;
            },
            shared: true,
            padding: 0,
            backgroundColor: "#ffffff",
            useHTML: true,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            crosshairs: true,
          },

          exporting: { enabled: false },
          labels: {
            items: [
              {
                style: { left: "40px", top: "8px", color: "#ACADAF" },
              },
            ],
          },
          legend: {
            align: "right",
            verticalAlign: "bottom",
            y: 10,
            floating: true,
            borderWidth: 0,
            shadow: false,
            symbolPadding: 10,
            symbolWidth: 5,
          },
          series: [],
        };

        options.series = this.series;
        options.xAxis.categories = this.categories;
        return options;
      } catch (error) {
        return {};
      }
    },
    validate() {
      for (const item of this.series) {
        item.data.forEach((element) => {
          if (element > 0) {
            this.view = true;
            return;
          }
        });
      }
    },
  },
  props: {
    series: {
      required: true,
      type: Array,
    },
    total: {
      type: Number,
      required: true,
    },
    average: {
      type: Number,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.validate();
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  }
};
</script>

<style>
.off_op {
  opacity: 0.5;
}
</style>
