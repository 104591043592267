var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.postsDetails
    ? _c(
        "div",
        { staticClass: "w-100 pt-4 d-flex flex-wrap" },
        [
          _c("posts-total-char", {
            attrs: {
              series: _vm.postsDetails.postsGraph,
              categories: _vm.postsDetails.chartCategories,
              viewGraph: _vm.postsDetails.viewGraph,
              arrDataUrl: _vm.postsDetails.arrDataUrl,
            },
          }),
          _c("bar-graph", {
            staticClass: "font-weight-bold pl-lg-3 pl-xl-4",
            attrs: {
              callcomponentType: 1,
              socialData: _vm.postsDetails.totalPostByRrss,
              title: "Total Posts",
              tooltipMsg: "Total number of posts by associated social networks",
            },
          }),
          _c("div", { staticClass: "w-100 h-16" }),
          _c("post-per-day-chart", {
            attrs: { series: _vm.postsDetails.postPerDayGraph },
          }),
          _c("most-used", {
            attrs: {
              mostUsedDays: _vm.postsDetails.mostUsedDays,
              mostUsedHours: _vm.postsDetails.mostUsedHours,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }