<template>
  <div class="variationItem-box">
    <div
      class="variationItem-box__item w-100 d-flex flex-column position-relative"
      :class="`bg-${backgrounColor}`"
      :style="`${
        backgrounColor !== 'white' ? 'color : white' : 'color : #2c3852'
      }`"
    >
      <i
        class="position-absolute icon-i"
        :class="['fab', `fa-${rrss}`, `fa-lg`]"
      ></i>
      <div class="variationItem-box__item__info d-flex flex-column">
        <span class="text-capitalize"
          >{{ title }}
          <i
            :id="`${title}-icon`"
            class="fa fa-info-circle variationItem-box__item__icon-info"
            aria-hidden="true"
            :style="`${
              title !== 'audience' ? 'color : #a2acc4' : 'color : white'
            }`"
          ></i>
          <CpTooltipWhiteCustom
            :target="`${title}-icon`"
            :title="`${title}-icon`"
            message="Variation: Start date vs. end date."
          ></CpTooltipWhiteCustom>
        </span>
        <span
          class="font-weight-bold"
          :style="`${
            backgrounColor !== 'white' ? 'color : white' : 'color : #2c3852'
          }`"
          >{{ numFormatter(cant) }}</span
        >
      </div>
      <div
        class="w-100 variationItem-box__item__data d-flex justify-content-between align-items-baseline"
      >
        <span
          class="variationItem-box__item__data__name"
          :style="`${
            backgrounColor !== 'white' ? 'color : white' : 'color : #626b86'
          }`"
          >Variation</span
        >
        <div
          class="variationItem-box__item__data__level d-flex align-items-start"
        >
          <span
            :style="`${
              backgrounColor !== 'white' ? 'color : white' : 'color : #626b86'
            }`"
            >{{ level }}%</span
          >
          <i
            class="iconLevel"
            :class="`fa fa-sort-${up ? 'up text-success' : 'down text-danger'}`"
            :style="`${
              backgrounColor !== 'white' ? 'color : white !important' : ''
            }`"
          >
          </i>
        </div>
      </div>
      <div class="position-relative w-100">
        <div
          class="position-relative z-0 h-2"
          style="background-color: #f3f4f7; opacity: 0.6"
        ></div>
        <div
          :class="[
            'position-absolute',
            'h-2',
            'top-0',
            title == 'audience' ? 'bg-exception' : `bg-${rrss}`,
          ]"
          :style="`${
            backgrounColor !== 'white' ? 'color : white' : ''
          };width: ${level}%; z-index: 2;`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { CpTooltipWhiteCustom, Helpers } from "@isc/styleguide";
export default {
  components: { CpTooltipWhiteCustom },
  props: {
    backgrounColor: {
      type: String,
      default: "white",
    },
    cant: Number,
    title: String,
    level: Number,
    up: Boolean,
    metrics: Object,
    rrss: String,
  },
  data: () => ({
    numFormatter: (num) => Helpers.numFormatter(num),
  }),
  mounted() {
    //console.log("cards", this.rrss);
  },
};
</script>

<style>
.variationItem-box {
  width: 24%;
}
.variationItem-box__item {
  padding: 30px 20px;
  border-radius: 6px;
}
.variationItem-box__item__icon-info {
  font-size: 16px;
  padding-left: 0.2rem;
  /* color: #a2acc4; */
}
.variationItem-box__item .icon-i {
  color: #fff;
  font-size: 2em;
  top: 15px;
  right: 15px;
}
.variationItem-box__item__info span {
  font-size: 0.9375em;
}
.variationItem-box__item__info span + span {
  font-size: 2em;
}
.variationItem-box__item__info span,
.variationItem-box__item__info span + span {
  line-height: normal;
}
.variationItem-box__item__data {
  margin-top: 10px;
}
.variationItem-box__item__data__name {
  font-size: 0.75em;
}
.variationItem-box__item__data__level span {
  font-size: 1.125em;
}
.variationItem-box__item__data__level i {
  font-size: 1em;
}
.variationItem-box__item__data__level i.fa-sort-up {
  margin-top: 4px;
}
</style>
