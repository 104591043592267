var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-8/12 pl-3 pr-5" },
    [
      _c("div", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "span",
            {
              staticClass:
                "font-weight-bold text-capitalize text-lsm text-secondary mr-2",
            },
            [_vm._v(_vm._s(_vm.title) + " ")]
          ),
          _c("i", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover",
                value: {
                  variant: "primary",
                  content: "Interactions day week",
                },
                expression:
                  "{\n          variant: 'primary',\n          content: 'Interactions day week',\n        }",
                modifiers: { hover: true },
              },
            ],
            staticClass: "fa fa-info-circle text-skygray",
          }),
        ]),
      ]),
      !_vm.view
        ? _c("div", { staticClass: "image-container-off" }, [
            _c("img", {
              staticClass: "opacity-50",
              attrs: {
                src: require("@/assets/images/image-off-interacions-by-day-off-week.png"),
                alt: "",
              },
            }),
            _c(
              "div",
              {
                staticClass: "image-container-off__message opacity-required-35",
              },
              [
                _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
              ]
            ),
          ])
        : _c("graph-bar", {
            attrs: {
              titleyAxis: _vm.titleyAxis,
              titlexAxis: _vm.titlexAxis,
              series: _vm.series,
              categories: _vm.categories,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }