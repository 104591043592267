<template>
  <div class="w-100 d-flex justify-content-between mb-4">
    <div class="w-100 d-flex align-items-center">
      <img
        class="profile_img d-inline-block"
        :src="logo"
        alt="foto de perfil"
        @error="errorUrlImage"
      />
      <span class="font-weight-bold mx-2">{{ accountName }}</span>
      <i :class="`fab fa-${rrss} mr-3 text-${rrss}`"></i>
    </div>
    <b-button
      variant="primary"
      style="font-size: 12px; width: 17%"
      class="shadow px-4 py-2 w-3/12"
      @click="getPageOther"
    >
      View social media profile
    </b-button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    detail: Object,
    accountName: String,
    rrss: String,
    logo: String,
  },
  methods: {
    getPageOther() {
      window.open(this.detail.idUrlPage);
    },
    errorUrlImage(event) {
      // changing for default image.
      event.target.src = require("../../assets/images/off-account_40x40.png");
    },
  },
};
</script>
<style scoped>
.profile_img {
  border-radius: 999999999px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
</style>
