var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 d-flex justify-content-between mb-4" },
    [
      _c("div", { staticClass: "w-100 d-flex align-items-center" }, [
        _c("img", {
          staticClass: "profile_img d-inline-block",
          attrs: { src: _vm.logo, alt: "foto de perfil" },
          on: { error: _vm.errorUrlImage },
        }),
        _c("span", { staticClass: "font-weight-bold mx-2" }, [
          _vm._v(_vm._s(_vm.accountName)),
        ]),
        _c("i", { class: "fab fa-" + _vm.rrss + " mr-3 text-" + _vm.rrss }),
      ]),
      _c(
        "b-button",
        {
          staticClass: "shadow px-4 py-2 w-3/12",
          staticStyle: { "font-size": "12px", width: "17%" },
          attrs: { variant: "primary" },
          on: { click: _vm.getPageOther },
        },
        [_vm._v(" View social media profile ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }