<template>
  <div>
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <div class="w-100 detail-content-box" v-if="detail">
      <profile-bar
        :detail="detail"
        :accountName="detail.name"
        :rrss="'instagram'"
        :logo="detail.logo"
      />

      <div class="w-100 d-flex text-left mb-4 box-card-channel" :class="flexClass">
        <variation-item
          class="box-card-channel__item"
          v-for="(variation, index) in detail.variations"
          :key="index"
          :backgrounColor="variation.background"
          :title="variation.title"
          :level="variation.level"
          :cant="variation.cant"
          :up="variation.up"
          :rrss="'instagram'"
        />
      </div>

      <!-- tabs -->
      <tabs :cant="3" :titles="tabs" @change="changeTab" />

      <!-- tabs content-->
      <div class="w-100 bg-white p-xl-5 p-lg-3">
        <!-- <transition name="slide" mode="in-out"> -->
          <audience v-if="activeTab === 0" :detail="detail.audience" :categories="detail.chartCategories"/>
          <posts v-else-if="activeTab === 1" :detail="detail.posts" :categories="detail.chartCategories"/>
          <interactions v-else :detail="detail.interactions" :categories="detail.chartCategories"/>
        <!-- </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBar from "../../ProfileBar.vue";
import VariationItem from "../../VariationItem.vue";
import Audience from "./tabs/Audience/AudienceIg";
import Interactions from "./tabs/Interactions/InteractionsIg";
import Posts from "./tabs/Posts/PostsIg";
import { Tabs } from "@isc/styleguide";

import { instagramDetailByChannel } from "@/services/channel.service";
import { loaderCustom } from "@isc/styleguide"; //"../transversal/loader.vue";

export default {
  components: {
    ProfileBar,
    VariationItem,
    Audience,
    Interactions,
    Posts,
    Tabs,
    loaderCustom,
  },
  props: {
    ChannelId: {
      type: [String, Number],
      required: true,
    },
    RangeDate: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      detail: null,
      tabs: [
        {
          name: "Audience",
        },
        {
          name: "Posts",
        },
        {
          name: "Interactions",
        },
      ],
      flexClass: 'boxItemLess',
      visibilityLoader: true,
    };
  },
  async created() {
    const detail = await instagramDetailByChannel(this.ChannelId, {
      init: this.RangeDate[0],
      end: this.RangeDate[1],
    }); //TODO : OBTENER ID DE CHANNEL
    this.detail = detail;
    (this.detail.variations.length) > 3 ? this.flexClass = 'boxItemMore': this.flexClass = 'boxItemLess';
    this.visibilityLoader = false

  },
  methods: {
    changeTab(position) {
      this.activeTab = position;
    },
  },
};
</script>

<style>
 .detail-content-box{
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
}

</style>
