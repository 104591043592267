<template>
  <div class="w-100 bg-mainbg">
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <bread-crumb>
      <div class="d-flex justify-content-end align-items-center">
        <span style="width: 4rem; font-size: 14px"> Filter by:</span>
        <b-form-select
          class="select_socials"
          v-model="social"
          :options="socials"
          @change="filterChannels"
        >
        </b-form-select>
      </div>
    </bread-crumb>

    <div class="mt-4" v-if="channels">
      <b-container fluid class="detail-content-box">
        <div class="d-flex flex-wrap justify-content-between">
          <channel-box
            v-for="(channel, index) in channels"
            :key="index"
            :accountData="channel"
          />
        </div>
        <div class="row pd-lr-10">
          <div class="col-md-12">
            <div id="paginado">
              <ul id="pagination">
                <li
                  :class="[
                    'li_paginado',
                    index == currentpage ? 'select_paginado' : '',
                  ]"
                  v-for="index in totalPages"
                  @click="pagination(index, social)"
                  :key="index"
                  id="1"
                  style=""
                >
                  {{ index }}
                </li>
              </ul>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </b-container>
    </div>
    <ModalTokensExpired :cuentas="cuentasexpired"></ModalTokensExpired>
  </div>
</template>

<script>
import { BreadCrumb } from "@isc/styleguide";
import ChannelBox from "../components/ChannelBox.vue";
import * as OverviewService from "@/services/overview.service";
import { listChannelsByClient } from "@/services/channel.service";
import ModalTokensExpired from "../components/modal-contents/tokenExpired/ModalTokensExpired.vue";
import { loaderCustom } from "@isc/styleguide"; //"../transversal/loader.vue";

export default {
  name: "Accounts",
  components: {
    BreadCrumb,
    ChannelBox,
    ModalTokensExpired,
    loaderCustom,
  },
  data() {
    return {
      social: 0,
      socials: [
        {
          value: 0,
          text: "All",
        },
        {
          value: 1,
          text: "facebook",
        },
        {
          value: 2,
          text: "twitter",
        },
        {
          value: 4,
          text: "youtube",
        },
        {
          value: 3,
          text: "instagram",
        },
        {
          value: 5,
          text: "linkedin",
        },
      ],
      channels: null,
      currentpage: 1,
      totalPages: 1,
      cuentasexpired: [],
      visibilityLoader: true,
    };
  },
  async created() {
    this.pagination(1, 0);
    this.gettokensExpired();
  },
  methods: {
    async pagination(page, idRedSocial) {
      this.visibilityLoader= true;
      const response = await listChannelsByClient(page, idRedSocial);
      this.currentpage = response.pagination.currentPage;
      this.totalPages = response.pagination.totalPages;
      this.channels = response.channels;
      this.visibilityLoader = false;
      return response.channels;
    },
    async gettokensExpired() {
      const res = await OverviewService.expiredAccounts();
      if (res.length > 0) {
        this.cuentasexpired = res;
      }
    },
    async filterChannels() {
      return await this.pagination(1, this.social);
    },
  },
  computed: {},
};
</script>

<style lang="css" scoped>
#paginado {
  margin-top: 0.8em;
}
#pagination {
  float: right;
}
#pagination {
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
}
.custom-select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40'  d='M2 0L0 1zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px !important;
}
.select_paginado {
  background-color: rgb(0, 165, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 165, 255) !important;
}
.li_paginado {
  height: 35px;
  width: 35px;
  background-color: transparent;
  border-radius: 0.5em;
  color: #5d6b88;
  line-height: 1.6em;
  border: 1px solid #c2d1e6;
  cursor: pointer;
}
.li_paginado {
  list-style: none;
  float: left;
  margin-right: 2px;
  padding: 7px;
  background: #e7eaef;
  font-size: 12px !important;
}
.li_paginado:hover {
  background-color: rgb(0, 165, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 165, 255) !important;
}
.select_socials {
  font-family: Oxygen;
  box-shadow: inset 0px 1px 3px 1px rgba(72, 72, 72, 0.18);
  border: solid 1px #c7cfe2;
  background-color: #ffffff;
  font-size: 12px;
  width: 8rem;
}
</style>
