var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 d-flex flex-wrap grid-items" }, [
    _c("div", { staticClass: "mb-4 w-100" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c(
          "span",
          {
            staticClass:
              "font-weight-bold text-capitalize text-lsm text-secondary mr-2",
          },
          [_vm._v("Top Posts ")]
        ),
        _c("i", {
          directives: [
            {
              name: "b-popover",
              rawName: "v-b-popover.hover",
              value: {
                variant: "primary",
                content: "Posts published by day and hour",
              },
              expression:
                "{\n          variant: 'primary',\n          content: 'Posts published by day and hour',\n        }",
              modifiers: { hover: true },
            },
          ],
          staticClass: "fa fa-info-circle text-skygray",
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "row px-lg-3" },
      _vm._l(_vm.posts, function (post, index) {
        return _c("div", { key: index, staticClass: "top-post-box col px-2" }, [
          _c("div", { staticClass: "top-post-box__content" }, [
            _c(
              "div",
              {
                staticClass:
                  "text-white w-100 top-post-box__content--top d-flex align-items-end justify-content-between px-3 py-2",
                class: ["bg-" + post.rrss],
              },
              [
                _c("div", [
                  _c("i", { class: "fab fa-" + post.rrss + " mr-2 text-lg" }),
                  _c("span", { staticClass: "text-lsm" }, [
                    _vm._v("Interactions"),
                  ]),
                ]),
                _c("span", { staticClass: "font-bold text-3-5xl" }, [
                  _vm._v(
                    _vm._s(
                      _vm.numFormatter(_vm.calcInteractions(post.interactions))
                    )
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "d-flex p-3 align-items-center" }, [
              _c("img", {
                staticClass: "mr-3 rounded-circle",
                staticStyle: { width: "40px", height: "40px", float: "left" },
                attrs: { src: post.images.profile },
              }),
              _c("div", [
                _c("p", { staticClass: "text-xs text-secondary" }, [
                  _vm._v(" " + _vm._s(post.name) + " "),
                ]),
                _c("p", { staticClass: "text-skygray text-xxs" }, [
                  _vm._v(_vm._s(post.date)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "text-xs px-4" }, [
              _c("p", { staticClass: "text-justify text-color-text" }, [
                _vm._v(_vm._s(post.post)),
              ]),
            ]),
            post.rrss !== "twitter"
              ? _c("div", { staticClass: "my-2" }, [
                  _c(
                    "a",
                    { attrs: { target: "_blank", href: post.url_post } },
                    [
                      _c("img", {
                        staticClass: "max-h-70 w-100 object-cover",
                        attrs: { src: post.images.posts, alt: "" },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "py-2 px-4 text-skygray" },
              [
                post.rrss !== "youtube" && post.rrss !== "linkedin"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center text-color-text",
                      },
                      [
                        _vm._m(0, true),
                        _c("span", { staticClass: "text-xs" }, [
                          _vm._v(_vm._s(post.interactions.shares)),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("cp-icon-reaction", {
                  attrs: {
                    name: "Comments",
                    amount: post.interactions.comments,
                    icon: "far fa-comments",
                  },
                }),
                post.rrss === "facebook"
                  ? _c(
                      "div",
                      [
                        _c("cp-icon-reaction", {
                          attrs: { amount: post.interactions.reactions.like },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Love",
                            amount: post.interactions.reactions.love,
                            icon: "far fa-heart",
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Ha-ha",
                            amount: post.interactions.reactions.haha,
                            icon: "far fa-grin-squint",
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Sad",
                            amount: post.interactions.reactions.sad,
                            icon: "far fa-frown",
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Angry",
                            amount: post.interactions.reactions.angry,
                            icon: "far fa-angry",
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Wow",
                            amount: post.interactions.reactions.wow,
                            icon: "far fa-surprise",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                post.rrss === "twitter"
                  ? _c(
                      "div",
                      [
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Favorites",
                            icon: "far fa-heart",
                            amount: post.interactions.reactions.like,
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Retweet",
                            amount: post.interactions.retweet,
                            icon: "fa fa-retweet",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                post.rrss === "instagram"
                  ? _c(
                      "div",
                      [
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Favorites",
                            icon: "far fa-heart",
                            amount: post.interactions.reactions.like,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                post.rrss === "youtube"
                  ? _c(
                      "div",
                      [
                        _c("cp-icon-reaction", {
                          attrs: { amount: post.interactions.reactions.like },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Dislike",
                            amount: post.interactions.reactions.dislike,
                            icon: "far fa-thumbs-down",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                post.rrss === "linkedin"
                  ? _c(
                      "div",
                      [
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Support",
                            amount: post.interactions.shares,
                            icon: "fa fa-hand-holding-heart",
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: { amount: post.interactions.reactions.like },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Celebrate",
                            amount: post.interactions.reactions.haha,
                            icon: "fa fa-glass-cheers",
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Funny",
                            amount: post.interactions.reactions.wow,
                            icon: "far fa-laugh",
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Love",
                            amount: post.interactions.reactions.love,
                            icon: "far fa-heart",
                          },
                        }),
                        _c("cp-icon-reaction", {
                          attrs: {
                            name: "Insightful",
                            amount: post.interactions.reactions.sad,
                            icon: "far fa-lightbulb",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      }),
      0
    ),
    _vm.posts.length == 0
      ? _c("div", { staticClass: "image-container-off" }, [
          _c("img", {
            staticClass: "spacing mb-4 rounded shadow opacity-50",
            attrs: {
              src: require("@/assets/images/off-top-post.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "image-container-off__message" }, [
            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
            _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", {
        staticClass: "text-xs far fa-share-square mr-2 d-inline-block text-xs",
      }),
      _c("span", { staticClass: "text-xs" }, [_vm._v(" Shares ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }