import axios from "axios";
import moment from "moment";
const urlService = require('../util/config').rutaService
const base_url = urlService;
const respUserSession = require('../util/util').getObjUsuarioSession()
const timezoneId = respUserSession.ID_ZONA_HORARIA;
const { validationCodeToken } = require('../util/validateCodeToken')
const _TOKEN = sessionStorage.getItem('token')
const _REFRESH_TOKEN = sessionStorage.getItem('refreshToken')
const _IP_PUBLIC = localStorage.getItem('ip-public')
const _IP_LOCAL = localStorage.getItem('ip-local')
const headers = {
  Authorization: _TOKEN+'|'+_REFRESH_TOKEN+'|'+_IP_PUBLIC+'|'+_IP_LOCAL,
}; // laamar al token desde local storage

export const listChannelsByClient = async (page,idRedSocial) => {
  try {
    const client_id = respUserSession.id_cliente || 1; // TODO : esto será llamado desde el localstorage junto con el token
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/channels/${client_id}/client`,
      headers: headers,
      params: {
        page:page,
        idRedSocial: idRedSocial
      }
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return -1;
  }
};

export const youtubeDetailByChannel = async (channel_id, { init, end }) => {
  try {
    const _init = moment(init).format("YYYY-MM-DD");
    const _end = moment(end).format("YYYY-MM-DD");
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/channels/${channel_id}/youtube?init=${_init}&end=${_end}&timezone_id=${timezoneId}`,
      headers: headers,
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return -1;
  }
};

export const twitterDetailByChannel = async (channel_id, { init, end }) => {
  try {
    const _init = moment(init).format("YYYY-MM-DD");
    const _end = moment(end).format("YYYY-MM-DD");
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/channels/${channel_id}/twitter?init=${_init}&end=${_end}&timezone_id=${timezoneId}`,
      headers: headers,
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return -1;
  }
};

export const instagramDetailByChannel = async (channel_id, { init, end }) => {
  try {
    const _init = moment(init).format("YYYY-MM-DD");
    const _end = moment(end).format("YYYY-MM-DD");
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/channels/${channel_id}/instagram?init=${_init}&end=${_end}&timezone_id=${timezoneId}`,
      headers: headers,
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return -1;
  }
};
export const facebookDetailByChannel = async (channel_id, { init, end }) => {
  try {
    const _init = moment(init).format("YYYY-MM-DD");
    const _end = moment(end).format("YYYY-MM-DD");
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/channels/${channel_id}/facebook?init=${_init}&end=${_end}&timezone_id=${timezoneId}`,
      headers: headers,
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return -1;
  }
};
export const linkedinDetailByChannel = async (channel_id, { init, end }) => {
  try {
    const _init = moment(init).format("YYYY-MM-DD");
    const _end = moment(end).format("YYYY-MM-DD");
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/channels/${channel_id}/linkedin?init=${_init}&end=${_end}&timezone_id=${timezoneId}`,
      headers: headers,
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return -1;
  }
};
