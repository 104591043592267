<template>
  <div>
    <highcharts :options="getOptions()" />
  </div>
</template>
<script>
export default {
  props: {
    titlexAxis: {
      type: String,
      default: '',
    },
    titleyAxis: {
      type: String,
      default: '',
      required: true,
    },
    series: {
      type: [Object, Array ],
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    arrDataUrl: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getOptions() {
      var _categories = this.categories;
      var arrDataUrlTooltip = this.arrDataUrl
      try {
        var _xAxis = {
          title: { text: this.titlexAxis, align: "middle" },
          min: 0,
          startOnTick: true,
          endOnTick: true,
          tickInterval: 1,
          labels: {
            formatter: null,
            autoRotation: false,
            rotation: 0,
            style: { color: "#ababab" },
          },    
        };
        _xAxis.max = _categories.length - 1
        _xAxis.labels.formatter = function () {
          return _categories[this.value];
        };
        var _yAxis = {
          title: { text: this.titleyAxis, align: "middle" },
          labels: {
            formatter: function () {
              return this.value + ":00";
            },
          },
          max: 24,
          tickInterval: 1,
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          alternateGridColor: null,
          plotBands: [
            {
              from: 5,
              to: 12,
              color: "rgba(68, 170, 213, 0.1)",
              label: {
                text: "Morning",
                style: { color: "#606060" },
              },
            },
            {
              from: 12,
              to: 18,
              color: "rgba(0,0,0,0)",
              label: {
                text: "Afternoon",
                style: { color: "#606060" },
              },
            },
            {
              from: 18,
              to: 24,
              color: "rgba(68, 170, 213, 0.1)",
              label: {
                text: "Night",
                style: { color: "#606060" },
              },
            },
          ],
        };
        let options = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "scatter",
            zoomType: "xy",
            marginTop: 10,
            marginBottom: 80,  
          },
          credits: { text: "" },
          exporting: { enabled: false },
          title: { text: "" },
          xAxis: {},
          yAxis: {},
          series: [],
          plotOptions: {},
          tooltip: {
            formatter: function () {
                    if (this.point.clusteredData) {
                        return 'Clustered points: ' ;
                    }
                    return '<b style="color:#ffffff;text-align:center">View Post</b>';
                },               
            backgroundColor: null
          },
          legend: {
            align: "right",
            x: 10,
            verticalAlign: "bottom",
            y: 10,
            floating: true,
            borderWidth: 0,
            shadow: false,
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
        }; 
        options.xAxis = _xAxis
        options.yAxis = _yAxis
        options.series = this.series;
        options.plotOptions = {
          scatter: {
            marker: {
              radius: 5,
              states: {
                hover: { enabled: true, lineColor: "rgb(100,100,100)" },
              },
            },
            states: {
              hover: { marker: { enabled: false } },
            },
          },
          series: {
            point: {
              events: {
                click: function (event) {
                  const dataOption = event.point.options;
                  const indexGraphEquals = dataOption.x + "" + dataOption.y;
                  let respUrl = arrDataUrlTooltip.find(
                    (it) => it.indexGraph == indexGraphEquals
                  );
                  const objPost = respUrl || {};
                  window.open(objPost.url);
                },
                mouseOver(event){
                  const tooltipMainBox = document.querySelector(`g.highcharts-tooltip path:last-of-type`);
                    if (tooltipMainBox) {
                      tooltipMainBox.setAttribute('fill', event.target.color);
                    }
                }
              },
            },
            cursor: "pointer",
          },
        };
        return options;
      } catch (error) {
        console.error("sucedio un error en getOptions: ", error);
        return {};
      }
    },
  },
};
</script>
