var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("posts-by-day", {
        attrs: {
          series: _vm.detail.totals,
          chartCategories: _vm.chartCategories,
          postIndicators: _vm.detail.postIndicators,
        },
      }),
      _c("div", { staticClass: "w-100 h-20" }),
      _c("top-posts", { attrs: { series: _vm.detail.byDay } }),
      _c("post-resume", { attrs: { posts: _vm.detail.posts } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }