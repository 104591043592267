<template>
  <div class="w-8/12 pl-3 pr-5">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >Interactions by day
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Total interactions per day and social network',
          }"
        ></i>
      </div>
    </div>
    <img
      v-if="this.series.length === 0"
      class="off_op et"
      :src="rutaNotFoundAny"
      alt=""
    />
    <highcharts v-if="this.series.length > 0" :options="setDataByRSChart()" />
  </div>
</template>

<script>
import { rutaNotFound } from '../../../../util/config'
export default {
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
    };
  },
  props: {
    series: {
      type: Array,
      required: true,
    },
    chartCategories: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setDataByRSChart(){
      try {
        let options = {
        chart: {
          type: "column",
        },
        title: { text: "" },
        xAxis: {
          categories: [
            "26<br>",
            "27<br>",
            "28<br>",
            "29<br>",
            "30<br>",
            "31<br>",
            "01<br>",
            "02<br>",
            "03<br>",
            "04<br>",
            "05<br>",
            "06<br>",
            "07<br>",
            "08<br>",
            "09<br>",
            "10<br>",
            "11<br>",
            "12<br>",
            "13<br>",
            "14<br>",
            "15<br>",
            "16<br>",
            "17<br>",
            "18<br>",
            "19<br>",
            "20<br>",
            "21<br>",
            "22<br>",
            "23<br>",
            "24<br>",
            "25<br>",
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: { text: "" },
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:1rem";font-weight:bold>{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        legend: {
          layout: "horizontal",
          align: "right",
        },
        series: [],
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        }
        options.series = this.series;
        options.xAxis.categories = this.chartCategories;
        return options
      } catch (error) {
        return {}
      }
    }
  },
  created() {  
  },
};
</script>
<style>
.off_op {
  opacity: 0.5;
}
</style>
