var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "d-flex justify-content-between align-items-center text-color-text",
    },
    [
      _c("div", [
        _c("i", {
          staticClass: "text-xs far  mr-2 d-inline-block",
          class: [_vm.icon],
        }),
        _c("span", { staticClass: "text-xs" }, [
          _vm._v(" " + _vm._s(_vm.name) + " "),
        ]),
      ]),
      _c("span", { staticClass: "text-xs" }, [_vm._v(_vm._s(_vm.amount))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }