import axios from "axios";
// overview back
const base_url = require('../util/config').rutaService
const respUserSession = require('../util/util').getObjUsuarioSession() || {}
const { validationCodeToken } = require('../util/validateCodeToken')
const _TOKEN = sessionStorage.getItem('token')
const _REFRESH_TOKEN = sessionStorage.getItem('refreshToken')
const _IP_PUBLIC = localStorage.getItem('ip-public')
const _IP_LOCAL = localStorage.getItem('ip-local')
const headers = {
  Authorization: _TOKEN+'|'+_REFRESH_TOKEN+'|'+_IP_PUBLIC+'|'+_IP_LOCAL,
};

export const insertRestrictAccount = async (dataConsume) => {
  try {
    dataConsume.idCliente = respUserSession.id_cliente;
    let jsonAxios = {
      method: 'post',
      url: `${base_url}/restrict/save-period-consume`,
      headers: headers,
      data: dataConsume
    }
    const { data } = await axios(jsonAxios);

    return validationCodeToken(data)
  } catch (error) {
    return error.response;
  }
};
export const updateRestrictAccount = async (dataConsume, idModule) => {
  try {
    
    let jsonAxios = {
      method: 'put',
      url: `${base_url}/restrict/update-period-consume`,
      headers: headers,
      data: {
        data: dataConsume,
        clientId: respUserSession.id_cliente,
        idModule
      }
    }
    const { data } = await axios(jsonAxios);

    return validationCodeToken(data)
  } catch (error) {
    return error.response;
  }
};