<template>
  <div
    class="d-flex justify-content-between align-items-center text-color-text"
  >
    <div>
      <i 
        class="text-xs far  mr-2 d-inline-block"
        :class="[ icon ]"
      ></i>
      <span class="text-xs"> {{ name }} </span>
    </div>
    <span class="text-xs">{{ amount}}</span>
  </div>
</template>

<script>
export default {
  name: "CpIconReaction",
  props: {
    name: {
      type: String,
      default:() => {
        return "Like";
      },
    },
    amount:{
      type: Number,
      default: 0
    }, 
    icon: {
      type: String,
      default: "fa-thumbs-up"
    }
  },
};
</script>

<style></style>
