<template>
  <div class="w-100">
    <interactions-chart :series="detail.graph" :total="detail.total" :categories="categories" :average="detail.average" :boolViewData="detail.boolViewData"/>
  </div>
</template>

<script>
import InteractionsChart from './InteractionsChart.vue';
export default {
  components: {InteractionsChart },
  props : {
    detail : {
      type : Object,
      required : true
    },
    chartCategories : {
      type : Array,
    },
    categories:{
      type: Array
    }
  }
};
</script>

<style>
</style>