<template>
  <div class="w-100 bg-white mb-4 resumeHorizontal-box">
    <div
      class="w-100 border-bottom d-flex"
      style="height: 80px"
      v-for="(account, index) in dataProfile.accounts"
      :key="index"
    >
      <div class="w-1/6 h-100 p-1 d-flex align-items-center">
        <div
          :class="`w-100 h-100 d-flex py-3 px-3 mr-1`"
          style="background-color: #f3f4f7"
        >
          <div class="mr-2" :class="`text-${account.rrss}`">
            <i :class="`fab fa-${account.rrss}`"></i>
          </div>
          <div class="d-flex flex-column">
            <span
              class="text-lsm text-capitalize"
              :class="`text-${account.rrss}`"
              >{{ fixNameSocialNetwork(account.rrss) }}</span
            >
            <span class="text-xs accountName text-secondary">{{
              account.name
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="py-2 w-1/6 h-100 d-flex flex-column align-items-center justify-content-around"
      >
        <span class="text-xs text-secondary">Audience</span>
        <span class="font-weight-bold text-color-text text-lsm">{{
          numFormatter(account.audience.count)
        }}</span>
        <span class="float-right font-light text-xs text-skygray"
          >{{ account.audience.variation }}%
          <i
            :class="`fa fa-sort-${
              account.audience.variation > 0
                ? 'up text-success'
                : 'down text-danger'
            }`"
          ></i
        ></span>
      </div>
      <div
        class="py-2 w-1/6 h-100 d-flex flex-column align-items-center justify-content-around"
      >
        <span class="text-xs text-secondary">Posts</span>
        <span class="font-weight-bold text-color-text text-lsm">{{
          numFormatter(account.posts.count)
        }}</span>
        <span class="float-right font-light text-xs text-skygray"
          >{{ account.posts.variation }}%
          <i
            :class="`fa fa-sort-${
              account.posts.variation > 0
                ? 'up text-success'
                : 'down text-danger'
            }`"
          ></i
        ></span>
      </div>
      <div
        class="py-2 w-1/6 h-100 d-flex flex-column align-items-center justify-content-around"
      >
        <span
          class="text-xs text-secondary"
          v-if="account.rrss != 'linkedin'"
          >{{ InteractionTypeTitle(account.rrss) }}</span
        >
        <span
          class="font-weight-bold text-color-text text-lsm"
          v-if="account.rrss != 'linkedin'"
          >{{ account[InteractionType(account.rrss)].count }}</span
        >
        <span
          class="text-xs text-secondary"
          v-if="account.rrss === 'linkedin'"
          >{{ InteractionTypeTitle(account.rrss) }}</span
        >
        <span
          class="font-weight-bold text-color-text text-lsm"
          v-if="account.rrss === 'linkedin'"
          >{{ account[InteractionType(account.rrss)].count }}</span
        >
        <span
          class="float-right font-weight-bold text-xs text-skygray"
          v-if="account.rrss === 'linkedin'"
        ></span>
        <span class="float-right font-light text-xs text-skygray">
          {{ account[InteractionType(account.rrss)].variation }}%
          <i
            :class="`fa fa-sort-${
              account[InteractionType(account.rrss)].variation > 0
                ? 'up text-success'
                : 'down text-danger'
            }`"
          ></i
        ></span>
      </div>
      <div
        class="py-2 w-1/6 h-100 d-flex flex-column align-items-center justify-content-around"
      >
        <span class="text-xs text-secondary">Interactions</span>
        <span class="font-weight-bold text-color-text text-lsm">{{
          numFormatter(account.interactions.count)
        }}</span>
        <span class="float-right font-light text-xs text-skygray"
          >{{ account.interactions.variation }}%
          <i
            :class="`fa fa-sort-${
              account.interactions.variation > 0
                ? 'up text-success'
                : 'down text-danger'
            }`"
          ></i
        ></span>
      </div>
      <div
        class="w-1/6 h-100 d-flex align-items-center justify-content-end pr-4"
      >
        <b-button
          :to="{
            name: 'overview-channels-detail',
            query: { rrss: account.rrss, channel: account.id },
          }"
          :variant="account.rrss"
          class="btn--rrss d-flex align-items-center justify-content-center"
        >
          <span class="text-xs text-white"> see more </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, Helpers } from "@isc/styleguide";
export default {
  name: "ResumeHOrizontal",
  props: {
    accounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      accounts_new: [],
      fixNameSocialNetwork: (name) => Helpers.fixNameSocialNetwork(name),
    };
  },
  methods: {
    InteractionType(social) {
      switch (social) {
        case "facebook":
          return "engaged_users";
        case "youtube":
          return "views";
        case "twitter":
          return "mentions";
        case "instagram":
          return "likes";
        case "linkedin":
          return "total_comentarios";
      }
    },
    InteractionTypeTitle(social) {
      switch (social) {
        case "facebook":
          return "Engaged users";
        case "youtube":
          return "Views";
        case "twitter":
          return "Mentions";
        case "instagram":
          return "Likes";
        case "linkedin":
          return "Comments";
      }
    },
    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000) {
        num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num < 900) {
        num; // if value < 1000, nothing to do
      }
      return num;
    },
  },
  computed: {
    ...mapGetters({ dataProfile: "overview/account/getProfileAccount" }),
  },
  mounted() {
    this.accounts_new = this.accounts;
  },
};
</script>

<style scoped>
.btn--rrss {
  width: 108px;
  height: 35px;
  border-radius: 3px;
  box-shadow: 0px 2px 3.9px 0.1px rgba(0, 0, 0, 0.15);
  padding: 0;
}
.accountName {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  width: 100%;
}
.w-1\/6 {
  width: 16.666667%;
}
.text-Facebook {
  color: #1778f2;
}
.text-Instagram {
  color: #ff69a1;
}
.text-Youtube {
  color: #ff6564;
}
.text-Twitter {
  color: #00a5ff;
}
</style>
