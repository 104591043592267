import { render, staticRenderFns } from "./InteractionsFb.vue?vue&type=template&id=7f0ba894&"
import script from "./InteractionsFb.vue?vue&type=script&lang=js&"
export * from "./InteractionsFb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-overview/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7f0ba894')) {
      api.createRecord('7f0ba894', component.options)
    } else {
      api.reload('7f0ba894', component.options)
    }
    module.hot.accept("./InteractionsFb.vue?vue&type=template&id=7f0ba894&", function () {
      api.rerender('7f0ba894', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/channel-detail/socials/facebook/tabs/Interactions/InteractionsFb.vue"
export default component.exports