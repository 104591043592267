var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("net-likes-chart", {
        attrs: {
          "v-if": _vm.detail.netLikes,
          series: _vm.detail.netLikes.graph,
          dataList: _vm.detail.netLikes.dataList,
          boolViewData: _vm.detail.boolViewDataNetLikes,
          chartCategories: _vm.chartCategories,
        },
      }),
      _c("div", { staticClass: "w-100 " }),
      _c("fans-trend-chart", {
        attrs: {
          "v-if": _vm.detail.fansTrend,
          series: _vm.detail.fansTrend.graph,
          dataList: _vm.detail.fansTrend.dataList,
          chartCategories: _vm.chartCategories,
          boolViewData: _vm.detail.boolViewDataFansTrend,
        },
      }),
      _c("div", { staticClass: "w-100 h-20" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }