<template>
  <div class="w-4/12 pl-lg-3 pl-xl-4 pr-2">
    <div class="w-100 rounded-6 bg-facebook p-4">
      <div class="d-flex justify-content-between mb-1">
        <div>
          <span class="text-white text-lsm mr-1">Engaged Users Rate</span>
          <i
            class="fa fa-info-circle text-white text-xs"
            v-b-popover.hover="{
              variant: 'primary',
              content: '(Total Engaged Users / Total Reach) * 100',
            }"
          ></i>
        </div>

        <span class="text-white font-weight-bold text-lg">{{levels.total}}%</span>
      </div>
      <div class="w-100 position-relative">
        <div class="position-relative z-0 h-2" style="background-color:#fff;opacity: 0.2;"></div>
        <div
          class="position-absolute h-2 bg-white top-0"
          :style="`width: ${levels.total}%;z-index : 2; max-width : 100%;`"
        ></div>
      </div>
    </div>
    <div class="mt-3 d-flex">
      <div class="w-1/2">
        <p class=" text-center mb-4">
          <span class="text-xs text-color-text font-weight-bold">
            Average Engaged Users <br />
            Rate per Post 
          </span>
          <i
          class="fa fa-info-circle text-skygray text-xs"
          v-b-popover.hover="{
            variant: 'primary',
            content: '((Total Engaged Users / Total Reach) / Total Posts) * 100',
          }"
        ></i>
        </p>
        <vc-donut
          :sections="[{ value: levels.rate > 100 ? 100 : levels.rate, color: '#1877f2' }]"
          :size="donutSizeNumber"
          :thickness="10"
          :auto-adjust-text-size="true"
        >
          <span class="text-lsm text-color-text font-weight-bold">{{levels.rate}}%</span>
        </vc-donut>
      </div>
      <div class="w-1/2">
        <p class="text-center mb-4">
          <span class="text-xs text-color-text font-weight-bold">
            Posts Total Reach
        
          </span>
          <i
          class="fa fa-info-circle text-skygray text-xs"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Summing of All Reach of The Posts',
          }"
        ></i>
        </p>
        <br/>
        <vc-donut
          :sections="[{ value: levels.totalReach > 100 ? 100 : levels.totalReach, color: '#1877f2' }]"
          :size="donutSizeNumber"
          :thickness="10"
          :auto-adjust-text-size="true"
        >
          <span class="text-lsm text-color-text font-weight-bold">{{numFormatter(levels.totalReach)}}</span>
        </vc-donut>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      donutSizeNumber: 115
    }
  },
  props : {
    levels : {
      type : Object,
      required : true,
    }
  },
  methods:{
    async setear(){
         if(this.levels.rate==null){
            this.levels.rate=0
         }
         if(this.levels.total==null){
            this.levels.total=0
         }
         if(this.levels.usersPerPost==null){
            this.levels.usersPerPost=0
         }
    },
    numFormatter(num) {
        if(num > 999 && num < 1000000){
           num =(num/1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
        }else if(num > 1000000){
            num =(num/1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million 
        }else if(num < 900){
             num; // if value < 1000, nothing to do
        }
        return num;
      },
  },
  created(){
   this.setear()
  }
};
</script>
<style>
</style>