<template>
  <div class="w-100">
    <interactions-total-chart :series="detail.total.graph" :dataList="detail.total.dataList" :categories="chartCategories" :chartCategories="chartCategories"/>
    <div class="w-100 h-20"></div>
    <interactions-types-chart :series="detail.types.graph" :dataList="detail.types.dataList" :chartCategories="chartCategories"/>
    <div class="w-100 h-20"></div>
    <reactions-chart :series="detail.reactions.graph" :dataList="detail.reactions.dataList" :chartCategories="chartCategories"/>
  </div>
</template>

<script>
import InteractionsTotalChart from "./InteractionsTotalChart.vue";
import InteractionsTypesChart from "./InteractionsTypesChart.vue";
import ReactionsChart from "./ReactionsChart.vue";
export default {
  components: {
    InteractionsTotalChart,
    InteractionsTypesChart,
    ReactionsChart,
  },
  props : {
    chartCategories : {
      type : [ Object, Array ],
      required : true
    },
    detail : {
      type : Object,
      required : true,
    },
    chartCategories:{
      type:[ Object, Array ],
    }
  
  }
};
</script>

<style>
</style>