var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticClass: "py-1 icon-reaction text-color-text" }, [
    _c("i", { staticClass: " mr-2 w-4 d-inline-block", class: [_vm.icon] }),
    _vm._v(" " + _vm._s(_vm.name) + " "),
    _c("span", { staticClass: "float-right" }, [_vm._v(_vm._s(_vm.amount))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }