<template>
  <div class="w-8/12 pl-3 pr-5">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >New audience
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content:
              'Daily amount of new audience per social network. Formula: Current Followers + New Followers - Unfollowed Accounts',
          }"
        ></i>
      </div>
    </div>
    <graph-spline
      v-if="series.length > 0"
      :titlexAxis="titlexAxis"
      :arrTitlesyAxis="arrTitlesyAxis"
      :series="series"
      :categories="categories"
    ></graph-spline>
    <div v-else class="image-container-off">
      <img
        class="opacity-50"
        src="@/assets/images/off-new-audience.png"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
import graphSpline from "../../../graph-custom/graphSpline.vue";

export default {
  components: {
    graphSpline,
  },
  props: {
    series: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    categories: {
      type: [Array, String],
      required: true,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      arrTitlesyAxis: ["New audience"],
      titlexAxis: "Days",
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {},
};
</script>

<style></style>
