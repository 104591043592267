var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _c("div", { staticClass: "py-4 d-flex" }, [
      _c(
        "div",
        { staticClass: "w-100 d-flex align-items-center" },
        [
          _c("img", {
            staticClass: "profile_img d-inline-block",
            attrs: { src: _vm.logo, alt: "foto de perfil" },
            on: { error: _vm.errorUrlImage },
          }),
          _c("span", { staticClass: "font-weight-bold mx-2 mr-3" }, [
            _vm._v(_vm._s(_vm.profileName)),
          ]),
          _vm._l(_vm.rrss, function (social, index) {
            return _c("i", {
              key: index,
              class: "fab fa-" + social + " mr-2 text-" + social,
            })
          }),
          _c(
            "div",
            [
              _c("i", {
                staticClass: "fa fa-info-circle icon-msg-info",
                attrs: { id: "generalAudienceDetail", "aria-hidden": "true" },
              }),
              _c("CpTooltipWhiteCustom", {
                attrs: {
                  target: "generalAudienceDetail",
                  title: "generalAudienceDetail",
                  message: "Update: Every 24 hours",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("i", {
                staticClass: "fa fa-info-circle icon-msg-info",
                attrs: { id: "variationMsg", "aria-hidden": "true" },
              }),
              _c("CpTooltipDoubleCustom", {
                attrs: {
                  target: "variationMsg",
                  title: "Variation (%):",
                  subTitle1: "- Audience: Start date vs. end date.",
                  heightSubTitle1: "20px",
                  heightSubTitle2: "50px",
                  subTitle2:
                    "- Posts, Engaged Users and Interactions: Period selected vs. previous period.",
                  backgroundColorSubtitle1: "white",
                  colorTextSubtitle1: "#6b738b",
                  textAlignSubtitle1: "left",
                  widthSubtitle1: "204px",
                  textAlignSubtitle2: "left",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }