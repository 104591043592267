var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "b-col",
        { staticClass: "mt-2 pl-3 pr-5", attrs: { cols: "8" } },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("span", { staticClass: "title-style" }, [_vm._v("Likes ")]),
              _c("i", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover",
                    value: {
                      variant: "primary",
                      content:
                        "Total number of people who indicated they like your page.",
                    },
                    expression:
                      "{\n            variant: 'primary',\n            content:\n              'Total number of people who indicated they like your page.',\n          }",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "fa fa-info-circle icoPop",
              }),
            ]),
          ]),
          !_vm.boolViewData
            ? _c("div", { staticClass: "image-container-off" }, [
                _c("img", {
                  staticClass: "off_op",
                  staticStyle: { opacity: "0.5", width: "100%" },
                  attrs: {
                    src: require("@/assets/images/image-off-line-graph.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "image-container-off__message" }, [
                  _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                  _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                ]),
              ])
            : _c("highcharts", { attrs: { options: _vm.getOptionsDefault } }),
          _c("b-form-select", {
            staticClass: "select_option_graph",
            class: ["sel", _vm.boolViewData == false ? "no-view" : ""],
            attrs: { options: _vm.options },
            on: { change: _vm.getOptions },
            model: {
              value: _vm.selectGraph,
              callback: function ($$v) {
                _vm.selectGraph = $$v
              },
              expression: "selectGraph",
            },
          }),
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass: "pl-5 detail-list text-semidark mt-4",
          attrs: { cols: "4" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "detail-list__item w-100 mt-4 d-flex justify-content-between align-items-baseline",
            },
            [
              _c("span", [_vm._v("Likes")]),
              _c("span", [
                _vm._v(_vm._s(_vm.numFormatter(_vm.dataList.likesFollowers))),
              ]),
            ]
          ),
          _c("div", { staticClass: "box-social-networks" }, [
            _c(
              "div",
              {
                staticClass:
                  "bg-facebook box h-24 rounded d-flex flex-column justify-content-center align-items-center",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "white-color-text font-weight-bold text-center",
                  },
                  [_vm._v("Organic")]
                ),
                _c(
                  "p",
                  { staticClass: "bx_Content_color text-white text-center" },
                  [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.numFormatter(_vm.dataList.organic)) +
                          " "
                      ),
                    ]),
                    _c("i", { staticClass: "fa fa-sort-up text-white" }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-warning box h-24 rounded d-flex flex-column justify-content-center align-items-center ",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "white-color-text font-weight-bold text-center",
                  },
                  [_vm._v("Paid")]
                ),
                _c(
                  "p",
                  { staticClass: "bx_Content_color text-white text-center" },
                  [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        " " + _vm._s(_vm.numFormatter(_vm.dataList.paid)) + " "
                      ),
                    ]),
                    _c("i", { staticClass: "fa fa-sort-up text-white" }),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }