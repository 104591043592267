<template>
  <div class="w-100 mt-5">
    <div
      class="d-flex align-items-center justify-content-between mb-4"
      v-for="(item, index) in reactions"
      :key="index"
    >
    
      <div class="w-1/6 d-flex align-items-center justify-content-start">
        <span class="leading-none text-capitalize">
          <p class="m-0 text-xs">{{ item.name }}</p>
        </span>
      </div>
      <div
        class="w-3/6 position-relative d-flex justify-content-center align-items-center"
      >
        <div class="position-relative z-0 bg-mainbg h-2 w-100"></div>
        <div
          class="position-absolute h-2 bg-primary top-0 left-0"
          :style="`width: ${item.pctValue}%; z-index :2`"
        ></div>
      </div>
      <span class="d-inline-block w-1/6 text-sm w-">{{ item.level }}</span>
    </div>
  </div>
</template>

<script>
import { Helpers } from "@isc/styleguide";
export default {
  props: {
    content: {
      type: [Object, Array],
      required: true,
    },
    chartCategories: {
      type: Object,
    },
  },
  data() {
    return {
      
      reactions: []
    };
  },
  methods: {
    convertContent(){
      const levels = this.content.map(item => item.level);
      const maxValue = Helpers.getMaxValueObject(levels);
      this.reactions = this.content.map(item => {
        return {
          ...item,
          pctValue: Helpers.convertToPercentage(item.level, maxValue)
        }
      })
    }
  },
  mounted(){
    this.convertContent()
  }
};
</script>

<style></style>
