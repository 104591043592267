var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "b-col",
        { staticClass: "mt-2 pl-3 pr-5", attrs: { cols: "8" } },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c(
              "span",
              { staticClass: "font-weight-bold" },
              [
                _vm._v("Reactions, comments, shares "),
                _c("i", {
                  staticClass: "fa fa-info-circle text-skygray",
                  attrs: { id: "reactCommShares" },
                }),
                _c("CpTooltipWhiteCustom", {
                  attrs: {
                    target: "reactCommShares",
                    title: "reactCommShares",
                    message:
                      "Reactions, comments and shares updated every 24 hours.",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.view
            ? _c("highcharts", { attrs: { options: _vm.options } })
            : _c("div", { staticClass: "image-container-off" }, [
                _c("img", {
                  staticStyle: { opacity: "0.5", width: "100%" },
                  attrs: {
                    src: require("@/assets/images/image-off-reactions.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "image-container-off__message" }, [
                  _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                  _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                ]),
              ]),
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "pl-5 pr-2", attrs: { cols: "4" } },
        [
          _c("interactions-types-bar-graph", {
            attrs: { content: _vm.numFormatter(_vm.dataList.bars) },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }