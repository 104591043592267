var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mt-2", attrs: { "no-gutters": "" } },
    [
      _c(
        "b-col",
        { attrs: { cols: "8 pl-3 pr-5" } },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Accumulated Subscribers "),
              _c("i", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover",
                    value: {
                      variant: "primary",
                      content: "Accumulated Subscribers youtube",
                    },
                    expression:
                      "{\n            variant: 'primary',\n            content: 'Accumulated Subscribers youtube',\n          }",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "fa fa-info-circle text-skygray",
              }),
            ]),
          ]),
          !_vm.boolViewData
            ? _c("div", { staticClass: "image-container-off" }, [
                _c("img", {
                  staticClass: "off_op",
                  attrs: {
                    src: require("@/assets/images/images-off-yt-suscribers.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "image-container-off__message" }, [
                  _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                  _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                ]),
              ])
            : _c("highcharts", { attrs: { options: _vm.getOptions() } }),
        ],
        1
      ),
      _c("b-col", { staticClass: "pl-5 pr-2", attrs: { cols: "4" } }, [
        _c("div", { staticClass: "bg-youtube rounded p-4 text-white" }, [
          _c("p", [_vm._v("Subscribers")]),
          _c("p", { staticClass: "text-4xl font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.numFormatter(_vm.totals.subscribers)) + " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "p-4" }, [
          _c("p", [_vm._v("New Subscribers")]),
          _c("p", { staticClass: "text-4xl font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.numFormatter(_vm.totals.newSubscribers)) + " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "p-4" }, [
          _c("p", [_vm._v("Average New Subscribers")]),
          _c("p", { staticClass: "text-4xl font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.numFormatter(_vm.totals.AverageNewSubscribers)) +
                " "
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }