var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 bg-mainbg" },
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _c("bread-crumb", [
        _c(
          "div",
          { staticClass: "d-flex justify-content-end align-items-center" },
          [
            _c(
              "span",
              { staticStyle: { width: "4rem", "font-size": "14px" } },
              [_vm._v(" Filter by:")]
            ),
            _c("b-form-select", {
              staticClass: "select_socials",
              attrs: { options: _vm.socials },
              on: { change: _vm.filterChannels },
              model: {
                value: _vm.social,
                callback: function ($$v) {
                  _vm.social = $$v
                },
                expression: "social",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.channels
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "b-container",
                { staticClass: "detail-content-box", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap justify-content-between" },
                    _vm._l(_vm.channels, function (channel, index) {
                      return _c("channel-box", {
                        key: index,
                        attrs: { accountData: channel },
                      })
                    }),
                    1
                  ),
                  _c("div", { staticClass: "row pd-lr-10" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { attrs: { id: "paginado" } }, [
                        _c(
                          "ul",
                          { attrs: { id: "pagination" } },
                          _vm._l(_vm.totalPages, function (index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                class: [
                                  "li_paginado",
                                  index == _vm.currentpage
                                    ? "select_paginado"
                                    : "",
                                ],
                                attrs: { id: "1" },
                                on: {
                                  click: function ($event) {
                                    return _vm.pagination(index, _vm.social)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(index) + " ")]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "clear" }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("ModalTokensExpired", { attrs: { cuentas: _vm.cuentasexpired } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }