<template>
  <div class="w-100 pt-4 d-flex flex-wrap" v-if="true">
    <engaged-users-by-day-chart :series="engageDetails.usersEngageByDay" :categories="engageDetails.chartCategories" :boolViewData="engageDetails.boolEngagedUsersByDay"/>
    <engaged-levels :levels="engageDetails.levels" />
    <div class="w-100 h-16"></div>
    <dayli-engagedment :series="engageDetails.dayliEngage"  :categories="engageDetails.chartCategories"/>
    <engage-type :count="engageDetails.typeEngage"/>
  </div>
  
</template>

<script>
import EngagedUsersByDayChart from "./EngagedUsersByDayChart.vue";
import EngagedLevels from "./EngagedLevels.vue";
import DayliEngagedment from "./DayliEngagedment.vue";
import EngageType from "./EngageType.vue";
import { mapGetters } from "@isc/styleguide";

export default {
  components: {
    EngagedUsersByDayChart,
    EngagedLevels,
    DayliEngagedment,
    EngageType,
  },
  name: "engagedUsers",
  data() {
    return {
    };
  },
  props: {
    rangeDate: {
      type: Array,
      required: true,
    },
  },
  created() {
  },
  methods: {
    async loadEngageDetails() {
      const loader = this.$loading.show();
      loader.hide()
    },
    getEngagedUser(){
      return this.engageDetails
    }
  },
  computed: {
    ...mapGetters({engageDetails: 'overview/account/getEngagedDetails'}),
  }
};
</script>
