<template>
  <div class="w-100">
    <interactions-chart :series="detail.graph" :total="detail.total" :average="detail.average" :categories="categories"/>
  </div>
</template>

<script>
import InteractionsChart from "./InteractionsChart.vue";
export default {
  components: { InteractionsChart },
  props: {
    detail: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
