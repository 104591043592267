<template>
  <b-row no-gutters>
    <b-col cols="8" class="mt-2 pl-3 pr-5">
      <div class="mb-4">
        <div class="d-flex align-items-center">
          <span class="title-style">Likes </span>
          <i
            class="fa fa-info-circle icoPop"
            v-b-popover.hover="{
              variant: 'primary',
              content:
                'Total number of people who indicated they like your page.',
            }"
          ></i>
        </div>
      </div>
      <div
        v-if="!boolViewData"
        class="image-container-off"
      >
        <img
          class="off_op"
          src="@/assets/images/image-off-line-graph.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
      <highcharts v-else :options="getOptionsDefault" />
      <b-form-select
        class="select_option_graph"
        :class="['sel', boolViewData == false ? 'no-view' : '']"
        v-model="selectGraph"
        :options="options"
        @change="getOptions"
      />
    </b-col>
    <b-col cols="4" class="pl-5 detail-list text-semidark mt-4">
      <div
        class="detail-list__item w-100 mt-4 d-flex justify-content-between align-items-baseline"
      >
        <span>Likes</span>
        <span>{{ numFormatter(dataList.likesFollowers) }}</span>
      </div>
      <!-- <div
        class="detail-list__item bg mt-2 w-100 d-flex justify-content-between align-items-baseline"
      >
        <span>Net Likes</span>
        <span>{{ numFormatter(dataList.netLikeFollowers) }}</span>
      </div> -->
      <div
        class="box-social-networks"
      >
        <div
          class="bg-facebook box h-24 rounded d-flex flex-column justify-content-center align-items-center"
        >
          <p class="white-color-text font-weight-bold text-center">Organic</p>
          <p class="bx_Content_color text-white text-center">
            <span class="font-weight-bold">
              {{ numFormatter(dataList.organic) }}
            </span>
            <i class="fa fa-sort-up text-white"></i>
          </p>
        </div>
        <div
          class="bg-warning box h-24 rounded d-flex flex-column justify-content-center align-items-center "
        >
          <p class="white-color-text font-weight-bold text-center">Paid</p>
          <p class="bx_Content_color text-white text-center">
            <span class="font-weight-bold">
              {{ numFormatter(dataList.paid) }}
            </span>
            <i class="fa fa-sort-up text-white"></i>
          </p>
        </div>
      </div>
      <!-- <div
        v-if="false"
        class="detail-list__item sml w-100 mt-2 d-flex justify-content-between align-items-baseline"
      >
        <span>Organic</span>
        <span>100</span>
      </div> -->
    </b-col>
  </b-row>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../../../util/config";
export default {
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      selectGraph: 1,
      options: [
        {
          value: 1,
          text: "All",
        },
        {
          value: 2,
          text: "Likes", // before it was "Net Likes"
        },
        {
          value: 3,
          text: "Organic",
        },
        {
          value: 4,
          text: "Paid",
        },
      ],
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  props: {
    series: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Object,
      required: true,
    },
    chartCategories: {
      type: Array,
      required: true,
    },
    boolViewData: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    // Net likes is equal value with likes 
    getOptions() {
      try {
        let options = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "spline",
            marginTop: 10,
            height: 360,
            marginBottom: 70,
          },
          title: { text: "", enabled: false },
          credits: { text: "" },
          xAxis: {
            title: { text: "Days", align: "middle" },
            allowDecimals: false,
            lineColor: "#D6DBDF",
            tickLength: 0,
            type: "datetime",
            categories: [],
            labels: {
              formatter: function () {
                return this.value;
              },
              rotation: 0,
              autoRotation: false,
              align: "center",
              style: { color: "#999999" },
            },
            dateTimeLabelFormats: {
              day: "%e of %b",
            },
          },
          yAxis: [
            {
              title: { text: "Likes", align: "middle" }, // "Net Likes" 
              min: null,
              labels: { style: { color: "#B3B4B8" } },
              allowDecimals: false,
            },
            {
              gridLineColor: "#D5DAE0",
              title: { enabled: false, text: "", align: "middle" },
              allowDecimals: false,
              min: null,
              labels: { style: { color: "#B3B4B8" } },
              opposite: true,
            },
          ],
          plotOptions: {
            line: {
              marker: {
                enabled: true, // muestra el punto
              },
              connectNulls: true,
            },
            series: {
              pointWidth: 4, //width of the column bars irrespective of the chart size
              allowPointSelect: true,
            },
            spline: { marker: { lineWidth: 1, radius: 3, symbol: "circle" } },
            areaspline: {
              marker: { lineWidth: 1, radius: 3, symbol: "circle" },
            },
          },
          tooltip: {
            formatter: function () {
              let text =
                "<table class='table_tooltip' border=0 cellspacing=0 cellpadding='5px' style=''>";
              text +=
                "<tr><td class='tp-table' colspan='2' style=''>" +
                this.x +
                "</td></tr>";
              for (let i = 0; i < this.points.length; i++) {
                text += "<tr><td style='padding:4px;padding-left:8px;'>";
                text +=
                  "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                  this.points[i].series.color +
                  " !important'></i> " +
                  this.points[i].series.name;
                text +=
                  "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
                text += Helpers.numberWithCommas(this.points[i].y);
                text += "</td></tr>";
              }
              text += "</table>";
              return text;
            },
            shared: true,
            padding: 0,
            backgroundColor: "#ffffff",
            useHTML: true,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            crosshairs: true,
          },
          exporting: { enabled: false },
          labels: {
            items: [
              {
                style: { left: "40px", top: "8px", color: "#ACADAF" },
              },
            ],
          },
          legend: {
            align: "right",
            verticalAlign: "bottom",
            y: 10,
            floating: true,
            borderWidth: 0,
            shadow: false,
            symbolPadding: 10,
            symbolWidth: 5,
          },
          series: [],
        };
        switch (this.selectGraph) {
          case 2:
            options.series = [this.series[0]];
            break;
          case 3:
            options.series = [this.series[1]];
            break;
          case 4:
            options.series = [this.series[2]];
            break;
          default:
            options.series = this.series;
            break;
        }

        options.xAxis.categories = this.chartCategories;
        this.graphFull = options;
        return options;
      } catch (error) {}
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
    getOptionsDefault() {
      return this.getOptions();
    },
  },
  created() {},
};
</script>

<style scoped>
.detail-list > p {
  margin-bottom: 1rem;
}
.detail-list > p > span {
  float: right;
}
.off_op {
  opacity: 0.5;
}
.no-view {
  display: none;
}
.custom-select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40'  d='M2 0L0 1zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px !important;
}
.sel {
  top: 0px;
  right: 50px;
  position: absolute;
}
.detail-list__item span {
  font-size: 0.9375em;
  color: #2c3852;
}
.detail-list__item span + span {
  font-size: 1.125em;
}
.detail-list__item.sml span,
.detail-list__item.sml span + span {
  font-size: 0.75em;
  font-weight: normal;
  color: #5d6b88;
}
.detail-list__item.rd span,
.detail-list__item.rd span + span {
  color: #ff7e7e;
}
.detail-list__item.bg span + span {
  font-size: 2em;
}
.detail-list__item span,
.detail-list__item span + span {
  font-weight: bold;
}
.select_option_graph {
  font-family: Oxygen;
  box-shadow: inset 0px 1px 3px 1px rgba(72, 72, 72, 0.18);
  border: solid 1px #c7cfe2;
  background-color: #ffffff;
  font-size: 12px;
  width: 8rem;
}

/* BOX SOCIAL NETWORKS */
.box-social-networks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.7rem;
  margin-top: 3rem;
}

.box-social-networks .box {
  flex-grow: 1;
  /* height: 100%; */
  width: 144px;
}
</style>
