var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-4/12 pl-lg-3 pl-xl-4 pr-2" }, [
    _c("div", { staticClass: "w-100 rounded-6 bg-facebook p-4" }, [
      _c("div", { staticClass: "d-flex justify-content-between mb-1" }, [
        _c("div", [
          _c("span", { staticClass: "text-white text-lsm mr-1" }, [
            _vm._v("Engaged Users Rate"),
          ]),
          _c("i", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover",
                value: {
                  variant: "primary",
                  content: "(Total Engaged Users / Total Reach) * 100",
                },
                expression:
                  "{\n            variant: 'primary',\n            content: '(Total Engaged Users / Total Reach) * 100',\n          }",
                modifiers: { hover: true },
              },
            ],
            staticClass: "fa fa-info-circle text-white text-xs",
          }),
        ]),
        _c("span", { staticClass: "text-white font-weight-bold text-lg" }, [
          _vm._v(_vm._s(_vm.levels.total) + "%"),
        ]),
      ]),
      _c("div", { staticClass: "w-100 position-relative" }, [
        _c("div", {
          staticClass: "position-relative z-0 h-2",
          staticStyle: { "background-color": "#fff", opacity: "0.2" },
        }),
        _c("div", {
          staticClass: "position-absolute h-2 bg-white top-0",
          style:
            "width: " + _vm.levels.total + "%;z-index : 2; max-width : 100%;",
        }),
      ]),
    ]),
    _c("div", { staticClass: "mt-3 d-flex" }, [
      _c(
        "div",
        { staticClass: "w-1/2" },
        [
          _c("p", { staticClass: " text-center mb-4" }, [
            _vm._m(0),
            _c("i", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover",
                  value: {
                    variant: "primary",
                    content:
                      "((Total Engaged Users / Total Reach) / Total Posts) * 100",
                  },
                  expression:
                    "{\n          variant: 'primary',\n          content: '((Total Engaged Users / Total Reach) / Total Posts) * 100',\n        }",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "fa fa-info-circle text-skygray text-xs",
            }),
          ]),
          _c(
            "vc-donut",
            {
              attrs: {
                sections: [
                  {
                    value: _vm.levels.rate > 100 ? 100 : _vm.levels.rate,
                    color: "#1877f2",
                  },
                ],
                size: _vm.donutSizeNumber,
                thickness: 10,
                "auto-adjust-text-size": true,
              },
            },
            [
              _c(
                "span",
                { staticClass: "text-lsm text-color-text font-weight-bold" },
                [_vm._v(_vm._s(_vm.levels.rate) + "%")]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-1/2" },
        [
          _c("p", { staticClass: "text-center mb-4" }, [
            _c(
              "span",
              { staticClass: "text-xs text-color-text font-weight-bold" },
              [_vm._v(" Posts Total Reach ")]
            ),
            _c("i", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover",
                  value: {
                    variant: "primary",
                    content: "Summing of All Reach of The Posts",
                  },
                  expression:
                    "{\n          variant: 'primary',\n          content: 'Summing of All Reach of The Posts',\n        }",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "fa fa-info-circle text-skygray text-xs",
            }),
          ]),
          _c("br"),
          _c(
            "vc-donut",
            {
              attrs: {
                sections: [
                  {
                    value:
                      _vm.levels.totalReach > 100 ? 100 : _vm.levels.totalReach,
                    color: "#1877f2",
                  },
                ],
                size: _vm.donutSizeNumber,
                thickness: 10,
                "auto-adjust-text-size": true,
              },
            },
            [
              _c(
                "span",
                { staticClass: "text-lsm text-color-text font-weight-bold" },
                [_vm._v(_vm._s(_vm.numFormatter(_vm.levels.totalReach)))]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "text-xs text-color-text font-weight-bold" },
      [_vm._v(" Average Engaged Users "), _c("br"), _vm._v(" Rate per Post ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }