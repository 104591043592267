<template>
  <b-row no-gutters class="mt-2">
    <b-col cols="8" class="pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >Tweets
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content: 'Posts published by day and hour',
            }"
          ></i>
        </span>
      </div>
      <div
        v-if="!boolViewData"
        class="image-container-off"
      >
        <img
          class="off_op"
          src="@/assets/images/image-off-graphpost-channel.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
      <highcharts v-else :options="getOptions()" />
    </b-col>
    <b-col cols="4" class="pl-5 pr-2">
      <div class="bg-twitter rounded p-4 text-white">
        <p>Most used time to tweet</p>
        <p class="text-2xl font-weight-light">
          {{ postIndicators.horarioTiempo }}
        </p>
      </div>
      <div class="rounded p-4">
        <p>Total Tweets</p>
        <p class="text-4xl font-weight-bold">{{ numFormatter(total) }}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../../../util/config";
var dataGraph = [];
export default {
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  props: {
    postIndicators: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    boolViewData: {
      type: Boolean,
      required: true,
    },
    total: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {
    getOptions() {
      try {
        let options = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "scatter",
            zoomType: "xy",
            marginTop: 10,
            marginBottom: 80,
          },
          credits: { text: "" },
          exporting: { enabled: false },
          title: { text: null },
          xAxis: {
            title: { text: "Days", align: "middle" },
            // max: 31.5,
            min: 0,
            startOnTick: true,
            endOnTick: true,
            // showLastLabel: false,
            // showFirstLabel: false,
            tickInterval: 1,
            // tickWidth: 0,
            // plotBands: [], //%ybands
            labels: {
              formatter: null,
              autoRotation: false,
              rotation: 0,
              style: { color: "#ababab" },
            },
          },
          yAxis: {
            title: { text: "Hours" },
            labels: {
              formatter: function () {
                return this.value + ":00";
              },
            },
            //min: 0, para empezar a las 00 horas.
            max: 24,
            tickInterval: 1,
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
            plotBands: [
              {
                from: 5,
                to: 12,
                color: "rgba(68, 170, 213, 0.1)",
                label: {
                  text: "Morning",
                  style: { color: "#606060" },
                },
              },
              {
                from: 12,
                to: 18,
                color: "rgba(0,0,0,0)",
                label: {
                  text: "Afternoon",
                  style: { color: "#606060" },
                },
              },
              {
                from: 18,
                to: 24,
                color: "rgba(68, 170, 213, 0.1)",
                label: {
                  text: "Night",
                  style: { color: "#606060" },
                },
              },
            ],
          },
          tooltip: {
            backgroundColor: "#1da1f2",
            borderColor: "#1da1f2",
            textColor: "#FFF",
            formatter: function () {
              if (this.point.clusteredData) {
                return "Clustered points: ";
              }
              return '<b style="color:#fff">View Post</b>';
            },
          },
          legend: {
            enabled: false,
            align: "right",
            x: 10,
            verticalAlign: "bottom",
            y: 10,
            floating: true,
            borderWidth: 0,
            shadow: false,
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: { enabled: true, lineColor: "rgb(100,100,100)" },
                },
              },
              states: {
                hover: { marker: { enabled: false } },
              },
            },
            series: {
              point: {
                events: {
                  click: function (event) {
                    const dataOption = event.point.options;
                    let ref_stack = [];
                    ref_stack["1213.537031067946677"] =
                      process.env.VUE_APP_FB_POST;
                    /*ref_stack["1222.149"] =
                    "https://facebook.com/100159385410654/posts/100161322077127";
                  ref_stack["1222.131"] =
                    "https://facebook.com/100159385410654/posts/100160845410508";
                  ref_stack["1222.131"] =
                    "https://facebook.com/100159385410654/posts/100160805410512";
                  ref_stack["162.149"] =
                    "https://facebook.com/100159385410654/posts/102537508506175";
                  ref_stack["180.916"] =
                    "https://facebook.com/100159385410654/posts/104188491674410";
                  ref_stack["1719.333"] =
                    "https://facebook.com/100159385410654/posts/103986588361267";
                  ref_stack["162.149"] =
                    "https://facebook.com/100159385410654/posts/102537508506175";
                  ref_stack["1813.711"] =
                    "https://facebook.com/100159385410654/posts/104745121618747";
                  ref_stack["1816.643"] =
                    "https://facebook.com/100159385410654/posts/104853688274557";
                  ref_stack["2516.219"] =
                    "https://facebook.com/100159385410654/posts/108088531284406";
                  ref_stack["3010.2"] =
                    "https://facebook.com/100159385410654/posts/109381977821728";*/
                    //this.getFunc()
                    const indexGraphEquals = dataOption.x + "" + dataOption.y;
                    let respUrl = dataGraph.filter(
                      (it) => it.indexGraph == indexGraphEquals
                    );
                    window.open(respUrl[0].url);
                  },
                },
              },
              cursor: "pointer",
            },
          },
          series: [],
        };
        options.series = this.series;
        options.xAxis.max = this.categories.length - 1;
        options.xAxis.categories = this.categories;
        // options.xAxis.max = this.categories.length
        dataGraph = this.series[0].arrUrl;
        return options;
      } catch (error) {
        //console.error('sucedio un error en getOptions: ',error)
        return {};
      }
    },
  },
  created() {},
};
</script>

<style></style>
