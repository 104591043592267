import { render, staticRenderFns } from "./TotalFollowersChart.vue?vue&type=template&id=64b4972a&"
import script from "./TotalFollowersChart.vue?vue&type=script&lang=js&"
export * from "./TotalFollowersChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-overview/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64b4972a')) {
      api.createRecord('64b4972a', component.options)
    } else {
      api.reload('64b4972a', component.options)
    }
    module.hot.accept("./TotalFollowersChart.vue?vue&type=template&id=64b4972a&", function () {
      api.rerender('64b4972a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/channel-detail/socials/twitter/tabs/Audience/TotalFollowersChart.vue"
export default component.exports