<template>
  <div class="w-100 pt-4 d-flex flex-wrap">
    <engaged-users-by-day-chart  :series="detail.usersEngageByDay" :categories="chartCategories" :boolViewData="detail.boolEngagedUsersByDay"/>
    <engaged-levels :levels="detail.levels"/>

    <!-- divider -->
    <div class="w-100 h-16"></div>

    <dayli-engagedment  :series="detail.dayliEngage" :categories="chartCategories"/>
    <engage-type  :count="detail.typeEngage"/>
  </div>
</template>

<script>
import EngagedUsersByDayChart from '@/components/detail/tabs/EngagedUsers/EngagedUsersByDayChart.vue';
import EngagedLevels from '@/components/detail/tabs/EngagedUsers/EngagedLevels.vue';
import DayliEngagedment from '@/components/detail/tabs/EngagedUsers/DayliEngagedment.vue';
import EngageType from '@/components/detail/tabs/EngagedUsers/EngageType.vue';

export default {
  components: { EngagedUsersByDayChart, EngagedLevels, DayliEngagedment, EngageType },
  name: "engagedUsersFb",
  props : {
    detail  : {
      type : Object,
      required : true,
    },
    chartCategories  : {
      type : [ Object, Array ],
      required : true,
    }
  }
};
</script>
