<template>
  <div class="w-100 px-4">
    <div class="mb-4 mt-4 w-100">
      <span class="font-bold"
        >Top Posts
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Posts published by day and hour',
          }"
        ></i>
      </span>
    </div>
    <div v-if="posts.length > 0" class="w-100 d-flex flex-wrap grid-items">
      <div
        style="width: 32%; height: fit-content; font-family: Oxygen"
        class="spacing mb-4 rounded shadow"
        v-for="(post, index) in posts"
        :key="index"
      >
        <div
          class="text-white h-16 w-100 rounded-top d-flex align-items-center justify-content-between px-4"
          :class="[`bg-${post.rrss}`]"
        >
          <div>
            <i :class="`fab fa-${post.rrss} mr-2`"></i>
            <span style="font-size: 15px">Interactions</span>
          </div>
          <span class="text-2xl font-weight-bold" style="font-size: 32px">{{
            numFormatter(calcInteractions(post.interactions))
          }}</span>
        </div>
        <div class="d-flex p-3 align-items-center">
          <img
            :src="post.images.profile"
            style="width: 40px; height: 40px; float: left"
            class="mr-4 rounded-circle"
          />
          <div>
            <p style="font-size: 10px">{{ post.name }}</p>
            <small class="text-skygray" style="font-size: 10px">{{
              post.date
            }}</small>
          </div>
        </div>
        <div class="text-xs px-4" style="font-size: 12px">
          <p class="text-justify">{{ post.post }}</p>
        </div>
        <div class="my-2">
          <a target="_blank" :href="post.url_post">
            <img
              :src="post.images.posts"
              alt=""
              class="max-h-60 w-100 object-cover"
            />
          </a>
        </div>
        <div class="p-2 text-skygray">
          <cp-icon-ch-reaction
            name="Share"
            :amount="post.interactions.shares"
            icon="far fa-share-square"
          />
          <cp-icon-ch-reaction
            name="Comments"
            :amount="post.interactions.comments"
            :icon="'far fa-comments'"
          />

          <cp-icon-ch-reaction :amount="post.interactions.reactions.like" />
          <cp-icon-ch-reaction
            name="Love"
            :amount="post.interactions.reactions.love"
            :icon="'far fa-heart'"
          />
          <cp-icon-ch-reaction
            name="Ha-ha"
            :amount="post.interactions.reactions.haha"
            :icon="'far fa-grin-squint'"
          />
          <cp-icon-ch-reaction
            name="Sad"
            :amount="post.interactions.reactions.sad"
            :icon="'far fa-frown'"
          />
          <cp-icon-ch-reaction
            name="Angry"
            :amount="post.interactions.reactions.angry"
            :icon="'far fa-angry'"
          />
          <cp-icon-ch-reaction
            name="Wow"
            :amount="post.interactions.reactions.wow"
            :icon="'far fa-surprise'"
          />
          <!-- <p class="py-1" style="padding-left: 20px; padding-right: 20px; font-size: 13px;">
                <i class="fa fa-share-alt mr-2 w-4 d-inline-block"></i> Share
                <span class="float-right">{{ post.interactions.shares }}</span>
              </p> -->
        </div>
      </div>
    </div>
    <div v-else class="image-container-off">
      <img
        src="@/assets/images/off-top-post.png"
        class="w-100 d-flex flex-wrap grid-items"
        style="opacity: 0.5; width: 100%"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
  </div>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";
import CpIconChReaction from "../../../../components/CpIconChReaction.vue";
export default {
  name: "PostResume",
  components: {
    CpIconChReaction,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  computed: {
    ...mapGetters({
      titleImgOff: "global/helper/getTitleImgOff",
      msgImgOff: "global/helper/getImageOff", // global img off msg
    }),
  },
  methods: {
    calcInteractions(interactions) {
      let count = 0;
      count += interactions.shares;
      count += interactions.comments;
      count += interactions.retweet;
      const { like, sad, wow, angry, dislike, love, haha } =
        interactions.reactions;
      count += like + sad + wow + angry + dislike + love + haha;
      return count;
    },
  },
};
</script>

<style scoped>
.grid-items .spacing:nth-child(3n + 2) {
  margin-right: 2%;
  margin-left: 2%;
}

.max-h-60 {
  max-height: 15rem;
}
</style>
