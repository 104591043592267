<template>
  <div v-if="rrss && metricDetail" class="box-resume w-100">
    <p class="mb-2 text-lsm text-secondary">
      {{
        variationType === "bottom"
          ? "Bottom Social Network"
          : "Top Social Network"
      }}
    </p>
    <div class="">
      <div class="mb-0 leading-none title-color text-3-5xl">
        <b>{{ numFormatter(metricDetail.count) }}</b>
      </div>
      <span class="text-skygray text-xxs eading-none text-capitalize">{{
        metric
      }}</span>
    </div>
    <div class="w-100">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-block socialMedia-name">
          <i :class="`fab fa-${rrss.name} mr-1 text-${rrss.name}`"></i>
          <span class="text-xs text-secondary">
            {{ fixNameSocialNetwork(rrss.name) + ": " + rrss.account }}
          </span>
        </div>
        <div>
          <span class="text-lg text-secondary">
            {{ metricDetail.variation }}%
          </span>
          <i
            :class="`fa fa-sort-${
              metricDetail.variation > 0
                ? 'up text-success'
                : 'down text-danger'
            }`"
          ></i>
        </div>
      </div>
    </div>
    <div class="position-relative w-100">
      <div class="position-relative z-0 bg-white h-2"></div>
      <div
        :class="`position-absolute h-2 bg-${rrss.name} top-0`"
        :style="`width: ${metricDetail.variation}%; z-index : 2; transition : all ease 0.2s; max-width : 100%`"
      ></div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Helpers } from "@isc/styleguide";
export default {
  name: "ResumeVariation",
  props: {
    variationType: {
      type: String,
      required: true,
    },
    accounts: {
      type: Array,
      required: true,
    },
    metric: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      metricDetail: null,
      rrss: null,
      fixNameSocialNetwork: (name) => Helpers.fixNameSocialNetwork(name),
    };
  },
  mounted() {
    this.processAccounts();
  },
  methods: {
    processAccounts() {
      try {
        let bestAccount = null;
        if (this.variationType === "top") {
          //ordenando cuentas primero por variation y si tienen el mismo valor ordenar por cantidad
          bestAccount = _.orderBy(
            this.accounts,
            ["audience.count", "audience.variation"],
            ["desc", "desc"]
          )[0];
        } else {
          bestAccount = _.orderBy(
            this.accounts,
            ["audience.count", "audience.variation"],
            ["asc", "asc"]
          )[0];
        }
        const account = bestAccount[this.metric];
        this.metricDetail = {
          count: account.count,
          variation: account.variation,
        };
        this.rrss = {
          name: bestAccount.rrss,
          account: bestAccount.name,
        };
      } catch (error) {
        console.error("sucedio un error en processAccounts: ", error);
      }
    },
    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000) {
        num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num < 900) {
        num; // if value < 1000, nothing to do
      }
      return num;
    },
  },
  watch: {
    accounts() {
      this.processAccounts();
    },
    metric() {
      this.processAccounts();
    },
  },
};
</script>
<style scoped>
.metric-detail {
  font-family: Oxygen;
  font-size: 32px;
  font-weight: bold;
}
.socialMedia-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 65%;
}
</style>
