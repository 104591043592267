import { render, staticRenderFns } from "./TwitterDetail.vue?vue&type=template&id=ed16a1ca&"
import script from "./TwitterDetail.vue?vue&type=script&lang=js&"
export * from "./TwitterDetail.vue?vue&type=script&lang=js&"
import style0 from "./TwitterDetail.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-overview/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ed16a1ca')) {
      api.createRecord('ed16a1ca', component.options)
    } else {
      api.reload('ed16a1ca', component.options)
    }
    module.hot.accept("./TwitterDetail.vue?vue&type=template&id=ed16a1ca&", function () {
      api.rerender('ed16a1ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/channel-detail/socials/twitter/TwitterDetail.vue"
export default component.exports