<template>
  <div>
    <highcharts :options="getOptions()" />
  </div>
</template>
<script>
export default {
  props: {
    titlexAxis: {
      type: String,
      defautl: '',
      required: true,
    },
    yAxis: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      default:[],
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getOptions() {
      var _titlexAxis = this.titlexAxis
      try {
        let options = {
          chart: { type: "column" },
          title: { text: "" },
          series: [],
          credits: { enabled: false },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          legend: {
            layout: "horizontal",
            align: "right",
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          navigation: {
            buttonOptions: {
              enabled: false,
            },
          },
        };
        options.xAxis = {
            title: { text: _titlexAxis },
            min: 0,
            tickWidth: 0,
            categories: [],
            crosshair: true,
            labels: {
            autoRotation: false,
            rotation: 0,
            style: { color: "#ababab" },
          },
        }
        //DECLARE yAxis
        options.yAxis = this.yAxis
        options.yAxis.min = 0
        options.yAxis.allowDecimals = false
        options.series = this.series;
        options.xAxis.categories = this.categories;
        return options;
      } catch (error) {
        console.error("sucedio un error en getOptions: ", error);
        return {};
      }
    },
  },
};
</script>
