var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("interactions-chart", {
        attrs: {
          detail: _vm.detailInteractions,
          categories: _vm.categories,
          boolViewData: _vm.boolViewData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }