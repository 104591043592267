var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("total-suscribers-chart", {
        attrs: {
          totals: _vm.totals,
          series: _vm.totalSeries,
          categories: _vm.categories,
          boolViewData: _vm.boolViewDataSuscribers,
        },
      }),
      _c("div", { staticClass: "w-100 h-20" }),
      _c("new-suscribers-chart", {
        attrs: {
          series: _vm.viewsSeries,
          categories: _vm.categories,
          boolViewData: _vm.boolViewDataViewersYT,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }