import { render, staticRenderFns } from "./InteractionsYt.vue?vue&type=template&id=72958ec2&"
import script from "./InteractionsYt.vue?vue&type=script&lang=js&"
export * from "./InteractionsYt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-overview/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72958ec2')) {
      api.createRecord('72958ec2', component.options)
    } else {
      api.reload('72958ec2', component.options)
    }
    module.hot.accept("./InteractionsYt.vue?vue&type=template&id=72958ec2&", function () {
      api.rerender('72958ec2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/channel-detail/socials/youtube/tabs/Interactions/InteractionsYt.vue"
export default component.exports