<template>
  <div class="w-100 pt-4 d-flex flex-wrap" v-if="postsDetails">
    <posts-total-char
      :series="postsDetails.postsGraph"
      :categories="postsDetails.chartCategories"
      :viewGraph="postsDetails.viewGraph"
      :arrDataUrl="postsDetails.arrDataUrl"
    />
    <bar-graph class="font-weight-bold pl-lg-3 pl-xl-4"
      :callcomponentType="1"
      :socialData="postsDetails.totalPostByRrss"
      title="Total Posts"
      :tooltipMsg="'Total number of posts by associated social networks'"
    />
    <div class="w-100 h-16"></div>
    <post-per-day-chart :series="postsDetails.postPerDayGraph" />
    <most-used
      :mostUsedDays="postsDetails.mostUsedDays"
      :mostUsedHours="postsDetails.mostUsedHours"
    />
  </div>
</template>

<script>
import BarGraph from "../shared/BarGraph.vue";
import MostUsed from "./MostUsedDays.vue";
import PostPerDayChart from "./PostPerDayChart.vue";
import PostsTotalChar from "./PostsTotalChar.vue";
import { mapGetters } from "@isc/styleguide";

export default {
  components: { PostsTotalChar, BarGraph, PostPerDayChart, MostUsed },
  name: "posts",
  data() {
    return {
    };
  },
  props: {
    rangeDate: {
      type: Array,
      required: true,
    },
  },
  created() {
  },
  methods: {
    async loadPostsDetails() {  },
  },
  computed: {
    ...mapGetters({ postsDetails: "overview/account/getPostsDetails" }),
  },
};
</script>
<style>
</style>