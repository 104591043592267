(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@isc/styleguide"), require("vue"), require("vue-router"));
	else if(typeof define === 'function' && define.amd)
		define(["@isc/styleguide", "vue", "vue-router"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@isc/styleguide"), require("vue"), require("vue-router")) : factory(root["@isc/styleguide"], root["vue"], root["vue-router"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__isc_styleguide__, __WEBPACK_EXTERNAL_MODULE_vue__, __WEBPACK_EXTERNAL_MODULE_vue_router__) {
return 