var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 px-4" }, [
    _c("div", { staticClass: "mb-4 mt-4 w-100" }, [
      _c("span", { staticClass: "font-bold" }, [
        _vm._v("Top Posts "),
        _c("i", {
          directives: [
            {
              name: "b-popover",
              rawName: "v-b-popover.hover",
              value: {
                variant: "primary",
                content: "Posts published by day and hour",
              },
              expression:
                "{\n          variant: 'primary',\n          content: 'Posts published by day and hour',\n        }",
              modifiers: { hover: true },
            },
          ],
          staticClass: "fa fa-info-circle text-skygray",
        }),
      ]),
    ]),
    _vm.posts.length > 0
      ? _c(
          "div",
          { staticClass: "w-100 d-flex flex-wrap grid-items" },
          _vm._l(_vm.posts, function (post, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "spacing mb-4 rounded shadow",
                staticStyle: {
                  width: "32%",
                  height: "fit-content",
                  "font-family": "Oxygen",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-white h-16 w-100 rounded-top d-flex align-items-center justify-content-between px-4",
                    class: ["bg-" + post.rrss],
                  },
                  [
                    _c("div", [
                      _c("i", { class: "fab fa-" + post.rrss + " mr-2" }),
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v("Interactions"),
                      ]),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "text-2xl font-weight-bold",
                        staticStyle: { "font-size": "32px" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.numFormatter(
                              _vm.calcInteractions(post.interactions)
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "d-flex p-3 align-items-center" }, [
                  _c("img", {
                    staticClass: "mr-4 rounded-circle",
                    staticStyle: {
                      width: "40px",
                      height: "40px",
                      float: "left",
                    },
                    attrs: { src: post.images.profile },
                  }),
                  _c("div", [
                    _c("p", { staticStyle: { "font-size": "10px" } }, [
                      _vm._v(_vm._s(post.name)),
                    ]),
                    _c(
                      "small",
                      {
                        staticClass: "text-skygray",
                        staticStyle: { "font-size": "10px" },
                      },
                      [_vm._v(_vm._s(post.date))]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "text-xs px-4",
                    staticStyle: { "font-size": "12px" },
                  },
                  [
                    _c("p", { staticClass: "text-justify" }, [
                      _vm._v(_vm._s(post.post)),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "my-2" }, [
                  _c(
                    "a",
                    { attrs: { target: "_blank", href: post.url_post } },
                    [
                      _c("img", {
                        staticClass: "max-h-60 w-100 object-cover",
                        attrs: { src: post.images.posts, alt: "" },
                      }),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "p-2 text-skygray" },
                  [
                    _c("cp-icon-ch-reaction", {
                      attrs: {
                        name: "Share",
                        amount: post.interactions.shares,
                        icon: "far fa-share-square",
                      },
                    }),
                    _c("cp-icon-ch-reaction", {
                      attrs: {
                        name: "Comments",
                        amount: post.interactions.comments,
                        icon: "far fa-comments",
                      },
                    }),
                    _c("cp-icon-ch-reaction", {
                      attrs: { amount: post.interactions.reactions.like },
                    }),
                    _c("cp-icon-ch-reaction", {
                      attrs: {
                        name: "Love",
                        amount: post.interactions.reactions.love,
                        icon: "far fa-heart",
                      },
                    }),
                    _c("cp-icon-ch-reaction", {
                      attrs: {
                        name: "Ha-ha",
                        amount: post.interactions.reactions.haha,
                        icon: "far fa-grin-squint",
                      },
                    }),
                    _c("cp-icon-ch-reaction", {
                      attrs: {
                        name: "Sad",
                        amount: post.interactions.reactions.sad,
                        icon: "far fa-frown",
                      },
                    }),
                    _c("cp-icon-ch-reaction", {
                      attrs: {
                        name: "Angry",
                        amount: post.interactions.reactions.angry,
                        icon: "far fa-angry",
                      },
                    }),
                    _c("cp-icon-ch-reaction", {
                      attrs: {
                        name: "Wow",
                        amount: post.interactions.reactions.wow,
                        icon: "far fa-surprise",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "image-container-off" }, [
          _c("img", {
            staticClass: "w-100 d-flex flex-wrap grid-items",
            staticStyle: { opacity: "0.5", width: "100%" },
            attrs: {
              src: require("@/assets/images/off-top-post.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "image-container-off__message" }, [
            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
            _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }