var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "boxMain overview-boxMain", attrs: { id: "accountbox" } },
    [
      _c("div", { staticClass: "first-block eye-layer" }, [
        _c(
          "div",
          {
            class: ["eye", _vm.showeye == true ? "eyesee" : ""],
            on: {
              mouseover: function ($event) {
                return _vm.changeenter(1)
              },
              mouseleave: function ($event) {
                return _vm.changeenter(0)
              },
              click: _vm.navigateTo,
            },
          },
          [_c("i", { staticClass: "fa fa-eye fa-6x" })]
        ),
        _c("div", { staticClass: "imgBox" }, [
          _c("img", {
            attrs: { src: _vm.accountData.image },
            on: { error: _vm.errorUrlImage },
          }),
        ]),
        _c("div", { staticClass: "data" }, [
          _c("div", { staticClass: "general" }, [
            _c(
              "div",
              { staticClass: "general__social-icon iconCustomByCardRSS" },
              _vm._l(
                _vm.accountData.social_networks,
                function (social_network, index) {
                  return _c("i", {
                    key: index,
                    class: ["fab", "fa-" + social_network],
                    attrs: { title: social_network },
                  })
                }
              ),
              0
            ),
            _c("span", { staticClass: "general__title" }, [
              _vm._v(_vm._s(_vm.accountData.title)),
            ]),
            _c("span", { staticClass: "general__level" }, [
              _vm._v(_vm._s(_vm.numFormatter(_vm.accountData.audienceLevel))),
            ]),
            _vm._m(0),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "second-block" }, [
        _c("div", { staticClass: "info" }, [
          _c("span", [
            _vm._v(" Posts "),
            _vm.accountData.social_networks.length > 0
              ? _c("div", { staticClass: "hide" }, [
                  _c("i", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.top",
                        value:
                          "Sum of Facebook Posts, Youtube videos, Tweets ,Instagram posts and LinkedIn posts",
                        expression:
                          "\n              'Sum of Facebook Posts, Youtube videos, Tweets ,Instagram posts and LinkedIn posts'\n            ",
                        modifiers: { top: true },
                      },
                    ],
                    staticClass: "ml-1 fa fa-question-circle cursor-pointer",
                    attrs: {
                      title: "Posts " + _vm.fechamonth + " - " + _vm.fechaAyer,
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _c("span", { staticClass: "general__title font-weight-bold" }, [
            _vm._v(_vm._s(_vm.numFormatter(_vm.accountData.totalPosts))),
          ]),
        ]),
        _c("div", { staticClass: "info" }, [
          _c("span", [
            _vm._v(" Engaged Users "),
            _c("i", {
              staticClass: "fab fa-facebook",
              attrs: { title: "Facebook" },
            }),
            _vm.accountData.social_networks.length > 0
              ? _c("div", { staticClass: "hide" }, [
                  _c("i", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.top",
                        value: "Sumf of Facebook Engaged Users",
                        expression: "'Sumf of Facebook Engaged Users'",
                        modifiers: { top: true },
                      },
                    ],
                    staticClass: "ml-1 fa fa-question-circle cursor-pointer",
                    attrs: {
                      title:
                        "Engaged Users " +
                        _vm.fechamonth +
                        " - " +
                        _vm.fechaAyer,
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _c("span", { staticClass: "general__title font-weight-bold" }, [
            _vm._v(
              _vm._s(_vm.numFormatter(_vm.accountData.engaged_users)) + " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "info" }, [
          _c(
            "div",
            { staticClass: "d-flex containerInteractions" },
            [
              _c("span", [_vm._v(" Interactions ")]),
              _c("i", {
                staticClass: "fa fa-info-circle iconInfoInter",
                attrs: {
                  id: "interactions" + _vm.accountIndex,
                  "aria-hidden": "true",
                },
              }),
              _c("CpTooltipDoubleCustom", {
                attrs: {
                  target: "interactions" + _vm.accountIndex,
                  tooltipTitle: "interactions" + _vm.accountIndex,
                  title:
                    "Update: Every 30 minutes (if the campaign is active).",
                  subTitle1:
                    "For posts that are not linked to a campaign the update is every 24 hours.",
                  subTitle2:
                    "Sum of likes, comments, shares, favorites, dislikes, retweets.",
                  heightSubTitle1: "89px",
                  heightSubTitle2: "57px",
                },
              }),
            ],
            1
          ),
          _c("span", { staticClass: "general__title font-weight-bold" }, [
            _vm._v(
              _vm._s(_vm.numFormatter(_vm.accountData.totalInteractions)) + " "
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "third-block" },
        [
          _c(
            "b-button",
            {
              staticClass: "w-btn",
              attrs: { squared: "", variant: "mainbg" },
              on: { click: _vm.navigateTo },
            },
            [_c("i", { staticClass: "far fa-eye" })]
          ),
          _c(
            "b-button",
            {
              staticClass: "w-btn",
              attrs: { squared: "", variant: "mainbg" },
              on: {
                click: function ($event) {
                  _vm.modal = !_vm.modal
                },
              },
            },
            [_c("i", { staticClass: "fas fa-edit" })]
          ),
          _c(
            "b-button",
            {
              staticClass: "w-btn",
              attrs: { squared: "", variant: "mainbg" },
              on: {
                click: function ($event) {
                  return _vm.openModalDelete(_vm.accountData.title)
                },
              },
            },
            [_c("i", { staticClass: "far fa-trash-alt" })]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: "Edit Account",
            "header-class": "border-0 px-5 title-edit-account",
            "hide-footer": "",
            "no-enforce-focus": true,
            "no-close-on-backdrop": true,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header-close",
              fn: function () {
                return [
                  _c("CloseButton", {
                    staticClass: "p-0",
                    attrs: { fill: _vm.customFill },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modal,
            callback: function ($$v) {
              _vm.modal = $$v
            },
            expression: "modal",
          },
        },
        [
          _c("edit-account-modal", {
            attrs: { accountData: _vm.accountData },
            on: { onClose: _vm.confirmUpdate },
          }),
        ],
        1
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.iconCode,
          iconColor: _vm.iconColorCode,
          title: _vm.titleCode,
          paragraph: _vm.paragraphCode,
          buttonMessage: _vm.buttonMessageCode,
          noCloseBackdrop: true,
          refresh: true,
        },
        on: { sendResult: _vm.resultModalAlertsCode },
        model: {
          value: _vm.showAlertCode,
          callback: function ($$v) {
            _vm.showAlertCode = $$v
          },
          expression: "showAlertCode",
        },
      }),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
        },
        on: { sendResult: _vm.resultModalAlerts, input: _vm.closeIconModal },
        model: {
          value: _vm.showAlert,
          callback: function ($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex container-audience" }, [
      _c("small", { staticClass: "general__audience" }, [_vm._v("Audience")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }