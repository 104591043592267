<template>
  <div class="w-100 pt-4 d-flex flex-wrap content-interaction-tab" v-if="interactionsDetail">
    <interactions-total-chart :series="interactionsDetail.interactionsByDay" :chartCategories="interactionsDetail.chartCategories" />
    <div class="w-4/12 pl-xl-4 pl-lg-2 pr-2">
      <div class="w-100 rounded box-total bg-primary p-3">
        <p class="text-white mb-2 d-flex flex-column">
          <span class="text-lsm">Total Interactions</span>
          <span class="text-3-5xl font-weight-bold">{{
            numFormatter(interactionsDetail.total)
          }}</span>
        </p>
      </div>
      <br>
      <div class="col px-0 bar-graph-st">
        <bar-graph class="font-weight-bold"
          :socialData="interactionsDetail.socialsTotal"
          title="Interactions by social network"
          tooltipMsg="Total of interactions of all social networks per type"
        />
      </div>
    </div>

    <div class="w-100 h-16"></div>

    <interactions-by-type class="font-weight-bold" :series="interactionsDetail.interactionsByType" :title="'Interactions by day of the week'"/>
    <bar-graph class="font-weight-bold pl-xl-4 pl-lg-2 pr-2 "
      :rrss="false"
      :socialData="interactionsDetail.typesTotal"
      :bytype="true"
      title="Interactions by type"
      tooltipMsg="Total of interactions of all social networks per type"
    />
    

    <div class="w-100 h-16"></div>
    <post-resume :posts="interactionsDetail.posts" />
  </div>
</template>

<script>
import BarGraph from "../shared/BarGraph.vue";
import InteractionsSocial from "./InteractionsByRSChart.vue";
import InteractionsByType from "./InteractionsByType.vue";
import InteractionsTotalChart from "./InteractionsTotalChart.vue";
import PostResume from "./PostResume";
import { mapGetters, Helpers } from "@isc/styleguide";


export default {
  name: "interactions",
  components: {
    PostResume,
    InteractionsTotalChart,
    InteractionsSocial,
    InteractionsByType,
    BarGraph,
  },
  props: {
    rangeDate: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  created() {
  },
  methods: {
    async loadInteractionsDetail() {
      const loader = this.$loading.show();
      this.interactionsDetail = interactionsDetail;
      loader.hide()
    },
  },
  computed: {
    ...mapGetters({interactionsDetail: 'overview/account/getInteractionsDetails'}),
  }
};
</script>

<style scoped>
.content-interaction-tab .box-total{
  height: 100px;
}
.content-interaction-tab .bar-graph-st .w-4\/12{
  width: 100% !important;
}
</style>
