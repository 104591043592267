var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "boxMain overview-boxmain" }, [
    _c(
      "div",
      {
        staticClass: "first-block eye-layer",
        class: "bg-" + _vm.accountData.rrss,
        on: { click: _vm.navigateTo },
      },
      [
        _c(
          "div",
          {
            class: ["eye", _vm.showeye == true ? "eyesee" : ""],
            on: {
              mouseover: function ($event) {
                return _vm.changeenter(1)
              },
              mouseleave: function ($event) {
                return _vm.changeenter(0)
              },
            },
          },
          [_c("i", { staticClass: "fa fa-eye fa-4x" })]
        ),
        _c("div", { staticClass: "imgBox" }, [
          _c("img", {
            attrs: { src: _vm.accountData.image },
            on: { error: _vm.errorUrlImage },
          }),
        ]),
        _c("div", { staticClass: "data" }, [
          _c("div", { staticClass: "general" }, [
            _c(
              "div",
              { staticClass: "general__social-icon iconCustomByCardRSS" },
              [
                _c("i", {
                  class: ["fab", "fa-" + _vm.accountData.rrss],
                  attrs: { title: _vm.accountData.rrss },
                }),
              ]
            ),
            _c("span", { staticClass: "general__title" }, [
              _vm._v(_vm._s(_vm.accountData.name)),
            ]),
            _c("span", { staticClass: "general__level" }, [
              _vm._v(_vm._s(_vm.numFormatter(_vm.accountData.audience))),
            ]),
            _c("small", { staticClass: "general__audience" }, [
              _vm._v("Audience"),
            ]),
            _c("div", { staticClass: "general__rrss" }, [
              _c("span", [
                _vm._v("Created " + _vm._s(_vm.accountData.created)),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _c("div", { staticClass: "second-block" }, [
      _c("div", { staticClass: "info" }, [
        _c("span", [
          _vm._v(" Posts "),
          _c("div", { staticClass: "hide" }, [
            _c("i", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.top",
                  value: "16/12/2020 - 15/01/2021",
                  expression: "'16/12/2020 - 15/01/2021'",
                  modifiers: { top: true },
                },
              ],
              staticClass: "ml-1 fa fa-question-circle",
              attrs: { title: "Engaged Users" },
            }),
          ]),
        ]),
        _c("span", { staticClass: "text-xl" }, [
          _vm._v(_vm._s(_vm.numFormatter(_vm.accountData.posts))),
        ]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("span", [
          _vm._v(" " + _vm._s(_vm.getMetricByRS().name) + " "),
          _c("div", { staticClass: "hide" }, [
            _c("i", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.top",
                  value: "16/12/2020 - 15/01/2021",
                  expression: "'16/12/2020 - 15/01/2021'",
                  modifiers: { top: true },
                },
              ],
              staticClass: "ml-1 fa fa-question-circle",
              attrs: { title: _vm.getMetricByRS().name },
            }),
          ]),
        ]),
        _c("span", { staticClass: "text-xl" }, [
          _vm._v(_vm._s(_vm.numFormatter(_vm.getMetricByRS().value))),
        ]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("span", [
          _vm._v(" Interactions "),
          _c("div", { staticClass: "hide" }, [
            _c("i", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.top",
                  value: "16/12/2020 - 15/01/2021",
                  expression: "'16/12/2020 - 15/01/2021'",
                  modifiers: { top: true },
                },
              ],
              staticClass: "ml-1 fa fa-question-circle",
              attrs: { title: "Interactions" },
            }),
          ]),
        ]),
        _c("span", { staticClass: "text-xl" }, [
          _vm._v(_vm._s(_vm.numFormatter(_vm.accountData.interactions))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }