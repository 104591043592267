<template>
  <div class="w-100">
    <total-suscribers-chart :totals="totals" :series="totalSeries"  :categories="categories" :boolViewData="boolViewDataSuscribers"/>
    <div class="w-100 h-20"></div>
    <new-suscribers-chart :series="viewsSeries" :categories="categories" :boolViewData="boolViewDataViewersYT"/>
  </div>
</template>

<script>
import NewSuscribersChart from "./NewSuscribersChart.vue";
import TotalSuscribersChart from "./TotalSuscribersChart.vue";

export default {
  components: { NewSuscribersChart, TotalSuscribersChart },
  props : {
    totals : {
      type : Object,
      required : true
    },
    totalSeries : {
      type : Array,
      required : true
    },
    viewsSeries : {
      type : Array,
      required : true
    },
    categories : Array,
    boolViewDataSuscribers: {
      type : Boolean,
      required : true
    },
    boolViewDataViewersYT: {
      type : Boolean,
      required : true
    }
  }
};
</script>

<style>
</style>