<template>
  <b-row no-gutters>
    <b-col cols="8" class="mt-2 pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >Reactions
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content: 'People who reacted on your posts',
            }"
          ></i>
        </span>
      </div>
      <highcharts v-if="view" :options="options" />
      <div
        v-else
        class="image-container-off"
      >  
        <img
          src="@/assets/images/image-off-emotions.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message"> 
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
    </b-col>
    <b-col cols="4" class="pl-5 pr-2">
      <div
        class="d-flex justify-content-between w-100 h-12"
        v-for="(sentiment, index) in sentiments"
        :key="index"
      >
        <span>
          <img :src="sentiment.iconPath" class="h-4" />
          {{ sentiment.name }}
        </span>
        <span>
          {{ numFormatter(getSentimentCant(sentiment.name)) }}
        </span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";

export default {  
  props: {
    series: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Object,
      required: true,
    },
    chartCategories: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      numFormatter: (num) => Helpers.numFormatter(num),
      sentiments: [
        {
          iconPath: require("@/assets/images/reactions/like.svg"),
          name: "Likes",
        },
        {
          iconPath: require("@/assets/images/reactions/love.svg"),
          name: "Love",
        },
        {
          iconPath: require("@/assets/images/reactions/haha.svg"),
          name: "Ha-Ha",
        },
        {
          iconPath: require("@/assets/images/reactions/wow.svg"),
          name: "Wow",
        },
        {
          iconPath: require("@/assets/images/reactions/sad.svg"),
          name: "Sad",
        },
        {
          iconPath: require("@/assets/images/reactions/angry.svg"),
          name: "Angry",
        },
      ],
      options: {
        chart: { type: "spline" },
        title: { text: "" },
        legend: {
          align: "right",
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        series: [],
        xAxis: {
          categories: [],
        },
        yAxis: [
          {
            allowDecimals: false,
            label: { align: "left" },
            min: 0,
            title: { text: "" },
          },
        ],
        credits: { enabled: false },
        tooltip: { crosshairs: true, shared: true },
      },
      view: false,
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  created() {
    this.validate();
    this.options.series = this.series;
    this.options.xAxis.categories = this.chartCategories;
  },
  methods: {
    getSentimentCant(name) {
      return this.dataList.sentiments.find((item) => item.name === name).cant;
    },
    validate() {
      for (const item of this.series) {
        item.data.forEach((element) => {
          if (element > 0) {
            this.view = true;
            return;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.panels > div {
  width: 45%;
}
</style>
