<template>
  <div class="w-100">
    <total-followers-chart
      :series="detail.totalSubscribersGraph"
      :total="detail.audienceTotals.subscribers"
      :boolViewData="detail.boolViewDataSuscribers"
      :categories="categories"
    />
    <div class="w-100 h-20"></div>
    <new-followers-chart
      :series="[detail.totalSubscribersGraph[1]]"
      :total="detail.audienceTotals.newSubscribers"
      :average="detail.audienceTotals.AverageNewSubscribers"
      :categories="categories"
    />
  </div>
</template>

<script>
import NewFollowersChart from "./NewFollowersChart.vue";
import TotalFollowersChart from "./TotalFollowersChart.vue";

export default {
  components: { NewFollowersChart, TotalFollowersChart },
  props: {
    detail: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true
    }
  },
};
</script>

<style></style>
