<template>
  <div class="w-100">
    <interactions-chart :detail="detailInteractions" :categories="categories" :boolViewData="boolViewData"/>
  </div>
</template>

<script>
import InteractionsChart from './InteractionsChart.vue';
export default {
  components: {InteractionsChart },
  props : {
    detailInteractions : {
      type : Object,
      required : true
    },
    categories :Array,
    boolViewData: {
      type : Boolean,
      required : true
    }
  }
};
</script>

<style>
</style>