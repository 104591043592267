<template>
  <b-row no-gutters>
    <b-col cols="8 pr-5" class="mt-2 pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >Interactions
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content:
                'Total number of reactions, comments and shares per publication.',
            }"
          ></i>
        </span>
      </div>
      <graph-column-multiple
        v-if="view == true"
        :series="series"
        :categories="categories"
      ></graph-column-multiple>
      <div
        v-if="view == false"
        class="image-container-off"
      >
        <img
          src="@/assets/images/image-off-interactionsmultiple.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
    </b-col>
    <b-col cols="4" class="pl-5 pt-3 pr-2">
      <div class="bg-facebook rounded p-4 text-white">
        <p>Total Interactions</p>
        <p class="text-4xl font-weight-bold">
          {{ numFormatter(dataList.total) }}
        </p>
      </div>
      <div class="rounded p-4">
        <p>Average Interactions By Post</p>
        <p class="text-4xl font-weight-bold">
          {{ numFormatter(dataList.byPost) }}
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide"
import graphColumnMultiple from "../../../../../graph-custom/graphColumnMultiple.vue";
export default {
  components: {
    graphColumnMultiple,
  },
  data() {
    return {
      options: {
        chart: { type: "column" },
        title: { text: "" },
        legend: {
          align: "right",
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        series: [],
        xAxis: {
          categories: [],
          crosshair: true,
          labels: {
            autoRotation: false,
            rotation: 0,
            style: { color: "#ababab" },
          },
        },
        yAxis: [
          {
            allowDecimals: false,
            label: { align: "left" },
            min: 0,
            title: { text: "Interactions" },
          },
          {
            allowDecimals: false,
            label: { align: "right" },
            min: 0,
            title: { text: "Post" },
            opposite: true,
          },
        ],
        credits: { enabled: false },
      },
      view: false,
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  props: {
    series: {
      type: [Object, Array],
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Object,
      required: true,
    },
    chartCategories: {
      type: [Object, Array],
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  created() {
    this.options.series = this.series;
    this.validate();
    this.options.xAxis.categories = this.chartCategories;
  },
  methods: {
    validate() {
      for (const item of this.series) {
        item.data.forEach((element) => {
          if (element > 0) {
            this.view = true;
            return;
          }
        });
      }
    },
  },
};
</script>
