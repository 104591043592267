<template>
  <div>
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <div class="w-100 detail-content-box" v-if="detail">
      <profile-bar
        :detail="detail"
        :accountName="detail.name"
        :rrss="'facebook'"
        :logo="detail.logo"
      />

      <div class="w-100 d-flex text-left mb-4 box-card-channel" :class="flexClass">
        <variation-item
          class="box-card-channel__item"
          v-for="(variation, index) in detail.variations"
          :key="index"
          :backgrounColor="variation.background"
          :title="variation.title"
          :level="variation.level"
          :cant="variation.cant"
          :up="variation.up"
          :rrss="'facebook'"
        />
      </div>

      <!-- tabs -->
      <tabs :cant="4" :titles="tabs" @change="changeTab" />

      <div class="w-100 bg-white p-xl-5 p-lg-3">
          <audience v-if="activeTab === 0" :detail="detail.audience" :chartCategories="detail.chartCategories" />
          <posts v-else-if="activeTab === 1" :detail="detail.posts" :chartCategories="detail.chartCategories" />
          <engaged-users
            v-else-if="activeTab === 2"
            :detail="detail.engaged_users"
            :chartCategories="detail.chartCategories"
          />
          <interactions v-else :detail="detail.interactions" :chartCategories="detail.chartCategories"/>
        <!-- </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs } from "@isc/styleguide";
import ProfileBar from "../../ProfileBar";
import VariationItem from "../../VariationItem";
import Audience from "./tabs/Audience/AudienceFb";
import EngagedUsers from "./tabs/EngagedUsers/EngagedUsersFb";
import Interactions from "./tabs/Interactions/InteractionsFb";
import Posts from "./tabs/Posts/PostsFb";

import { facebookDetailByChannel } from "@/services/channel.service";

import { loaderCustom } from "@isc/styleguide"; //"../transversal/loader.vue";

export default {
  components: {
    ProfileBar,
    Tabs,
    VariationItem,
    Audience,
    EngagedUsers,
    Interactions,
    Posts,
    loaderCustom,
  },
  props : {
    ChannelId: {
      type: [String, Number],
      required: true,
    },
    RangeDate: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      detail: null,
      tabs: [
        {
          name: "Audience",
        },
        {
          name: "Posts",
        },
        {
          name: "Engaged Users",
          icon: "fab fa-facebook text-facebook",
        },
        {
          name: "Interactions",
        },
      ],
      visibilityLoader: true,
      flexClass: 'boxItemLess'
    };
  },
  async mounted() {
    
    await this.getDetailFB();
    (this.detail.variations.length) > 3 ? this.flexClass = 'boxItemMore': this.flexClass = 'boxItemLess';
  },
  methods: {
    changeTab(position) {
      this.activeTab = position;
    },
    async getDetailFB(){
      try {
        const detail = await facebookDetailByChannel(this.ChannelId,
        {
          init: this.RangeDate[0],
          end: this.RangeDate[1],
        }); //TODO : OBTENER ID DE CHANNEL
        this.detail = detail;
        this.visibilityLoader = false
      } catch (error) {
      }
    }
  },
};
</script>

<style>
.detail-content-box{
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
}
</style>
