<template>
  <div class="w-8/12 pl-3 pr-5">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >Interactions by day
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Total interactions per day and social network',
          }"
        ></i>
      </div>
    </div>
    <graph-column
      v-if="series.length > 0"
      :titlexAxis="titlexAxis"
      :yAxis="yAxis"
      :series="series"
      :categories="chartCategories"
    ></graph-column>
    <div v-else class="image-container-off">
      <img
        class="off_op et"
        src="@/assets/images/off-interactions-by-day.png"
        style="opacity: 0.5; width: 100%; height: 70%"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../util/config";
import graphColumn from "../../../graph-custom/graphColumn";

export default {
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      yAxis: {
        title: { text: "Interactions" },
      },
      titlexAxis: "Days",
    };
  },
  components: {
    graphColumn,
  },
  props: {
    series: {
      type: Array,
      required: true,
    },
    chartCategories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
};
</script>
<style>
.off_op {
  opacity: 0.5;
}
</style>
