<template>
  <div class="w-4/12 pl-lg-3 pl-xl-4 mostUsedDays-box">
    <div class="p-0 mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >Most used days to post
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Most used days to publish by social network',
          }"
        ></i>
      </div>
    </div>
    <div
      class="w-100 d-flex flex-wrap justify-content-between text-white text-center mb-5"
    >
    <div
      v-if="!view"
      class="image-container-off"
    >
      <img
        class="opacity-50"
        src="@/assets/images/off-most-used-day-post.png"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
      <div
        v-if="view == true"
        class="w-100 d-flex flex-wrap justify-content-between text-white text-center mb-5"
      >
        <div
          class="w-1/2 mb-3"
          v-for="(item, index) in mostUsedDays"
          :key="index"
        >
          <div :class="`w-10/12 bg-${item.rrss} p-xl-4 p-lg-3 base-box`">
            <p><i :class="`fab fa-${item.rrss}`"></i></p>
            <p class="font-thin text-capitalize text-lg">{{ item.day }}</p>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <div class="d-flex align-items-center">
          <span
            class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
            >Most used hours to post
          </span>
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content: 'Most used hours to publish by social network',
            }"
          ></i>
        </div>
      </div>
      <div
      v-if="!view"
      class="image-container-off"
      >
        <img
          class="opacity-50"
          src="@/assets/images/off-most-used-hours-post.png"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
      <div
        v-else
        class="w-100 d-flex flex-wrap justify-content-between text-white text-center mb-5"
      >
        <div
          class="w-1/2 mb-3"
          v-for="(item, index) in mostUsedHours"
          :key="index + 100"
        >
          <div :class="`w-10/12 bg-${item.rrss} p-xl-4 p-lg-3 base-box`">
            <p><i :class="`fab fa-${item.rrss}`"></i></p>
            <p class="font-thin text-lg">{{ item.hour }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../util/config";
export default {
  props: {
    mostUsedDays: {
      type: Array,
      required: true,
    },
    mostUsedHours: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      view: false,
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {
    async validations() {
      for (let item of this.mostUsedDays) {
        if (item.day.length > 0) {
          this.view = true;
          break;
        }
      }
    },
  },
  created() {
    this.validations();
  },
};
</script>

<style scoped>
.most-hours {
  color: black !important;
}
.mosts {
  width: 328px;
}
.off_op {
  opacity: 0.5;
}
.w-10\/12 {
  width: 92.333333%;
}
.mostUsedDays-box .base-box {
  border-radius: 6px;
  height: 100px;
}
</style>
