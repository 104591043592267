var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 mt-5" },
    _vm._l(_vm.reactions, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "d-flex align-items-center justify-content-between mb-4",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-1/6 d-flex align-items-center justify-content-start",
            },
            [
              _c("span", { staticClass: "leading-none text-capitalize" }, [
                _c("p", { staticClass: "m-0 text-xs" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-3/6 position-relative d-flex justify-content-center align-items-center",
            },
            [
              _c("div", {
                staticClass: "position-relative z-0 bg-mainbg h-2 w-100",
              }),
              _c("div", {
                staticClass: "position-absolute h-2 bg-primary top-0 left-0",
                style: "width: " + item.pctValue + "%; z-index :2",
              }),
            ]
          ),
          _c("span", { staticClass: "d-inline-block w-1/6 text-sm w-" }, [
            _vm._v(_vm._s(item.level)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }