<template>
  <div class="w-100 pt-4 d-flex flex-wrap">
    <audience-total-chart
      :series="audienceDetails.acumulatedAudienceGraph"
      :categories="audienceDetails.chartCategories"
    />

    <bar-graph
      v-if="audienceDetails.audienceCount.length > 0"
      class="font-weight-bold pl-lg-3 pl-xl-4"
      :socialData="audienceDetails.audienceCount"
      title="Total Audience"
      :tooltipMsg="'Total amount of current audience'"
    />
    <img
      v-else
      class="w-4/12"
      src="@/assets/images/off-total-audience.png"
      style="opacity: 0.5; height: 30%"
      alt=""
    />
    <div class="w-100 h-16"></div>
    <audience-new-chart
      :series="audienceDetails.newAudienceGraph"
      :categories="numFormatter(audienceDetails.chartCategories)"
    />

    <div class="w-4/12 pl-lg-3 pl-xl-4 totalAudience">
      <div class="mb-4">
        <div class="d-flex align-items-center">
          <span
            class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
            >Total new Audience
          </span>
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content:
                'Total current amount of new audience per social network',
            }"
          ></i>
        </div>
      </div>
      <div
        class="w-100 d-flex flex-wrap justify-content-between text-white text-center mb-5"
      >
        <div
          class="w-1/2 mb-3"
          v-for="(account, index) in audienceDetails.audienceCount"
          :key="index"
        >
          <div :class="`w-10/12 bg-${account.rrss} p-xl-4 p-lg-3 base-box`">
            <p class="ellipsisBase">
              <i :class="`fab fa-${account.rrss}`"></i>
              <small class="ml-2 text-xs">{{ account.name }}</small>
            </p>
            <p>
              <span class="text-lg">{{
                numFormatter(account.newAudience)
              }}</span>
              <i :class="`fa fa-sort-up`"></i>
            </p>
          </div>
        </div>
        <div
          v-if="audienceDetails.audienceCount.length === 0"
          class="image-container-off"
        >
          <img
            class="opacity-50"
            src="@/assets/images/off-total-new-audience.png"
            alt=""
          />
          <div class="image-container-off__message">
            <div>{{ titleImgOff }}</div>
              {{ msgImgOff }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarGraph from "../shared/BarGraph.vue";
import AudienceNewChart from "./AudienceNewChart.vue";
import AudienceTotalChart from "./AudienceTotalChart.vue";
import {
 mapGetters,
 Helpers
} from "@isc/styleguide";

export default {
  name: "audience",
  components: {
    AudienceTotalChart,
    AudienceNewChart,
    BarGraph,
  },
  props: {
    rangeDate: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  created() {},
  methods: {},
  computed: {
    ...mapGetters({ 
      audienceDetails: "overview/account/getAudienceDetails", 
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
};
</script>

<style scoped>
.m0-childs p {
  margin: 0;
}
.w-10\/12 {
  width: 92.333333%;
}
.ellipsisBase {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.totalAudience .base-box:nth-child(2) {
  margin-right: 0;
}
.totalAudience .base-box {
  border-radius: 6px;
  height: 100px;
}
</style>
