<template>
  <div class="w-100">
    <total-followers-chart
      :series="detail.totalFollowers"
      :total="detail.audienceTotals.total"
      :boolViewData="detail.boolViewDataSuscribers"
      :categories="categories"
    />
    <div class="w-100 h-20"></div>
    <new-followers-chart
      :series="detail.newFollowers"
      :total="detail.audienceTotals.new"
      :average="detail.audienceTotals.newAverage || 0"
      :categories="categories"
    />
  </div>
</template>

<script>
import NewFollowersChart from "./NewFollowersChart.vue";
import TotalFollowersChart from "./TotalFollowersChart.vue";

export default {
  components: { NewFollowersChart, TotalFollowersChart },
  props: {
    detail: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
