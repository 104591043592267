var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "variationItem-box" }, [
    _c(
      "div",
      {
        staticClass:
          "variationItem-box__item w-100 d-flex flex-column position-relative",
        class: "bg-" + _vm.backgrounColor,
        style:
          "" +
          (_vm.backgrounColor !== "white"
            ? "color : white"
            : "color : #2c3852"),
      },
      [
        _c("i", {
          staticClass: "position-absolute icon-i",
          class: ["fab", "fa-" + _vm.rrss, "fa-lg"],
        }),
        _c(
          "div",
          { staticClass: "variationItem-box__item__info d-flex flex-column" },
          [
            _c(
              "span",
              { staticClass: "text-capitalize" },
              [
                _vm._v(_vm._s(_vm.title) + " "),
                _c("i", {
                  staticClass:
                    "fa fa-info-circle variationItem-box__item__icon-info",
                  style:
                    "" +
                    (_vm.title !== "audience"
                      ? "color : #a2acc4"
                      : "color : white"),
                  attrs: { id: _vm.title + "-icon", "aria-hidden": "true" },
                }),
                _c("CpTooltipWhiteCustom", {
                  attrs: {
                    target: _vm.title + "-icon",
                    title: _vm.title + "-icon",
                    message: "Variation: Start date vs. end date.",
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "font-weight-bold",
                style:
                  "" +
                  (_vm.backgrounColor !== "white"
                    ? "color : white"
                    : "color : #2c3852"),
              },
              [_vm._v(_vm._s(_vm.numFormatter(_vm.cant)))]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "w-100 variationItem-box__item__data d-flex justify-content-between align-items-baseline",
          },
          [
            _c(
              "span",
              {
                staticClass: "variationItem-box__item__data__name",
                style:
                  "" +
                  (_vm.backgrounColor !== "white"
                    ? "color : white"
                    : "color : #626b86"),
              },
              [_vm._v("Variation")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "variationItem-box__item__data__level d-flex align-items-start",
              },
              [
                _c(
                  "span",
                  {
                    style:
                      "" +
                      (_vm.backgrounColor !== "white"
                        ? "color : white"
                        : "color : #626b86"),
                  },
                  [_vm._v(_vm._s(_vm.level) + "%")]
                ),
                _c("i", {
                  staticClass: "iconLevel",
                  class:
                    "fa fa-sort-" +
                    (_vm.up ? "up text-success" : "down text-danger"),
                  style:
                    "" +
                    (_vm.backgrounColor !== "white"
                      ? "color : white !important"
                      : ""),
                }),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "position-relative w-100" }, [
          _c("div", {
            staticClass: "position-relative z-0 h-2",
            staticStyle: { "background-color": "#f3f4f7", opacity: "0.6" },
          }),
          _c("div", {
            class: [
              "position-absolute",
              "h-2",
              "top-0",
              _vm.title == "audience" ? "bg-exception" : "bg-" + _vm.rrss,
            ],
            style:
              (_vm.backgrounColor !== "white" ? "color : white" : "") +
              ";width: " +
              _vm.level +
              "%; z-index: 2;",
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }