<template>
  <div>
    <highcharts :options="getOptions()" />
  </div>
</template>
<script>
export default {
  props: {
    series: {
      type: [ Object,  Array ],
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  created() {
  },
  data() {
    return {};
  },
  methods: {
    getOptions() {
      var _categories = this.categories
      try {
        let options = {
          chart: { type: "column" },
          title: { text: "" },
          legend: { align: "right" },
          series: [],
          xAxis: {
            categories: _categories,
            crosshair: true,
            min: 0,
            tickWidth: 0,
             title: {
            text: 'Days',
           },
           labels: {
              formatter: null,
            autoRotation: false,
            rotation: 0,
            style: { color: "#ababab" },
          },
          },
          yAxis: [
            {
              allowDecimals: false,
              label: { align: "left" },
              min: 0,
              title: { text: "Interactions" },
            },
            {
              allowDecimals: false,
              label: { align: "right" },
              min: 0,
              title: { text: "Post" },
              opposite: true,
            },
          ],
          credits: { enabled: false },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          legend: {
            layout: "horizontal",
            align: "right",itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          navigation: {
            buttonOptions: {
              enabled: false,
            },
          },
        };
        options.series = this.series;
        return options;
      } catch (error) {
        console.error("sucedio un error en getOptions: ", error);
        return {};
      }
    },
  },
};
</script>
