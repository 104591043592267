<template>
  <div class="w-8/12 pl-3 pr-5">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >Daily engaged users (%)
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content:
              'Total percentage of daily engagement ((Engaged Users)/(Reach))* 100',
          }"
        ></i>
      </div>
    </div>
    <highcharts
      v-if="series[0].data.reduce((a, b) => (a += b)) > 0"
      :options="setDataDayliEngaged()"
    />
    <div
      v-if="series[0].data.reduce((a, b) => (a += b)) == 0"
      class="image-container-off"
    >
      <img
        src="@/assets/images/off-daily-engagement.png"
        style="opacity: 0.5; width: 100%"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
  </div>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";

export default {
  data() {
    return {
      secondOptions: {},
    };
  },
  props: {
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {
    setDataDayliEngaged() {
      try {
        let secondOptions = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "spline",
            marginTop: 10,
            height: 360,
            marginBottom: 80,
          },
          title: { text: null, enabled: false },
          credits: { text: "" },
          xAxis: {
            title: {
              enabled: true,
              text: "Days",
            },
            lineColor: "#D6DBDF",
            categories: [""],
            tickWidth: 0,
            labels: {
              autoRotation: false,
              rotation: 0,
              style: { color: "#ababab" },
            },
          },
          yAxis: [
            {
              title: { enabled: true, text: "Engagement Rate" },
              min: 0,
              labels: { style: { color: "#ababab" } },
            },
            {
              title: { enabled: false, text: "", align: "middle" },
              min: 0,
              opposite: true,
            },
          ],
          tooltip: {
            formatter: function () {
              let text =
                "<table class='table_tooltip' border=0 cellspacing=0 cellpadding='5px' style=''>";
              text +=
                "<tr><td class='tp-table' colspan='2' style=''>" +
                this.x +
                "</td></tr>";
              for (let i = 0; i < this.points.length; i++) {
                text += "<tr><td style='padding:4px;padding-left:8px;'>";
                text +=
                  "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                  this.points[i].series.color +
                  " !important'></i> " +
                  this.points[i].series.name;
                text +=
                  "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
                text += Helpers.numberWithCommas(this.points[i].y);
                text += "</td></tr>";
              }
              text += "</table>";
              return text;
            },
            shared: true,
            padding: 0,
            backgroundColor: "#ffffff",
            useHTML: true,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            crosshairs: true,
          },
          plotOptions: {
            column: { pointWidth: 5 },
            bar: { pointWidth: 5 },
            area: {
              marker: { enabled: false },
              connectNulls: true,
            },
            line: {
              marker: { lineWidth: 1, enabled: true },
              connectNulls: true,
            },
            spline: { marker: { lineWidth: 1, radius: 3, symbol: "circle" } },
            series: { pointPadding: 0, groupPadding: 0 },
          },
          exporting: { enabled: false },
          labels: {
            items: [
              {
                style: { left: "40px", top: "8px", color: "red" },
              },
            ],
          },
          legend: {
            align: "right",
            x: -11,
            y: 10,
            verticalAlign: "bottom",
            borderWidth: 0,
            shadow: false,
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          series: [],
        };
        secondOptions.series = this.series;
        secondOptions.xAxis.categories = this.categories;
        return secondOptions;
      } catch (error) {
        return {};
      }
    },
  },
  created() {},
};
</script>

<style></style>
