var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 py-4" }, [
    _c("div", { staticClass: "d-flex" }, [
      _vm._m(0),
      _c("div", { staticClass: "w-1/3" }, [
        _c("div", { staticClass: "position-relative mb-2" }, [
          _c(
            "div",
            {
              staticClass:
                "position-absolute d-flex align-items-center align-middle content-center",
              staticStyle: {
                width: "calc(100% - 2.5rem - 1rem)",
                top: "50%",
                transform: "translate(-50%, -50%)",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 bg-skygray rounded align-items-center align-middle align-center flex-1",
                },
                [
                  _c("div", {
                    staticClass: "w-0 bg-primary py-1 rounded",
                    style: "width: " + _vm.levels.first + "%;",
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-10 h-10 mx-auto rounded-circle text-lg d-flex align-items-center transition",
              class: [
                _vm.step >= 2
                  ? "bg-primary text-white"
                  : "bg-white text-semidark border-2 border-skygray",
              ],
            },
            [_c("span", { staticClass: "text-center w-100" }, [_vm._v("2")])]
          ),
        ]),
        _c("div", { staticClass: "text-xs text-center" }, [
          _vm._v("Add Channels"),
        ]),
      ]),
      _c("div", { staticClass: "w-1/3" }, [
        _c("div", { staticClass: "position-relative mb-2" }, [
          _c(
            "div",
            {
              staticClass:
                "position-absolute d-flex align-center align-items-center align-middle content-center",
              staticStyle: {
                width: "calc(100% - 2.5rem - 1rem)",
                top: "50%",
                transform: "translate(-50%, -50%)",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 bg-skygray rounded align-items-center align-middle align-center flex-1",
                },
                [
                  _c("div", {
                    staticClass: "w-0 bg-primary py-1 rounded",
                    style: "width: " + _vm.levels.second + "%;",
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-10 h-10 mx-auto rounded-circle text-lg d-flex align-items-center transition",
              class: [
                _vm.step === 3
                  ? "bg-primary text-white"
                  : "bg-white text-semidark border-2 border-skygray",
              ],
            },
            [_c("span", { staticClass: "text-center w-100" }, [_vm._v("3")])]
          ),
        ]),
        _c("div", { staticClass: "text-xs text-center" }, [_vm._v("Confirm")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-1/3" }, [
      _c("div", { staticClass: "position-relative mb-2" }, [
        _c(
          "div",
          {
            staticClass:
              "w-10 h-10 mx-auto bg-primary rounded-circle text-lg text-white d-flex align-items-center",
          },
          [
            _c("span", { staticClass: "text-center text-white w-100" }, [
              _vm._v("1"),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "text-xs text-center" }, [_vm._v("Set Name")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }