var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _vm.detail
        ? _c(
            "div",
            { staticClass: "w-100 detail-content-box" },
            [
              _c("profile-bar", {
                attrs: {
                  detail: _vm.detail,
                  accountName: _vm.detail.name,
                  rrss: "linkedin",
                  logo: _vm.detail.logo,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "w-100 d-flex mb-4 box-card-channel",
                  class: _vm.flexClass,
                },
                _vm._l(_vm.detail.variations, function (variation, index) {
                  return _c("variation-item", {
                    key: index,
                    staticClass: "box-card-channel__item",
                    attrs: {
                      backgrounColor: variation.background,
                      title: variation.title,
                      level: variation.level,
                      cant: variation.cant,
                      up: variation.up,
                      rrss: "linkedin",
                    },
                  })
                }),
                1
              ),
              _c("tabs", {
                attrs: { cant: 3, titles: _vm.tabs },
                on: { change: _vm.changeTab },
              }),
              _c(
                "div",
                { staticClass: "w-100 bg-white p-xl-5 p-lg-3" },
                [
                  _vm.activeTab === 0
                    ? _c("audience", {
                        attrs: {
                          detail: _vm.detail.audience,
                          categories: _vm.detail.chartCategories,
                        },
                      })
                    : _vm.activeTab === 1
                    ? _c("posts", {
                        attrs: {
                          detail: _vm.detail.posts,
                          categories: _vm.detail.chartCategories,
                        },
                      })
                    : _c("interactions", {
                        attrs: {
                          detail: _vm.detail.interactions,
                          categories: _vm.detail.chartCategories,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }