<template>
  <div class="boxMain overview-boxMain" id="accountbox">
    <div class="first-block eye-layer">
      <div
        :class="['eye', showeye == true ? 'eyesee' : '']"
        @mouseover="changeenter(1)"
        @mouseleave="changeenter(0)"
        @click="navigateTo"
      >
        <i class="fa fa-eye fa-6x"></i>
      </div>
      <div class="imgBox">
        <img :src="accountData.image" @error="errorUrlImage" />
      </div>
      <div class="data">
        <div class="general">
          <div class="general__social-icon iconCustomByCardRSS">
            <i
              v-for="(social_network, index) in accountData.social_networks"
              :class="['fab', `fa-${social_network}`]"
              :title="social_network"
              :key="index"
            ></i>
          </div>
          <span class="general__title">{{ accountData.title }}</span>
          <span class="general__level">{{
            numFormatter(accountData.audienceLevel)
          }}</span>
          <div class="d-flex container-audience">
            <small class="general__audience">Audience</small>
            <!-- <i :id="`generalAudience${accountIndex}`" class="fa fa-info-circle iconInfoInter" aria-hidden="true"></i>
            <CpTooltipWhiteCustom :target="`generalAudience${accountIndex}`" :title="`generalAudience${accountIndex}`" message="Update: Every 24 hours"></CpTooltipWhiteCustom> -->
          </div>
        </div>
      </div>
    </div>
    <div class="second-block">
      <div class="info">
        <span>
          Posts
          <div v-if="accountData.social_networks.length > 0" class="hide">
            <i
              class="ml-1 fa fa-question-circle cursor-pointer"
              v-b-popover.top="
                'Sum of Facebook Posts, Youtube videos, Tweets ,Instagram posts and LinkedIn posts'
              "
              :title="'Posts ' + fechamonth + ' - ' + fechaAyer"
            ></i>
          </div>
        </span>
        <span class="general__title font-weight-bold">{{
          numFormatter(accountData.totalPosts)
        }}</span>
      </div>
      <div class="info">
        <span>
          Engaged Users
          <i class="fab fa-facebook" title="Facebook"></i>
          <div v-if="accountData.social_networks.length > 0" class="hide">
            <i
              class="ml-1 fa fa-question-circle cursor-pointer"
              v-b-popover.top="'Sumf of Facebook Engaged Users'"
              :title="'Engaged Users ' + fechamonth + ' - ' + fechaAyer"
            ></i>
          </div>
        </span>
        <span class="general__title font-weight-bold"
          >{{ numFormatter(accountData.engaged_users) }}
        </span>
      </div>
      <div class="info">
        <div class="d-flex containerInteractions">
          <span>
            Interactions
            <!-- <div v-if="accountData.social_networks.length > 0" class="hide">
              <i
                class="ml-1 fa fa-question-circle cursor-pointer"
                v-b-popover.top="
                  'Sum of likes, comments, shares, favorites, dislikes, retweets'
                "
                :title="'Interactions ' + fechamonth + ' - ' + fechaAyer"
              ></i>
            </div> -->
          </span>
          <i
            :id="`interactions${accountIndex}`"
            class="fa fa-info-circle iconInfoInter"
            aria-hidden="true"
          ></i>
          <CpTooltipDoubleCustom
            :target="`interactions${accountIndex}`"
            :tooltipTitle="`interactions${accountIndex}`"
            title="Update: Every 30 minutes (if the campaign is active)."
            subTitle1="For posts that are not linked to a campaign the update is every 24 hours."
            subTitle2="Sum of likes, comments, shares, favorites, dislikes, retweets."
            heightSubTitle1="89px"
            heightSubTitle2="57px"
          >
          </CpTooltipDoubleCustom>
        </div>
        <span class="general__title font-weight-bold"
          >{{ numFormatter(accountData.totalInteractions) }}
        </span>
      </div>
    </div>
    <div class="third-block">
      <b-button squared variant="mainbg" class="w-btn" @click="navigateTo"
        ><i class="far fa-eye"></i
      ></b-button>
      <b-button squared variant="mainbg" @click="modal = !modal" class="w-btn"
        ><i class="fas fa-edit"></i
      ></b-button>
      <b-button
        squared
        variant="mainbg"
        class="w-btn"
        @click="openModalDelete(accountData.title)"
        ><i class="far fa-trash-alt"></i
      ></b-button>
    </div>
    <!-- MODAL EDIT ACCOUNT -->
    <b-modal
      v-model="modal"
      size="lg"
      title="Edit Account"
      header-class="border-0 px-5 title-edit-account"
      hide-footer
      :no-enforce-focus="true"
      :no-close-on-backdrop="true"
    >
    <template v-slot:modal-header-close>
      <CloseButton 
        class="p-0"
        :fill="customFill"
      />
    </template>
      <edit-account-modal :accountData="accountData" @onClose="confirmUpdate" />
    </b-modal>
    <!-- ALERT CODE MODAL -->
    <IscModalAlerts
      v-model="showAlertCode"
      :icon="iconCode"
      :iconColor="iconColorCode"
      :title="titleCode"
      :paragraph="paragraphCode"
      :buttonMessage="buttonMessageCode"
      @sendResult="resultModalAlertsCode"
      :noCloseBackdrop="true"
      :refresh="true"
    />
    <!-- MODAL ALERT GLOBAL -->
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
      @input="closeIconModal"
    >
    </IscModalAlerts>
    <!-- <b-modal
      v-model="deleteModal"
      centered
      title="Atención!! Confirmar Eliminación"
      @ok="confirmDelete"
      :header-bg-variant="'danger'"
      :header-text-variant="'white'"
    >
      <p class="my-4">
        Está seguro que desea eliminar la cuenta {{ accountData.title }}?
      </p>
    </b-modal> -->
  </div>
</template>

<script>
import EditAccountModal from "./modal-contents/editAccount/Main";
import {
  mapMutations,
  IscModalAlerts,
  AlertSetting,
  CodeToken,
  CpTooltipWhiteCustom,
  CpTooltipDoubleCustom,
  mapActions,
  CloseButton,
  Helpers
} from "@isc/styleguide";
import moment from "moment";

export default {
  components: {
    EditAccountModal,
    CpTooltipWhiteCustom,
    CpTooltipDoubleCustom,
    IscModalAlerts,
    CloseButton,
  },
  name: "AccountBox",
  props: {
    accountData: {
      required: true,
      type: Object,
    },
    accountIndex: {
      type: Number,
      default: 0,
    },
  },
  mixins: [ AlertSetting, CodeToken],
  data() {
    return {
      modal: false,
      isDeleteAccount: false,
      fechaAyer: null,
      fechamonth: null,
      showeye: false,
      customFill: "#000",
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  created() {
    this.calculatefechas();
  },
  methods: {
    ...mapActions({
      deleteAccount: "overview/account/DELETE_ACCOUNT",
    }),
    ...mapMutations({
      setLoader: "global/helper/setLoader",
    }),
    navigateTo() {
      this.$router
        .push({
          name: "overview-accounts-detail",
          query: { account: this.accountData.id },
        })
        .catch((_) => {});
    },
    openModalDelete(accountName) {
      this.warningModal(
        `Are you sure to delete this account "${accountName}"?`
      );
      this.isDeleteAccount = true;
    },
    async confirmDelete() {
      try {
        this.setLoader(true);
        const respDeleteAccount = await this.deleteAccount({ account_id: this.accountData.id });
        const resp = this.validationCodeToken(respDeleteAccount.data);
        if (!resp) return;
        
        const _accountsDeleted = resp.rowsAffected[0];
        if (_accountsDeleted === -1) {
          this.$emit("error");
        } else {
          this.$emit("ok", _accountsDeleted);
          this.$emit("reload");
        }
        
      } catch (error) {
        console.log("[Error] in function confirmDelete ", error);
        let message =
          error?.response?.data.message ?? "Server fails to delete an account.";
        this.errorModal(message);
      } finally {
        this.setLoader(false);
      }
    },
    async resultModalAlerts(value) {
      this.showAlert = value;
      if (this.isDeleteAccount) {
        await this.confirmDelete();
        this.isDeleteAccount = false;
      }
    },
    closeIconModal() {
      this.isDeleteAccount = false;
    },
    confirmUpdate(result) {
      this.setLoader(true);
      if (result === -1) {
        this.$emit("error");
      } else {
        this.$emit("okUpdate", result);
        this.$emit("reload");
        this.modal = false;
      }
    },
    calculatefechas() {
      let datenow = new Date();
      let temp = new Date();
      let fechaa = datenow.setDate(datenow.getDate() - 1);
      let fechamonth = temp.setMonth(temp.getMonth() - 1);
      this.fechaAyer = moment(fechaa).format("DD/MM/YYYY");
      this.fechamonth = moment(fechamonth).format("DD/MM/YYYY");
    },
    changeenter(value) {
      if (value == 1) {
        this.showeye = true;
      } else {
        this.showeye = false;
      }
    },
    errorUrlImage(event) {
      // changing for default image.
      event.target.src = require("../assets/images/off-account_150x150.png");
    },
  },
};
</script>
<style>
/*clase unicamente para el btn viene de assets/third party/vue tailwind/button*/
.boxMain .general__title {
  font-size: 18px;
}
.boxMain .general__level {
  font-size: 32px;
}
.boxMain .general__audience {
  font-size: 10px;
  /* position: relative;
  top: -10px; */
}
.boxMain .w-btn {
  width: 32.5%;
  color: #5d6b88;
}
.boxMain .w-btn:hover {
  background-color: var(--primary) !important;
  color: white;
}
.boxMain {
  width: 49%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  font-family: "Oxygen" sans-serif !important;
}
.boxMain span .hide {
  display: none;
}
.boxMain .imgBox {
  width: 150px;
  height: 150px;
}
.boxMain .imgBox img {
  width: 100%;
  height: 100%;
}
.boxMain span:hover .hide {
  display: inline-block;
}
.boxMain .first-block {
  background-color: var(--primary);
  width: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  height: 150px;
}
.boxMain .first-block .eye {
  transition: all ease-in 0.2s;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.67);
}
.firsteye:hover {
  opacity: 60% !important;
}
.boxMain .first-block .data {
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
}
.boxMain .first-block .data .general {
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  height: 150px;
  padding: 15px 20px 15px 15px;
}
.boxMain .iconCustomByCardRSS {
  display: flex;
  justify-content: flex-end;
}
.iconCustomByCardRSS i {
  color: #eee !important;
  opacity: 0.6;
  size: 18px;
  margin-left: 6px;
}
.boxMain .iconCustomByCardRSS i:first-child {
  margin-left: 0;
}
.boxMain .iconCustomByCardRSS i:before {
  font-size: 22px !important;
}
.boxMain .iconCustomByCardRSSPart2 {
  color: #eee;
  opacity: 60% !important;
}

.boxMain .second-block {
  width: 100%;
  display: flex;
  background-color: white;
  color: #566b8a;
}
.boxMain .general__rrss {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.boxMain .general__rrss span {
  font-size: 13px;
}
.boxMain .second-block .info {
  padding: 1rem 0;
  width: 33.33333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.boxMain .third-block {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0.3rem;
  background-color: white;
}
#accountbox .data {
  background: none !important;
}
.boxMain .text-xl {
  font-size: 18px;
  font-weight: bold;
}

.eyesee {
  opacity: 0.6 !important;
}
.boxMain .first-block .data .rrss {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
}
@media (max-width: 1024px) {
  .boxMain .first-block {
    height: 130px !important;
  }
  .boxMain .second-block .info {
    padding: 10px 0 !important;
  }
  .boxMain .imgBox {
    width: 130px;
    height: 130px;
  }
  .boxMain .general__title {
    font-size: 16px;
    margin-bottom: 0;
  }
  .boxMain .general__level {
    font-size: 28px !important;
    top: -5px;
  }
  .boxMain .general__audience {
    top: -8px;
  }
  .boxMain .first-block .data {
    width: calc(100% - 130px) !important;
  }
  .boxMain .general__rrss {
    bottom: 15px;
    right: 15px;
  }
  .boxMain .first-block .data .general {
    height: auto;
    padding: 10px 15px 10px 10px;
  }
  .boxMain .iconCustomByCardRSS i:before {
    font-size: 18px !important;
  }
  .boxMain .text-xl {
    font-size: 16px !important;
  }
}
.iconInfoInter {
  font-size: 10px;
  padding-left: 0.2rem;
}
.container-audience {
  align-items: center;
}
.containerInteractions {
  align-items: center;
}

.containerInteractions i {
  font-size: 12px;
}
.title-edit-account .modal-title{
  font-size: 1.25rem;
  padding-top: 7px;
  font-weight: 500;
}
.title-edit-account {
  height: 67px;
}
.title-edit-account .close:not(:disabled):not(.disabled):hover {
 opacity: 0.5;
}
.title-edit-account .close:not(:disabled):not(.disabled):focus{
  opacity: 0.5;
}
</style>
