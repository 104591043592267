var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-4/12" }, [
    _c("div", { staticClass: "mb-4" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c(
          "span",
          {
            staticClass:
              "font-weight-bold text-capitalize text-lsm text-secondary mr-2",
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
        _c("i", {
          directives: [
            {
              name: "b-popover",
              rawName: "v-b-popover.hover",
              value: {
                variant: "primary",
                content: _vm.tooltipMsg,
              },
              expression:
                "{\n          variant: 'primary',\n          content: tooltipMsg,\n        }",
              modifiers: { hover: true },
            },
          ],
          staticClass: "fa fa-info-circle text-skygray",
        }),
      ]),
    ]),
    _vm.socialData.reduce(function (a, b) {
      return (a += b.total)
    }, 0) > 0
      ? _c(
          "div",
          { staticClass: "w-100 text-sm" },
          _vm._l(_vm.socialData, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "block-rrss-info d-flex align-items-start justify-content-between mb-3",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "st-rrss d-flex align-items-start justify-content-start",
                  },
                  [
                    _vm.rrss
                      ? _c("i", {
                          class:
                            "fab fa-" +
                            item.rrss +
                            " pt-1 mr-2 text-" +
                            item.rrss,
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      { staticClass: "leading-none text-capitalize" },
                      [
                        _c(
                          "p",
                          { staticClass: "m-0 text-xs text-color-text" },
                          [_vm._v(_vm._s(_vm.fixNameSocialNetwork(item.rrss)))]
                        ),
                        item.name
                          ? _c(
                              "small",
                              {
                                staticClass: "smallName text-xxs text-skygray",
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "d-flex col pl-0 pr-0 pt-1" }, [
                  _c(
                    "div",
                    { staticClass: "rrss-perc position-relative w-100" },
                    [
                      _c("div", {
                        staticClass:
                          "position-relative z-0 bg-mainbg h-2 w-100",
                      }),
                      _c("div", {
                        class:
                          "position-absolute h-2 bg-" +
                          (!_vm.bytype ? item.rrss : "primary") +
                          " top-0 left-0",
                        style:
                          "width: " +
                          item.total +
                          "%; z-index :2; max-width : 100%",
                      }),
                    ]
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass:
                      "rrss-total text-xs text-color-text font-weight-bold pr-lg-3 pr-xl-0",
                  },
                  [_vm._v(_vm._s(_vm.numFormatter(item.total)))]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.socialData.reduce(function (a, b) {
      return (a += b.total)
    }, 0) == 0 && _vm.bytype == false
      ? _c("div", { staticClass: "image-container-off" }, [
          _c("img", {
            staticClass: "opacity-50",
            attrs: {
              src: require("@/assets/images/off-interaction-social-network.png"),
              alt: "",
            },
          }),
          _c(
            "div",
            { staticClass: "image-container-off__message opacity-required-35" },
            [
              _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
              _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
            ]
          ),
        ])
      : _vm._e(),
    _vm.socialData.reduce(function (a, b) {
      return (a += b.total)
    }, 0) == 0 && _vm.bytype == true
      ? _c("div", { staticClass: "image-container-off" }, [
          _c("img", {
            staticClass: "opacity-50",
            attrs: {
              src: require("@/assets/images/images-off-interactions-bytype.png"),
              alt: "",
            },
          }),
          _c(
            "div",
            { staticClass: "image-container-off__message opacity-required-35" },
            [
              _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
              _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }