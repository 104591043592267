var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.interactionsDetail
    ? _c(
        "div",
        { staticClass: "w-100 pt-4 d-flex flex-wrap content-interaction-tab" },
        [
          _c("interactions-total-chart", {
            attrs: {
              series: _vm.interactionsDetail.interactionsByDay,
              chartCategories: _vm.interactionsDetail.chartCategories,
            },
          }),
          _c("div", { staticClass: "w-4/12 pl-xl-4 pl-lg-2 pr-2" }, [
            _c(
              "div",
              { staticClass: "w-100 rounded box-total bg-primary p-3" },
              [
                _c("p", { staticClass: "text-white mb-2 d-flex flex-column" }, [
                  _c("span", { staticClass: "text-lsm" }, [
                    _vm._v("Total Interactions"),
                  ]),
                  _c("span", { staticClass: "text-3-5xl font-weight-bold" }, [
                    _vm._v(
                      _vm._s(_vm.numFormatter(_vm.interactionsDetail.total))
                    ),
                  ]),
                ]),
              ]
            ),
            _c("br"),
            _c(
              "div",
              { staticClass: "col px-0 bar-graph-st" },
              [
                _c("bar-graph", {
                  staticClass: "font-weight-bold",
                  attrs: {
                    socialData: _vm.interactionsDetail.socialsTotal,
                    title: "Interactions by social network",
                    tooltipMsg:
                      "Total of interactions of all social networks per type",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "w-100 h-16" }),
          _c("interactions-by-type", {
            staticClass: "font-weight-bold",
            attrs: {
              series: _vm.interactionsDetail.interactionsByType,
              title: "Interactions by day of the week",
            },
          }),
          _c("bar-graph", {
            staticClass: "font-weight-bold pl-xl-4 pl-lg-2 pr-2 ",
            attrs: {
              rrss: false,
              socialData: _vm.interactionsDetail.typesTotal,
              bytype: true,
              title: "Interactions by type",
              tooltipMsg:
                "Total of interactions of all social networks per type",
            },
          }),
          _c("div", { staticClass: "w-100 h-16" }),
          _c("post-resume", { attrs: { posts: _vm.interactionsDetail.posts } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }