var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modaltokenexp" },
    [
      _c(
        "b-modal",
        {
          staticClass: "modal",
          attrs: {
            "no-close-on-backdrop": "",
            id: "modaltoken",
            title: "BootstrapVue",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "h4",
                    {
                      staticClass: "modal-title",
                      staticStyle: {
                        color: "white !important",
                        "padding-left": "35px",
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                      _vm._v(
                        " Los accesos a las siguientes páginas han vencido "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "enlace",
                      staticStyle: {
                        "background-color": "#00a5ff",
                        color: "white",
                        width: "155px",
                        height: "35px",
                      },
                      attrs: { role: "link" },
                      on: { click: _vm.getPageSettings },
                    },
                    [_vm._v(" Volver a registrar ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-container", [
            _c(
              "ol",
              {
                staticClass: "lista",
                staticStyle: {
                  "overflow-y": "auto",
                  "max-height": "110px",
                  "margin-top": "20px",
                  "margin-bottom": "20px",
                },
              },
              _vm._l(_vm.cuentas, function (item) {
                return _c(
                  "li",
                  {
                    key: item.idCuentaRedsocial,
                    staticStyle: { "margin-bottom": "10px" },
                  },
                  [
                    _c("i", {
                      class: "fab fa-" + item.nomRedSocial + " fbco",
                      staticStyle: {
                        "font-size": "18px",
                        "padding-right": "10px",
                      },
                    }),
                    _vm._v(_vm._s(item.nomUsuario) + " "),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }