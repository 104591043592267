<template>
  <div class="w-100">
    <posts-by-day :series="detail.totals.graph" :boolViewData="detail.totals.boolViewData" :categories="categories" :total="detail.totals.total" :postIndicators="detail.postIndicators"/>
    <div class="w-100 h-20"></div>
    <top-posts :series="detail.byDay" />
  </div>
</template>

<script>
import PostsByDay from "./PostsByDay.vue";
import TopPosts from "./TopPosts.vue";

export default {
  components: { PostsByDay, TopPosts },
  props: {
    detail: {
      required: true,
      type: Object,
    },
    categories: {
      required: true,
      type: Array,
    }
  },
};
</script>

<style></style>
