<template>
  <div class="w-100">
    <total-posts :series="detail.totals.graph" :total="detail.totals.total"  :categories="categories" :boolViewData="detail.totals.boolViewData" />
    <div class="w-100 h-20"></div>
    <posts-by-day :series="detail.byDay.graph" :day="detail.byDay.mostUsedDay" :hour="detail.byDay.mostUsedHour" :postIndicators="detail.postIndicators"/>
  </div>
</template>

<script>
import PostsByDay from "./PostsByDay.vue";
import TotalPosts from "./TotalPosts.vue";

export default {
  components: { PostsByDay, TotalPosts },
    props : {
    detail : {
      type : Object,
      required : true
    },
    categories : Array,
  }
};
</script>

<style>
</style>