<template>
  <b-row no-gutters class="mt-2">
    <b-col cols="8 pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >Accumulated Subscribers
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content: 'Accumulated Subscribers youtube',
            }"
          ></i>
        </span>
      </div>
      <div
        v-if="!boolViewData"
        class="image-container-off"
      >
        <img
          class="off_op"
          src="@/assets/images/images-off-yt-suscribers.png"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
      <highcharts v-else :options="getOptions()" />
    </b-col>
    <b-col cols="4" class="pl-5 pr-2">
      <div class="bg-youtube rounded p-4 text-white">
        <p>Subscribers</p>
        <p class="text-4xl font-weight-bold">
          {{ numFormatter(totals.subscribers) }}
        </p>
      </div>
      <div class="p-4">
        <p>New Subscribers</p>
        <p class="text-4xl font-weight-bold">
          {{ numFormatter(totals.newSubscribers) }}
        </p>
      </div>
      <div class="p-4">
        <p>Average New Subscribers</p>
        <p class="text-4xl font-weight-bold">
          {{ numFormatter(totals.AverageNewSubscribers) }}
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../../../util/config";
export default {
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  props: {
    series: {
      type: Array,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    boolViewData: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getOptions() {
      try {
        let options = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "spline",
            marginTop: 10,
            height: 360,
            marginBottom: 80,
          },
          title: { text: "", enabled: false },
          credits: { text: "" },
          xAxis: {
            title: {
              enabled: false,
              text: "",
            },
            lineColor: "#D6DBDF",
            categories: [],
            tickWidth: 0,
            labels: {
              autoRotation: false,
              rotation: 0,
              style: { color: "#ababab" },
            },
          },
          yAxis: [
            {
              allowDecimals: false,
              title: { text: "Subscribers", align: "middle" },
              min: 0,
              labels: { style: { color: "#ababab" } },
            },
            {
              allowDecimals: false,
              title: { text: "New Subscribers", align: "middle" },
              min: 0,
              opposite: true,
            },
          ],
          tooltip: {
            formatter: function () {
              let text =
                "<table class='table_tooltip' border=0 cellspacing=0 cellpadding='5px' style=''>";
              text +=
                "<tr><td class='tp-table' colspan='2' style=''>" +
                this.x +
                "</td></tr>";
              for (let i = 0; i < this.points.length; i++) {
                text += "<tr><td style='padding:4px;padding-left:8px;'>";
                text +=
                  "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                  this.points[i].series.color +
                  " !important'></i> " +
                  this.points[i].series.name;
                text +=
                  "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
                text += Helpers.numberWithCommas(this.points[i].y);
                text += "</td></tr>";
              }
              text += "</table>";
              return text;
            },
            shared: true,
            padding: 0,
            backgroundColor: "#ffffff",
            useHTML: true,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            crosshairs: true,
          },
          plotOptions: {
            column: { pointWidth: 5 },
            bar: { pointWidth: 5 },
            area: {
              marker: { enabled: false },
              connectNulls: true,
            },
            line: {
              marker: { lineWidth: 1, enabled: true },
              connectNulls: true,
            },
            spline: { marker: { lineWidth: 1, radius: 3, symbol: "circle" } },
            series: { pointPadding: 0, groupPadding: 0 },
          },
          exporting: { enabled: false },
          labels: {
            items: [
              {
                style: { left: "40px", top: "8px", color: "red" },
              },
            ],
          },
          legend: {
            align: "right",
            x: -11,
            y: 10,
            verticalAlign: "bottom",
            // floating: true,
            borderWidth: 0,
            shadow: false,
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          series: [],
        };
        options.series = this.series;
        options.xAxis.categories = this.categories;
        return options;
      } catch (error) {
        return options;
      }
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
};
</script>

<style>
.off_op {
  opacity: 0.5;
}
</style>
