var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mt-2", attrs: { "no-gutters": "" } },
    [
      _c(
        "b-col",
        { staticClass: "pl-3 pr-5", attrs: { cols: "8" } },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Tweets "),
              _c("i", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover",
                    value: {
                      variant: "primary",
                      content: "Posts published by day and hour",
                    },
                    expression:
                      "{\n            variant: 'primary',\n            content: 'Posts published by day and hour',\n          }",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "fa fa-info-circle text-skygray",
              }),
            ]),
          ]),
          !_vm.boolViewData
            ? _c("div", { staticClass: "image-container-off" }, [
                _c("img", {
                  staticClass: "off_op",
                  staticStyle: { opacity: "0.5", width: "100%" },
                  attrs: {
                    src: require("@/assets/images/image-off-graphpost-channel.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "image-container-off__message" }, [
                  _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                  _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                ]),
              ])
            : _c("highcharts", { attrs: { options: _vm.getOptions() } }),
        ],
        1
      ),
      _c("b-col", { staticClass: "pl-5 pr-2", attrs: { cols: "4" } }, [
        _c("div", { staticClass: "bg-twitter rounded p-4 text-white" }, [
          _c("p", [_vm._v("Most used time to tweet")]),
          _c("p", { staticClass: "text-2xl font-weight-light" }, [
            _vm._v(" " + _vm._s(_vm.postIndicators.horarioTiempo) + " "),
          ]),
        ]),
        _c("div", { staticClass: "rounded p-4" }, [
          _c("p", [_vm._v("Total Tweets")]),
          _c("p", { staticClass: "text-4xl font-weight-bold" }, [
            _vm._v(_vm._s(_vm.numFormatter(_vm.total))),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }