<template>
  <div class="w-100">
    <posts-by-day :series="detail.totals" :chartCategories="chartCategories" :postIndicators="detail.postIndicators"/>
    <div class="w-100 h-20"></div>
    <top-posts :series="detail.byDay" />
    <!-- --POST RESUME  -->

   <post-resume :posts="detail.posts"/>
  </div>
</template>

<script>
import PostsByDay from "./PostsByDay.vue";
import TopPosts from "./TopPosts.vue";
import PostResume from "./PostResume";

export default {
  components: { PostsByDay, TopPosts,PostResume },
  props: {
    detail: {
      required: true,
      type: Object,
    },
    chartCategories: {
      type: Array,
      required: true,
    }
  },
};
</script>

<style></style>
