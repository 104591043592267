<template>
  <div class="w-4/12">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
        >
          {{ title }}
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: tooltipMsg,
          }"
        ></i>
      </div>
    </div>
    <div
      class="w-100 text-sm"
      v-if="
        socialData.reduce((a, b) => {
          return (a += b.total);
        }, 0) > 0
      "
    >
      <div
        class="block-rrss-info d-flex align-items-start justify-content-between mb-3"
        v-for="(item, index) in socialData"
        :key="index"
      >
        <div class="st-rrss d-flex align-items-start justify-content-start">
          <i
            v-if="rrss"
            :class="`fab fa-${item.rrss} pt-1 mr-2 text-${item.rrss}`"
          ></i>
          <span class="leading-none text-capitalize">
            <p class="m-0 text-xs text-color-text">{{ fixNameSocialNetwork(item.rrss) }}</p>
            <small class="smallName text-xxs text-skygray" v-if="item.name">{{
              item.name
            }}</small>
          </span>
        </div>
        <div class="d-flex col pl-0 pr-0 pt-1">
          <div class="rrss-perc position-relative w-100">
            <div class="position-relative z-0 bg-mainbg h-2 w-100"></div>
            <div
              :class="`position-absolute h-2 bg-${
                !bytype ? item.rrss : 'primary'
              } top-0 left-0`"
              :style="`width: ${item.total}%; z-index :2; max-width : 100%`"
            ></div>
          </div>
        </div>
        <span
          class="rrss-total text-xs text-color-text font-weight-bold pr-lg-3 pr-xl-0"
          >{{ numFormatter(item.total) }}</span
        >
      </div>
    </div>
    <div
      class="image-container-off"
      v-if="
        socialData.reduce((a, b) => {
          return (a += b.total);
        }, 0) == 0 && bytype == false
      "
    >
      <img
        class="opacity-50"
        src="@/assets/images/off-interaction-social-network.png"
        alt=""
      />
      <div class="image-container-off__message opacity-required-35">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    <div
      class="image-container-off"
      v-if="
        socialData.reduce((a, b) => {
          return (a += b.total);
        }, 0) == 0 && bytype == true
      "
    >
      <img
        class="opacity-50"
        src="@/assets/images/images-off-interactions-bytype.png"
        alt=""
      />
      <div class="image-container-off__message opacity-required-35">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, Helpers } from "@isc/styleguide";
import { rutaNotFound } from "../../../../util/config";
export default { 
  props: {
    socialData: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    tooltipMsg: {
      type: String,
      required: true,
    },
    rrss: {
      type: Boolean,
      default: true,
    },
    callcomponentType: {
      type: Number,
      default: 0,
    },
    bytype: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      view: false,
      viewSocialData: false,
      fixNameSocialNetwork: (name) => Helpers.fixNameSocialNetwork(name),
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  created() {
    this.validar();
  },
  methods: {
    async validar() {
      for (let item of this.socialData) {
        if (item.total > 0) {
          this.view = true;
          break;
        }
      }
    },
  },
};
</script>

<style>
.block-rrss-info .st-rrss {
  width: 90px;
}
.block-rrss-info .rrss-total {
  width: 50px;
  text-align: right;
}
.block-rrss-info .smallName {
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
</style>
