<template>
  <div class="w-8/12 pl-3 pr-5">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
        >
          Posts
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Associated social networks posts by day and time',
          }"
        ></i>
      </div>
    </div>
    <graph-points
      v-if="viewGraph"
      :titleyAxis="titleyAxis"
      :series="series"
      :categories="categories"
      :arrDataUrl="arrDataUrl"
    ></graph-points>
    <div v-else class="image-container-off">
      <img class="opacity-50" src="@/assets/images/off-post-graph.png" alt="" />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../util/config";
import graphPoints from "../../../graph-custom/graphPoints.vue";
var dataGraphTwitter = [];
var arrDataUrlTooltip = [];
export default {
  components: {
    graphPoints,
  },
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      titleyAxis: "Hours",
    };
  },
  props: {
    viewGraph: {
      type: Boolean,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    arrDataUrl: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {
    setTotalChar() {
      try {
        arrDataUrlTooltip = this.arrDataUrl;
        let options = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "scatter",
            zoomType: "xy",
            marginTop: 10,
            marginBottom: 80,
          },
          credits: { text: "" },
          exporting: { enabled: false },
          title: { text: null },
          xAxis: {
            min: 0,
            startOnTick: true,
            endOnTick: true,
            tickInterval: 1,
            labels: {
              formatter: null,
              autoRotation: false,
              rotation: 0,
              style: { color: "#ababab" },
            },
          },
          yAxis: {
            title: { text: "Hours", align: "middle" },
            labels: {
              formatter: function () {
                return this.value + ":00";
              },
            },
            max: 24,
            tickInterval: 1,
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
            plotBands: [
              {
                from: 5,
                to: 12,
                color: "rgba(68, 170, 213, 0.1)",
                label: {
                  text: "Morning",
                  style: { color: "#606060" },
                },
              },
              {
                from: 12,
                to: 18,
                color: "rgba(0,0,0,0)",
                label: {
                  text: "Afternoon",
                  style: { color: "#606060" },
                },
              },
              {
                from: 18,
                to: 24,
                color: "rgba(68, 170, 213, 0.1)",
                label: {
                  text: "Night",
                  style: { color: "#606060" },
                },
              },
            ],
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px;font-weight:normal;text-align: center;"></span><table>',
            pointFormat: '<tr><td style="padding:1rem;">View Post </td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: false,
          },
          legend: {
            align: "right",
            x: 10,
            verticalAlign: "bottom",
            y: 10,
            floating: true,
            borderWidth: 0,
            shadow: false,
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: { enabled: true, lineColor: "rgb(100,100,100)" },
                },
              },
              states: {
                hover: { marker: { enabled: true } },
              },
            },
            series: {
              point: {
                events: {
                  click: function (event) {
                    const dataOption = event.point.options;
                    const indexGraphEquals = dataOption.x + "" + dataOption.y;
                    let respUrl = arrDataUrlTooltip.find(
                      (it) => it.indexGraph == indexGraphEquals
                    );
                    const objPost = respUrl || {};
                    window.open(objPost.url);
                  },
                },
              },
              cursor: "pointer",
            },
          },
          series: [],
        };
        options.series = this.series;
        options.xAxis.max = this.categories.length - 1;
        const _categories = this.categories;
        options.xAxis.labels.formatter = function () {
          return _categories[this.value];
        };
        dataGraphTwitter = this.series;
        return options;
      } catch (error) {
        return {};
      }
    },
  },
  created() {},
};
</script>

<style></style>
