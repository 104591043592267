var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-8/12 pl-3 pr-5" },
    [
      _c("div", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "span",
            {
              staticClass:
                "font-weight-bold text-capitalize text-lsm text-secondary mr-2",
            },
            [_vm._v("Engaged Users by day ")]
          ),
          _c("i", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover",
                value: {
                  variant: "primary",
                  content: "Engaged Users by day",
                },
                expression:
                  "{\n          variant: 'primary',\n          content: 'Engaged Users by day',\n        }",
                modifiers: { hover: true },
              },
            ],
            staticClass: "fa fa-info-circle text-skygray",
          }),
        ]),
      ]),
      _vm.view
        ? _c("highcharts", {
            attrs: { options: _vm.setDataGraphEngageByDay() },
          })
        : _c("div", { staticClass: "image-container-off" }, [
            _c("img", {
              staticStyle: { opacity: "0.5", width: "100%" },
              attrs: {
                src: require("@/assets/images/engaged_users_day_off.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "image-container-off__message" }, [
              _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
              _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }