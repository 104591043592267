<template>
  <div class="modaltokenexp">
    <b-modal no-close-on-backdrop class="modal" id="modaltoken" title="BootstrapVue" >
        <template class="modal-header" #modal-title>
            <h4 class="modal-title" style="color:white !important;padding-left: 35px;">
							<i class="fa fa-exclamation-triangle"></i>
							Los accesos a las siguientes páginas han vencido
			</h4>
        </template>
        <b-container>
           	<ol class="lista" style="overflow-y:auto;max-height:110px;margin-top: 20px;margin-bottom: 20px;">
                <li v-for="item in cuentas" :key="item.idCuentaRedsocial" style="margin-bottom: 10px;"><i :class="`fab fa-${item.nomRedSocial} fbco`" style="font-size:18px;padding-right: 10px;"></i>{{item.nomUsuario}} </li>             
            </ol>				
        </b-container>
        <template #modal-footer>
            <button class="enlace" role="link" @click="getPageSettings"  style="background-color:#00a5ff; color:white; width:155px; height:35px">
				Volver a registrar
			</button>
        </template>
        
  </b-modal>
  </div>
</template>
<script>
export default {
name:"modaltokenexp",
props:{
    cuentas:{
        type: Array,
        required:true
    }
},
created(){
},
methods: {
    getPageSettings(){
        window.location.href = 'src/components/Settings-Components/subCampaigns/socialProfile.vue'
    }
},
}
</script>

<style >
 #modaltoken .modal-header{
    width: 100% ;
    background-color: #ff7e7e !important;
    height: 51px;
    padding: 15px;
    border-radius: 0px !important;
    
}
#modaltoken .modal-header h4{
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    text-align: left;
    font-family: 'Oxygen' sans-serif;
    margin: 0px !important;
    padding-left:35px;
    
}
#modaltoken .modal-content{
   border-radius: 0px;
   width: 557px !important;
   margin: auto;
   margin-top: 60px;
}
#modaltoken .close{
    background: none !important;
    border: none !important;
    color: white !important;
    font-size: 30px !important;
    font-weight: 1000 !important;
    opacity: 1;
    padding: 10px;
}
#modaltoken ol{
    margin: 0;
    list-style-type: none;
    padding: 0;
}
#modaltoken ol li {
    margin: 2px;
    font-size: 13px !important;
    font-family: 'Oxygen' sans-serif;
    color: #333;
}
#modaltoken .fbco {
    color: #1778f2;
}
#modaltoken .enlace{
    
    font-size: 12px !important;
    font-family: 'Oxygen' sans-serif;
    margin: auto;
}
#modaltoken .modal-footer{
text-align: center ;    
}
</style>

