var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 pt-4 d-flex flex-wrap" },
    [
      _c("engaged-users-by-day-chart", {
        attrs: {
          series: _vm.detail.usersEngageByDay,
          categories: _vm.chartCategories,
          boolViewData: _vm.detail.boolEngagedUsersByDay,
        },
      }),
      _c("engaged-levels", { attrs: { levels: _vm.detail.levels } }),
      _c("div", { staticClass: "w-100 h-16" }),
      _c("dayli-engagedment", {
        attrs: {
          series: _vm.detail.dayliEngage,
          categories: _vm.chartCategories,
        },
      }),
      _c("engage-type", { attrs: { count: _vm.detail.typeEngage } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }