<template>
  <b-row no-gutters class="mt-2">
    <b-col cols="8 pl-3 pr-5">
      <div class="mb-4">
        <span class="font-weight-bold"
          >Posts by day
          <i
            class="fa fa-info-circle text-skygray"
            v-b-popover.hover="{
              variant: 'primary',
              content: 'Posts made on the social network per day',
            }"
          ></i>
        </span>
      </div>
      <div
        v-if="!view"
        class="image-container-off"
      >
        <img
          class="off_op"
          src="@/assets/images/image-off-postperday.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
      <highcharts v-else :options="options" />
    </b-col>
    <b-col cols="4" class="pl-5 pr-2">
      <div class="bg-youtube rounded p-4 text-white">
        <p>Most used day to post</p>
        <p class="text-2xl font-weight-light">
          {{ postIndicators.horarioTiempo }}
        </p>
      </div>
      <div class="rounded p-4">
        <p>Most used hour to post</p>
        <p class="text-4xl font-weight-bold">{{ hour }}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
export default {
  data() {
    return {
      options: {
        chart: {
          renderTo: "bpchart2b",
          type: "column",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          marginTop: 10,
          height: 360,
          marginBottom: 80,
        },
        title: {
          text: null,
        },
        xAxis: {
          allowDecimals: false,
          type: "datetime",
          lineColor: "#D6DBDF",
          tickLength: 0,
          categories: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
        },
        credits: {
          text: null,
        },
        yAxis: {
          allowDecimals: false,
          labels: {
            style: {
              color: "#B3B4B8",
            },
          },
          min: 0,
          title: {
            text: null,
            enabled: false,
            gridLineColor: "#D5DAE0",
          },
          stackLabels: {
            enabled: false,
          },
        },
        legend: {
          align: "right",
          x: 10,
          verticalAlign: "bottom",
          y: 10,
          floating: true,
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        tooltip: {
          formatter: function () {
            let text =
              "<table class='table_tooltip' border='0' cellspacing='0' cellpadding='0'>";
            text +=
              "<tr><td colspan='2' class='tp-table'>" + this.x + "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: false,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        exporting: {
          enabled: false,
        },
        plotOptions: {
          series: {
            pointWidth: 5, //width of the column bars irrespective of the chart size
            allowPointSelect: true,
          },
          bar: {
            stacking: "normal",
            pointWidth: 7,
          },
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
            },
            pointWidth: 7,
          },
        },
        series: [],
      },
      view: false,
    };
  },
  props: {
    postIndicators: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    hour: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  created() {
    this.validate();
    this.options.series = this.series;
  },
  methods: {
    validate() {
      for (const item of this.series) {
        item.data.forEach((element) => {
          if (element > 0) {
            this.view = true;
            return;
          }
        });
      }
    },
  },
};
</script>

<style></style>
