var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bolChangeData
    ? _c("div", { staticClass: "w-100 mb-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3 pr-0 " }, [
            _vm.bestIndicator
              ? _c(
                  "div",
                  {
                    staticClass:
                      "w-100 box-resume first-block bg-primary text-white",
                  },
                  [
                    _c("div", {}, [
                      _c(
                        "p",
                        { staticClass: "text-capitalize text-lsm mb-0" },
                        [_vm._v(_vm._s(_vm.bestIndicator.name))]
                      ),
                      _c("p", { staticClass: "text-3-5xl mb-0" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.numFormatter(_vm.bestIndicator.count))
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "w-100 mb-1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "m-0 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("span", { staticClass: "text-xs" }, [
                              _vm._v("Variation"),
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "text-lg" }, [
                                _vm._v(
                                  _vm._s(_vm.bestIndicator.variation) + "% "
                                ),
                              ]),
                              _c("i", {
                                class:
                                  "fa fa-sort-" +
                                  (_vm.bestIndicator.variation > 0
                                    ? "up"
                                    : "down"),
                              }),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "position-relative w-100" }, [
                        _c("div", {
                          staticClass: "position-relative z-0 h-2",
                          staticStyle: {
                            "background-color": "azure",
                            opacity: "0.2",
                          },
                        }),
                        _c("div", {
                          class:
                            "position-absolute h-2 bg-white bg-" +
                            (_vm.bestIndicator.variation > 0
                              ? "warning"
                              : "danger") +
                            " top-0",
                          style:
                            "width: " +
                            (_vm.bestIndicator.variation > 0
                              ? _vm.bestIndicator.variation
                              : 0) +
                            "%; z-index : 2",
                        }),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "col-3 pr-0" }, [
            _c(
              "div",
              { staticClass: "box-resume text-secondary  bg-white" },
              _vm._l(_vm.variations, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "d-flex justify-content-between" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-capitalize text-color-text text-xs",
                        class: [
                          _vm.activeVariation === item.name
                            ? "variation-active"
                            : "cursor-pointer",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.setActiveVariation(item.name)
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(item.name.replace("_", " ")) + " "),
                        item.name === "engaged_users"
                          ? _c("i", {
                              staticClass: "fab fa-facebook text-facebook",
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-lsm mr-2 item-count text-color-text",
                        },
                        [
                          _c("b", [
                            _vm._v(
                              " " + _vm._s(_vm.numFormatter(item.count)) + " "
                            ),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "text-skygray text-xxs" }, [
                        _vm._v(" " + _vm._s(item.variation) + "% "),
                        _c("i", {
                          class:
                            "text-aling-center fa fa-sort-" +
                            (item.variation > 0
                              ? "up text-success "
                              : "down text-danger") +
                            " fa-lg",
                        }),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-3 pr-0" },
            [
              _vm.accounts && _vm.accounts.length > 0
                ? _c("variation-item", {
                    attrs: {
                      "variation-type": "top",
                      accounts: _vm.accounts,
                      metric: _vm.activeVariation,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-3 pr-0" },
            [
              _vm.accounts && _vm.accounts.length > 0
                ? _c("variation-item", {
                    attrs: {
                      "variation-type": "bottom",
                      accounts: _vm.accounts,
                      metric: _vm.activeVariation,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }