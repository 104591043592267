<template>
  <div class="w-100 bg-mainbg" id="accountdetail">
    <div>
      <loader-custom v-if="visibilityLoader"></loader-custom>
      <bread-crumb>
        <v-datepicker
          range
          v-model="rangeDate"
          class="mr-3"
          ref="datePicker"
          type="date"
          :clearable="false"
          range-separator=" - "
          format="DD/MM/YYYY"
          @change="changeDate"
          @open="openCalendar"
          :disabled-date="disabledAfter"
        />
      </bread-crumb>
      <div v-if="temp == true && rangeDate.length > 1" class="mt-2">
        <b-container fluid v-if="detail" class="detail-content-box">
          <profile-bar
            :profile-name="profile.name"
            :rrss="profile.rrss"
            :logo="profile.logo"
          />
          <resume :detail="detail" />
          <resume-horizontal :accounts="detail.accounts" />
          <tabs
            :cant="existsFacebook ? 4 : 3"
            :titles="filterTabs"
            @change="changeTab"
          />
          <div class="w-100 bg-white p-xl-5 p-lg-2">
            <audience v-if="activeTab === 0" :rangeDate="rangeDate" />
            <posts v-else-if="activeTab === 1" :rangeDate="rangeDate" />
            <engaged-users
              v-else-if="activeTab === 2 && existsFacebook"
              :rangeDate="rangeDate"
            />
            <interactions v-else :rangeDate="rangeDate" />
          </div>
        </b-container>
      </div>
      <div v-else>
        <div id="newpanelcc" class="row" align="center" style="display: block">
          <div class="box_notice">
            You need to add accounts to this brand, Go to Overview -&gt; Manage
            Brands -&gt; Edit Brand
          </div>
        </div>
      </div>
    </div>
    <!-- ALERT CODE MODAL -->
    <IscModalAlerts
      v-model="showAlertCode"
      :icon="iconCode"
      :iconColor="iconColorCode"
      :title="titleCode"
      :paragraph="paragraphCode"
      :buttonMessage="buttonMessageCode"
      @sendResult="resultModalAlertsCode"
      :noCloseBackdrop="true"
      :refresh="true"
    />
    <!-- MODAL ALERT GLOBAL -->
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>

<script>
import {
  BreadCrumb,
  Tabs,
  Helpers,
  loaderCustom,
  UserObjMixin,
  AlertSetting,
  mapActions,
  CodeToken,
  IscModalAlerts,
  mapMutations,
} from "@isc/styleguide";
import ProfileBar from "../components/detail/ProfileBar.vue";
import Resume from "../components/detail/Resume.vue";
import ResumeHorizontal from "../components/detail/ResumeHorizontal.vue";

import Audience from "../components/detail/tabs/Audience/Audience";
import EngagedUsers from "../components/detail/tabs/EngagedUsers/EngagedUsers";
import Posts from "../components/detail/tabs/Posts/Posts";
import Interactions from "../components/detail/tabs/Interactions/Interactions";
import { orderSocialNetworks, orderStringAlphabetically } from "../util/util";
import moment from "moment";

export default {
  name: "OverviewAccountDetail",
  components: {
    BreadCrumb,
    ProfileBar,
    Resume,
    ResumeHorizontal,
    Tabs,
    Audience,
    EngagedUsers,
    Posts,
    Interactions,
    loaderCustom,
    IscModalAlerts,
  },
  mixins: [CodeToken, AlertSetting, UserObjMixin],
  data() {
    return {
      rangeDate: [moment().subtract(15, "days").toDate(), moment().toDate()],

      profile: {
        rrss: [],
        name: "",
      },
      detail: null,
      activeTab: 0,
      tabs: [
        {
          name: "Audience",
        },
        {
          name: "Posts",
        },
        {
          name: "Engaged Users",
          icon: "fab fa-facebook text-facebook",
        },
        {
          name: "Interactions",
        },
      ],
      updateChangesResume: 0,
      updateChangesResumeHorizontal: 0,
      updateChangesContentTab: 0,
      temp: true,
      account_id: "",
      visibilityLoader: false,
      arrDataAudiencia: [],
      arrDataPosts: [],
      arrDataEngagedUsers: [],
      arrDataInteractions: [],
      prevRangeDate: [],
    };
  },
  async mounted() {
    try {
      await this.setCurrentObjUser();
      const dateInit = localStorage.getItem("RangeDateInit");
      const dateFin = localStorage.getItem("RangeDateFin");
      if (dateInit) {
        this.rangeDate = [new Date(dateInit), new Date(dateFin)];
      }
      this.visibilityLoader = true;
      this.account_id = this.$route.query.account;
      this.setData();
      await this.getServicesInit();
      this.visibilityLoader = false;
    } catch (error) {
      console.error("sucedio un error al crear el componente: ", error);
    }
  },
  methods: {
    ...mapActions({
      getDetailAccount: "overview/account/GET_ACCOUNT_DETAIL",
      getAccountAudience: "overview/account/GET_ACCOUNT_AUDIENCE",
      getAccountPosts: "overview/account/GET_ACCOUNT_POSTS",
      getAccountEngagedUsers: "overview/account/GET_ACCOUNT_ENGAGED_USERS",
      getAccountInteractions: "overview/account/GET_ACCOUNT_INTERACTIONS",
    }),
    ...mapMutations({
      setProfileAccount: "overview/account/setProfileAccount",
      setAudienceAccount: "overview/account/setAudienceAccount",
      setPostsAccount: "overview/account/setPostsAccount",
      setEngagedAccount: "overview/account/setEngagedAccount",
      setInteractionsAccount: "overview/account/setInteractionsAccount",
    }),
    setData() {
      try {
        let objAudiencia = {
          acumulatedAudienceGraph: [],
          audienceCount: [],
          chartCategories: [],
          newAudienceGraph: [],
        };
        let objPosts = {
          chartCategories: [],
          mostUsedDays: [],
          mostUsedHours: [],
          postPerDayGraph: [],
          postsGraph: [],
          totalPostByRrss: [],
        };
        let objEngaged = {
          chartCategories: [],
          dayliEngage: [],
          levels: {},
          typeEngage: {},
          usersEngageByDay: [],
        };
        let objInteraction = {
          chartCategories: [],
          interactionsByDay: [],
          interactionsByType: [],
          interactionsByweek: [],
          posts: [],
          socialsTotal: [],
          total: 0,
          typesTotal: [],
        };
        this.arrDataAudiencia.push(objAudiencia);
        this.arrDataPosts.push(objPosts);
        this.arrDataEngagedUsers.push(objEngaged);
        this.arrDataInteractions.push(objInteraction);
        /* console.info("el this.arrDataAudiencia 2: ", this.arrDataAudiencia);
        console.info("el this.arrDataPosts 2: ", this.arrDataPosts);
        console.info(
          "el this.arrDataEngagedUsers 2: ",
          this.arrDataEngagedUsers
        );
        console.info(
          "el this.arrDataInteractions 2: ",
          this.arrDataInteractions
        ); */
      } catch (error) {}
    },
    async getServicesInit() {
      try {
        //let dataSend = {
        let account_id = this.account_id;
        let rangeDate = [
          moment(this.rangeDate[0]).format("YYYY-MM-DD"),
          moment(this.rangeDate[1]).format("YYYY-MM-DD"),
        ];
        let timezoneId = this.userInfo.ID_ZONA_HORARIA;
        //};
        await this.loadDetail();
        this.arrDataAudiencia = await this.getLoadAudience(
          account_id,
          rangeDate,
          timezoneId
        );

        this.arrDataPosts = await this.getLoadPost(
          account_id,
          rangeDate,
          timezoneId
        );

        this.arrDataEngagedUsers = await this.getLoadEngagedUsers(
          account_id,
          rangeDate,
          timezoneId
        );
        this.arrDataInteractions = await this.getLoadInteractions(
          account_id,
          rangeDate,
          timezoneId
        );
        /* console.info("el this.arrDataAudiencia: ", this.arrDataAudiencia);
        console.info("el this.arrDataPosts: ", this.arrDataPosts);
        console.info("el this.arrDataEngagedUsers: ", this.arrDataEngagedUsers);
        console.info("el this.arrDataInteractions: ", this.arrDataInteractions); */
      } catch (error) {}
    },
    // async changeDate(date) {
    //   try {
    //     this.visibilityLoader = true;
    //     localStorage.setItem('RangeDateInit',date[0])
    //     localStorage.setItem('RangeDateFin',date[1])

    //     this.rangeDate[0] = date[0];
    //     this.rangeDate[1] = date[1];
    //     await this.getServicesInit();
    //     this.updateChangesResume++;
    //     this.updateChangesResumeHorizontal++;
    //     this.updateChangesContentTab++;
    //     this.visibilityLoader = false;
    //   } catch (error) {
    //   }
    // },
    async changeDate(date) {
      const validateRange = Helpers.validateRange(
        this.rangeDate[0],
        this.rangeDate[1]
      );
      if (!validateRange) {
        this.rangeDate = this.prevRangeDate;
        let message = "You can only search up to the last 30 days.";
        let title = "Error";
        Helpers.getToastAlert(
          this.$bvToast,
          message,
          title,
          null,
          "errorBvToast"
        );
      } else {
        this.visibilityLoader = true;
        localStorage.setItem("RangeDateInit", date[0]);
        localStorage.setItem("RangeDateFin", date[1]);

        this.rangeDate[0] = date[0];
        this.rangeDate[1] = date[1];
        await this.getServicesInit();
        this.updateChangesResume++;
        this.updateChangesResumeHorizontal++;
        this.updateChangesContentTab++;
        this.visibilityLoader = false;
      }
    },
    openCalendar() {
      this.prevRangeDate = this.rangeDate;
    },
    changeTab(position) {
      this.activeTab = position;
    },
    async loadDetail() {
      let account_id = this.$route.query.account;
      let rangeDate = [
        moment(this.rangeDate[0]).format("YYYY-MM-DD"),
        moment(this.rangeDate[1]).format("YYYY-MM-DD"),
      ];
      let timezoneId = this.userInfo.ID_ZONA_HORARIA;
      const detail = await this.getAccountDetail(
        account_id,
        rangeDate,
        timezoneId
      );

      this.profile = {
        rrss: detail.rrss_list,
        name: detail.account_name,
        logo: detail.logo,
      };
      this.detail = detail;
      if (this.detail.accounts.length > 0) {
        this.temp = true;
      } else {
        this.temp = false;
      }
    },
    disabledAfter(date) {
      return date > new Date();
    },
    //**  MAIN Methods 
    async getAccountDetail(account_id, rangeDetail, timezoneId) {
      try {
        const respGetAccount = await this.getDetailAccount({
          account_id,
          rangeDetail,
          timezoneId,
        });
        const resp = this.validationCodeToken(respGetAccount.data);
        if (!resp) return;
        resp.accounts = orderSocialNetworks(resp.accounts, "rrss");
        resp.rrss_list = orderStringAlphabetically(resp.rrss_list);
        this.setProfileAccount(resp);
        return resp;
      } catch (error) {
        console.log("[Error] in function getAccountDetail ", error);
        // adding modal
        let message =
          error?.response?.data.message ?? "Server fails to get account Detail";
        this.errorModal(message);
      }
    },
    async getLoadAudience(account_id, rangeDetail, timezoneId) {
      try {
        const respGetAudience = await this.getAccountAudience({
          account_id,
          rangeDetail,
          timezoneId,
        });
        const resp = this.validationCodeToken(respGetAudience.data);
        if (!resp) return;
        const attr1 = "name";
        const attr2 = "rrss";
        // Sorting
        resp.acumulatedAudienceGraph = orderSocialNetworks(
          resp.acumulatedAudienceGraph,
          attr1
        );
        resp.audienceCount = orderSocialNetworks(resp.audienceCount, attr2);
        resp.newAudienceGraph = orderSocialNetworks(
          resp.newAudienceGraph,
          attr1
        );
        this.setAudienceAccount(resp);
        return resp;
      } catch (error) {
        console.log("[Error] in function getLoadAudience ", error);
        // adding modal
        let message =
          error?.response?.data.message ??
          "Server fails to get loading audience";
        this.errorModal(message);
      }
    },
    async getLoadPost(account_id, rangeDetail, timezoneId) {
      try {
        const respGetPosts = await this.getAccountPosts({
          account_id,
          rangeDetail,
          timezoneId,
        });
        const resp = this.validationCodeToken(respGetPosts.data);
        if (!resp) return;
        const attribute1 = "rrss";
        const attribute2 = "name";
        //* rrss
        resp.mostUsedDays = orderSocialNetworks(resp.mostUsedDays, attribute1);
        resp.mostUsedHours = orderSocialNetworks(
          resp.mostUsedHours,
          attribute1
        );
        resp.totalPostByRrss = orderSocialNetworks(
          resp.totalPostByRrss,
          attribute1
        );
        //* name
        resp.postPerDayGraph = orderSocialNetworks(
          resp.postPerDayGraph,
          attribute2
        );
        resp.postsGraph = orderSocialNetworks(resp.postsGraph, attribute2);
        this.setPostsAccount(resp);
        return resp;
      } catch (error) {
        console.log("[Error] in function getLoadAudience ", error);
        // adding modal
        let message =
          error?.response?.data.message ??
          "Server fails to get loading audience";
        this.errorModal(message);
      }
    },
    async getLoadEngagedUsers(account_id, rangeDetail, timezoneId) {
      try {
        const respGetEngagedUsers = await this.getAccountEngagedUsers({
          account_id,
          rangeDetail,
          timezoneId,
        });
        const resp = this.validationCodeToken(respGetEngagedUsers.data);
        if (!resp) return;
        this.setEngagedAccount(resp);
        return resp;
      } catch (error) {
        console.log("[Error] in function getLoadEngagedUsers ", error);
        // adding modal
        let message =
          error?.response?.data.message ??
          "Server fails to get loading engaged users.";
        this.errorModal(message);
      }
    },
    async getLoadInteractions(account_id, rangeDetail, timezoneId) {
      try {
        const respGetInteractions = await this.getAccountInteractions({
          account_id,
          rangeDetail,
          timezoneId,
        });
        const resp = this.validationCodeToken(respGetInteractions.data);
        if (!resp) return;
        const attr = 'name';
        resp.interactionsByDay = orderSocialNetworks(resp.interactionsByDay, attr);
        resp.interactionsByType = orderSocialNetworks(resp.interactionsByType, attr);
        resp.socialsTotal = orderSocialNetworks(resp.socialsTotal, 'rrss');
        this.setInteractionsAccount(resp);
        return resp;
      } catch (error) {
        console.log("[Error] in function getLoadInteractions ", error);
        // adding modal
        let message =
          error?.response?.data.message ??
          "Server fails to get loading interactions.";
        this.errorModal(message);
      }
    },
  },
  computed: {
    //...mapGetters({ profileAccount: "getProfileAccount" }),
    existsFacebook() {
      return this.profile.rrss.includes("facebook");
    },
    filterTabs() {
      try {
        if (this.existsFacebook) {
          return this.tabs;
        } else {
          return this.tabs.filter((elm) => elm.name !== "Engaged Users");
        }
      } catch (error) {
        return [];
      }
    },
  },
};
</script>
<style>
#accountdetail .box_notice {
  margin-left: 0;
  margin-right: 0px;
}
#accountdetail .box_notice {
  background-color: #e1ebf4;
  background-image: url("https://www.isocialcube.com/home/images/notify_notice.png");
  background-repeat: no-repeat;
  background-position: 4px 6px;
  border: 0px solid #c4d1d9;
  padding: 6px;
  padding-left: 25px;
  font-family: "Oxygen" sans-serif;
  font-size: 12px;
  margin-top: 3px;
  text-align: left;
}
#newpanelcc {
  padding-right: 0px;
  padding-left: 0px;
  width: 1281px;
  height: 32px;
  margin: auto;
  margin-top: 10px;
}
.mx-input {
  font-family: Oxygen;
  font-size: 12px;
  font-weight: normal;
}
.detail-content-box {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
}
</style>
