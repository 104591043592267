var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-8/12 pl-3 pr-5" },
    [
      _c("div", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "span",
            {
              staticClass:
                "font-weight-bold text-capitalize text-lsm text-secondary mr-2",
            },
            [_vm._v("Daily engaged users (%) ")]
          ),
          _c("i", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover",
                value: {
                  variant: "primary",
                  content:
                    "Total percentage of daily engagement ((Engaged Users)/(Reach))* 100",
                },
                expression:
                  "{\n          variant: 'primary',\n          content:\n            'Total percentage of daily engagement ((Engaged Users)/(Reach))* 100',\n        }",
                modifiers: { hover: true },
              },
            ],
            staticClass: "fa fa-info-circle text-skygray",
          }),
        ]),
      ]),
      _vm.series[0].data.reduce(function (a, b) {
        return (a += b)
      }) > 0
        ? _c("highcharts", { attrs: { options: _vm.setDataDayliEngaged() } })
        : _vm._e(),
      _vm.series[0].data.reduce(function (a, b) {
        return (a += b)
      }) == 0
        ? _c("div", { staticClass: "image-container-off" }, [
            _c("img", {
              staticStyle: { opacity: "0.5", width: "100%" },
              attrs: {
                src: require("@/assets/images/off-daily-engagement.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "image-container-off__message" }, [
              _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
              _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }