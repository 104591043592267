<template>
  <div>
    <highcharts :options="getOptions()" />
  </div>
</template>
<script>
import { Helpers } from "@isc/styleguide";
export default {
  props: {
    titlexAxis: {
      type: String,
      required: true,
    },
    arrTitlesyAxis: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: [Array, String],
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getOptions() {
      var _titlexAxis = this.titlexAxis;
      var _categories = this.categories;
      try {
        var _xAxis = {
          title: {
            enabled: true,
            text: _titlexAxis,
          },
          lineColor: "#D6DBDF",
          categories: _categories,
          tickWidth: 0,
          labels: {
            autoRotation: false,
            rotation: 0,
            style: { color: "#ababab" },
          }, 
        };
        let _arrTitlesyAxis = [];
        this.arrTitlesyAxis.forEach((it) => {
          let objyAxis = {};
          objyAxis.allowDecimals = false;
          objyAxis.min = 0;
          objyAxis.labels = { style: { color: "#ababab" } };
          objyAxis.title = { enabled: true, text: it, align: "middle" };
          _arrTitlesyAxis.push(objyAxis);
        });
        var _yAxis = _arrTitlesyAxis;
        let options = {
          chart: {
            type: "spline",
          },
          title: { text: null, enabled: false },
          credits: { text: "" },
          xAxis: _xAxis,
          yAxis: _yAxis,
          tooltip: {
            formatter: function () {
              let text =
                "<table class='table_tooltip' border=0 cellspacing=0 cellpadding='5px' style=''>";
              text +=
                "<tr><td class='tp-table' colspan='2' style=''>" +
                this.x +
                "</td></tr>";
              for (let i = 0; i < this.points.length; i++) {
                text += "<tr><td style='padding:4px;padding-left:8px;'>";
                text +=
                  "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                  this.points[i].series.color +
                  " !important'></i> " +
                  this.points[i].series.name;
                text +=
                  "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
                text += Helpers.numberWithCommas(this.points[i].y);
                text += "</td></tr>";
              }
              text += "</table>";
              return text;
            },
            shared: true,
            padding: 0,
            backgroundColor: "#ffffff",
            useHTML: true,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            crosshairs: true,
          },
          plotOptions: {
            column: { pointWidth: 5 },
            bar: { pointWidth: 5 },
            area: {
              marker: { enabled: false },
              connectNulls: true,
            },
            line: {
              marker: { lineWidth: 1, enabled: true },
              connectNulls: true,
            },
            spline: { marker: { lineWidth: 1, radius: 3, symbol: "circle" } },
            series: { pointPadding: 0, groupPadding: 0 },
          },
          exporting: { enabled: false },
          labels: {
            items: [
              {
                style: { left: "40px", top: "8px", color: "red" },
              },
            ],
          },
          legend: {
            align: "right",
            x: 0,
            y: 10,
            verticalAlign: "bottom",
            borderWidth: 0,
            shadow: false,
            itemStyle: {
              fontWeight: "normal",
              color: "#6F7478",
              fontSize: "10px",
            },
          },
          series: []
        };
        options.series = this.series;
        return options;
      } catch (error) {
        console.error("sucedio un error en getOptions: ", error);
        return {};
      }
    },
  },
};
</script>
