<template>
  <div class="w-100">
    <div class="py-4 d-flex">
      <div class="w-100 d-flex align-items-center">
        <img
          class="profile_img d-inline-block"
          :src="logo"
          alt="foto de perfil"
          @error="errorUrlImage"
        />
        <span class="font-weight-bold mx-2 mr-3">{{ profileName }}</span>
        <i
          :class="`fab fa-${social} mr-2 text-${social}`"
          v-for="(social, index) in rrss"
          :key="index"
        ></i>
        <div>
          <i
            id="generalAudienceDetail"
            class="fa fa-info-circle icon-msg-info"
            aria-hidden="true"
          ></i>
          <CpTooltipWhiteCustom
            target="generalAudienceDetail"
            title="generalAudienceDetail"
            message="Update: Every 24 hours"
          ></CpTooltipWhiteCustom>
        </div>
        <div>
          <i
            id="variationMsg"
            class="fa fa-info-circle icon-msg-info"
            aria-hidden="true"
          ></i>
          <CpTooltipDoubleCustom
            target="variationMsg"
            title="Variation (%):"
            :subTitle1="'- Audience: Start date vs. end date.'"
            heightSubTitle1="20px"
            heightSubTitle2="50px"
            subTitle2="- Posts, Engaged Users and Interactions: Period selected vs. previous period."
            backgroundColorSubtitle1="white"
            :colorTextSubtitle1="'#6b738b'"
            textAlignSubtitle1="left"
            widthSubtitle1="204px"
            textAlignSubtitle2="left"
          ></CpTooltipDoubleCustom>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CpTooltipWhiteCustom, CpTooltipDoubleCustom } from "@isc/styleguide";
export default {
  name: "ProfileBar",
  components: {
    CpTooltipWhiteCustom,
    CpTooltipDoubleCustom,
  },
  props: {
    rrss: {
      type: Array,
      required: true,
    },
    profileName: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
  },
  methods: {
    errorUrlImage(event) {
      // changing for default image.
      event.target.src = require("../../assets/images/off-account_40x40.png");
    },
  },
};
</script>

<style scoped>
.profile_img {
  border-radius: 999999999px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
</style>
