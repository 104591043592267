import Router from 'vue-router' 
import accounts from './views/Accounts.vue'
import detailAccount from './views/AccountDetail.vue'
import channels from './views/Channels.vue'
import detailChannel from './views/ChannelDetail.vue'
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/overview/accounts',
      name: 'overview-accounts',
      component: accounts
    },
    {
      path: '/overview/accounts/detail',
      name: 'overview-accounts-detail',
      component: detailAccount
    },
    {
      path: '/overview/channels',
      name: 'overview-channels',
      component: channels
    },
    {
      path: '/overview/channels/detail',
      name: 'overview-channels-detail',
      component: detailChannel
    }
  ]
})
