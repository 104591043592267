var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rrss && _vm.metricDetail
    ? _c("div", { staticClass: "box-resume w-100" }, [
        _c("p", { staticClass: "mb-2 text-lsm text-secondary" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.variationType === "bottom"
                  ? "Bottom Social Network"
                  : "Top Social Network"
              ) +
              " "
          ),
        ]),
        _c("div", {}, [
          _c(
            "div",
            { staticClass: "mb-0 leading-none title-color text-3-5xl" },
            [
              _c("b", [
                _vm._v(_vm._s(_vm.numFormatter(_vm.metricDetail.count))),
              ]),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "text-skygray text-xxs eading-none text-capitalize",
            },
            [_vm._v(_vm._s(_vm.metric))]
          ),
        ]),
        _c("div", { staticClass: "w-100" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between w-100",
            },
            [
              _c("div", { staticClass: "d-block socialMedia-name" }, [
                _c("i", {
                  class:
                    "fab fa-" + _vm.rrss.name + " mr-1 text-" + _vm.rrss.name,
                }),
                _c("span", { staticClass: "text-xs text-secondary" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.fixNameSocialNetwork(_vm.rrss.name) +
                          ": " +
                          _vm.rrss.account
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "text-lg text-secondary" }, [
                  _vm._v(" " + _vm._s(_vm.metricDetail.variation) + "% "),
                ]),
                _c("i", {
                  class:
                    "fa fa-sort-" +
                    (_vm.metricDetail.variation > 0
                      ? "up text-success"
                      : "down text-danger"),
                }),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "position-relative w-100" }, [
          _c("div", { staticClass: "position-relative z-0 bg-white h-2" }),
          _c("div", {
            class: "position-absolute h-2 bg-" + _vm.rrss.name + " top-0",
            style:
              "width: " +
              _vm.metricDetail.variation +
              "%; z-index : 2; transition : all ease 0.2s; max-width : 100%",
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }