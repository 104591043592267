<template>
  <div class="w-100 mb-4" v-if="bolChangeData">
    <div class="row">
      <!-- BLOQUE DE AUDIENCIA   -->
      <div class="col-3 pr-0 ">
        <div
          class="w-100 box-resume first-block bg-primary text-white"
          v-if="bestIndicator"
        >
          <div class="">
            <p class="text-capitalize text-lsm mb-0">{{ bestIndicator.name }}</p>
            <p class="text-3-5xl mb-0"><b>{{numFormatter(bestIndicator.count)}}</b></p>
          </div>
          <div>
            <div class="w-100 mb-1">
              <div class="m-0 d-flex align-items-center justify-content-between">
                <span class="text-xs">Variation</span>
                <div>
                  <span class="text-lg"
                    >{{ bestIndicator.variation }}%
                  </span>
                    <i
                      :class="
                        `fa fa-sort-${bestIndicator.variation > 0 ? 'up' : 'down'}`
                      "
                    ></i>
                </div>
                  
              </div>
            </div>
            <div class="position-relative w-100">
              <div class="position-relative z-0 h-2" style="background-color:azure;opacity:0.2 ;"></div>
              <div
                :class="
                  `position-absolute h-2 bg-white bg-${
                    bestIndicator.variation > 0 ? 'warning' : 'danger'
                  } top-0`
                "
                :style="
                  `width: ${
                    bestIndicator.variation > 0 ? bestIndicator.variation : 0
                  }%; z-index : 2`
                "
              ></div>
            </div>
          </div>
        </div>
      </div>

      <!-- SELECTOR DE VARIACION -->
      <div class="col-3 pr-0">
        <div
          class="box-resume text-secondary  bg-white"
        >
          <div
            class="d-flex justify-content-between"
            v-for="(item, index) in variations"
            :key="index"
          >
            <span
              @click="setActiveVariation(item.name)"
              class="text-capitalize text-color-text text-xs"
              :class="[
                activeVariation === item.name ? 'variation-active' : 'cursor-pointer',
              ]"
              >{{ item.name.replace("_", " ") }}
              <i
                v-if="item.name === 'engaged_users'"
                class="fab fa-facebook text-facebook"
              ></i>
            </span>
            <div>
              <span class="text-lsm mr-2 item-count text-color-text">
                <b> {{ numFormatter(item.count)}} </b>
              </span>
              <span class="text-skygray text-xxs">
                {{item.variation}}% 
                <i :class="`text-aling-center fa fa-sort-${item.variation > 0 ? 'up text-success ' : 'down text-danger'} fa-lg`"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3 pr-0">
        <variation-item v-if="accounts && accounts.length > 0" :variation-type="'top'" :accounts="accounts" :metric="activeVariation"/>
      </div>
      <div class="col-3 pr-0">
        <variation-item v-if="accounts && accounts.length > 0" :variation-type="'bottom'" :accounts="accounts" :metric="activeVariation"/>
      </div>
    </div>
  </div>
</template>

<script>
import variationItem from "./ResumeVariation";
export default {
  name: "Resume",
  components: {
    variationItem,
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeVariation: "audience",
      items: ["audience", "posts", "interactions", "engaged_users"],
      bestIndicator: null, //este será el mejor de los indicadores estará en el bloque azul
      variations : [],
      accounts : [],
      detail_new : this.detail,
      bolChangeData: false
    };
  },
  methods: {
    setActiveVariation(value) {
      if(value !== 'engaged_users'){
        this.activeVariation = value;
      }
    },
    numFormatter(num) {
        if(num > 999 && num < 1000000){
           num =(num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
        }else if(num > 1000000){
            num =(num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
        }else if(num < 900){
             num; // if value < 1000, nothing to do
        }
        return num;
      },
  },
  mounted(){
    this.bolChangeData = false
     this.bestIndicator = this.detail.globals.sort((a, b) =>
        a.count < b.count ? 1 : -1
      )[0];
      this.variations = this.detail.globals.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      this.accounts = this.detail.accounts
      this.bolChangeData = true
  },
    
};
</script>
<style >
.variation-active {
  text-decoration: underline;
  font-weight: bold;
}
.indicator{
  font-family: Oxygen;
  font-size: 32px;
  font-weight: bold;
}
.item-count{
  font-family: Oxygen;
  font-size: 15px;
  font-weight: bold;
}
.box-resume.first-block{
  padding: 30px 20px;
}
.box-resume{
  height: 180px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
}

.text-color{
  color: #5d6b88 !important;
}
.title-color{
  color: #2c3852 !important;
}
</style>
