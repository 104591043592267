<template>
  <div class="w-8/12 pl-3 pr-5">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >{{ title }}
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Interactions day week',
          }"
        ></i>
      </div>
    </div>
    <div v-if="!view" class="image-container-off">
      <img
        class="opacity-50"
        src="@/assets/images/image-off-interacions-by-day-off-week.png"
        alt=""
      />
      <div class="image-container-off__message opacity-required-35">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    <graph-bar
      v-else
      :titleyAxis="titleyAxis"
      :titlexAxis="titlexAxis"
      :series="series"
      :categories="categories"
    ></graph-bar>
  </div>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../util/config";
import graphBar from "../../../graph-custom/graphBarCustomInteracions.vue";
export default {
  components: {
    graphBar,
  },
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      yAxis: {
        min: 0,
        title: {
          text: "Interactions",
        },
        labels: {
          overflow: "justify",
        },
      },
      titleyAxis: "Interactions",
      titlexAxis: "Days",
      view: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setDataByType() {
      try {
      } catch (error) {
        return {};
      }
    },
    validate() {
      try {
        for (const item of this.series) {
          item.data.forEach((element) => {
            if (element > 0) {
              this.view = true;
              return;
            }
          });
        }
      } catch (error) {
        this.view = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  created() {
    this.validate();
  },
};
</script>

